
import { defineComponent } from "vue";
import Premium from "@/views/Premium.vue";
import EditorialCollection from "@/components/EditorialCollection.vue";
import Live from "@/components/Live.vue";
import { GetStructureUseCase } from "@/usecases/get-structure-use-case";
import { StructureModel } from "../../entities/structure-entity";
import Greeting from "@/components/Greeting.vue";
import Logo from "@/components/Logo.vue";
import Channels from "@/components/Channels.vue";
import CuratedNews from "@/components/CuratedNews.vue";
import LocalNewsPlaylist from "@/components/LocalNewsPlaylist.vue";
import PremiumItem from "@/components/PremiumItem.vue";
import Links from "@/components/Links.vue";
import RecommendedPrograms from "@/components/RecommendedPrograms.vue";
import ListeningHistoryPrograms from "@/components/ListeningHistoryPrograms.vue";
import PopularPrograms from "@/components/PopularPrograms.vue";
import RecommendedEpisodes from "@/components/RecommendedEpisodes.vue";
import NewsBroadcastsEpisodes from "@/components/NewsBroadcastsEpisodes.vue";
import { GoogleAnalyticsTrackingScreen } from "../../entities/google-analytics-tracking-screen";
import * as appService from "../../public/appService";
import { IS_LOCAL, IS_DEV, IS_BETA, IS_PROD } from "@/util/dev-utils";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import Promotion from "@/components/Promotion.vue";
import Feedback from "@/components/Feedback.vue";
import { useExperimentStore } from "@/stores/experiments";
import { useVersionStore } from "@/stores/version";
import SystemMessage from "../components/SystemMessage.vue";
import StandaloneTopNewsContainer from "@/components/StandaloneTopNewsContainer.vue";
import OldTopNews from "@/components/OldTopNews.vue";
import CardLarge from "@/components/cards/CardLarge.vue";
import PuffPackageContainer from "@/components/PuffPackageContainer.vue";
import "@sverigesradio/stil/components/stil-button.js";
import "@sverigesradio/stil/components/stil-grid.js";
import StandalonePodcast from "@/components/StandalonePodcast.vue";
import { useFeatureFlagsStore } from "@/stores/featureFlagsStore";
import FixedNews from "@/components/FixedNews.vue";
import TopNews from "@/components/TopNews.vue";
import RightNow from "@/components/RightNow.vue";
import PageFooter from "@/components/PageFooter.vue";

export default defineComponent({
  name: "Startpage",
  components: {
    TopNews,
    PuffPackageContainer,
    OldTopNews,
    StandaloneTopNewsContainer,
    Promotion,
    NewsBroadcastsEpisodes,
    RecommendedEpisodes,
    PopularPrograms,
    ListeningHistoryPrograms,
    RecommendedPrograms,
    Logo,
    Greeting,
    Channels,
    FixedNews,
    CuratedNews,
    LocalNewsPlaylist,
    Premium,
    PremiumItem,
    EditorialCollection,
    Live,
    Links,
    Feedback,
    SystemMessage,
    CardLarge,
    StandalonePodcast,
    RightNow,
    PageFooter,
  },
  data() {
    let loaded: boolean = false;
    let hasError: boolean = false;
    let structure: StructureModel[] | [] | undefined | null;
    let promotionIndex: number = 0;
    let feedbackModuleEnabled: boolean = false;

    return {
      loaded: loaded,
      hasError: hasError,
      structure,
      isProd: IS_PROD,
      feedbackModuleEnabled,
      promotionIndex,
      showNewTopNewsModule: false,
      showPuffPackageModule: false,
    };
  },
  computed: {
    newFixedNewsDesignEnabled(): boolean {
      return this.featureFlagsStore.newFixedNewsDesignEnabled;
    },
    showNewTopNewsDesign(): boolean {
      return this.experimentStore.fixed_news_design;
    },
    hasNativeTopNavigation(): boolean {
      const webViewVersion = this.versionStore.webViewVersion;
      return webViewVersion !== null && webViewVersion >= 2.0;
    },
    envIndicatorColor(): string {
      if (IS_LOCAL) {
        return "hotpink";
      } else if (IS_DEV) {
        return "blue";
      } else if (IS_BETA) {
        return "red";
      }

      return "";
    },
  },
  created() {
    this.fetchData();

    window["WebViewVisibleIntervalEventStartPageHook"] = {
      components: this,
      update: () => {
        this.fetchStartPageStructure();
      },
    };
  },
  setup() {
    return {
      experimentStore: useExperimentStore(),
      versionStore: useVersionStore(),
      featureFlagsStore: useFeatureFlagsStore(),
    };
  },
  methods: {
    async fetchStartPageStructure() {
      this.structure = await GetStructureUseCase.get();
    },
    async fetchData() {
      await this.fetchStartPageStructure();
      if (this.structure) {
        this.loaded = true;
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "skarmvisning",
          parametersMap: {
            skarmnamn: "hem",
            ursprung: "tbd",
            eventkalla: "webview",
          },
        };
        let googleAnalyticsTrackingScreen: GoogleAnalyticsTrackingScreen = {
          name: "hem",
          customDimensions: {
            66: "webview",
          },
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingScreenEvent(
          googleAnalyticsTrackingScreen
        );
        const webViewVersion = this.versionStore.webViewVersion;
        if (webViewVersion !== null && webViewVersion >= 1.2) {
          this.feedbackModuleEnabled = true;
        } else {
          this.feedbackModuleEnabled = false;
        }
        if (webViewVersion !== null && webViewVersion >= 1.4) {
          this.showNewTopNewsModule = true;
        }
        if (webViewVersion !== null && webViewVersion >= 1.3) {
          this.showPuffPackageModule = true;
        }
        this.promotionIndex = 3;
      }
    },
  },
});
