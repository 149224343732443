import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@sverigesradio/dpp-ds-fundamentals/dist/css/_variables.css";
import "@/assets/main.css";
import VueObserveVisibility from "vue3-observe-visibility";
import VWave from "v-wave";
import "@sverigesradio/stil/dist/style.css";
import "@sverigesradio/dpp-vue-3-components/dist/index.css";
import { logger } from "@/util/logger";
import { createPinia } from "pinia";

window.onerror = function (eventOrMessage, url, lineNumber, colNumber, error) {
  logger.logError("error in js " + lineNumber + ":" + colNumber, error as Error, url as string);
};

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(VWave) // Android ripple effect
  .use(VueObserveVisibility) // To enable v-observe-visibility

app.config.errorHandler = function (err, vm, info) {
  logger.logError("Error in vue", err as Error, window.location.href);
};
app.mount("#app");
