
import * as appService from "../public/appService";
import {PLAYER_STATE_PLAYING, THEME_DARK} from "@/constants";
import {defineComponent} from 'vue'
import {useExperimentStore} from "@/stores/experiments";
import {useVersionStore} from "@/stores/version";
import {usePlayerStateStore} from '@/stores/playerState';
import {NativeRepository} from '@/repositories/native-repository';
import {useNativeStore} from '@/stores/nativeStore'
import {FontSizeUpdatedHook} from "../public/native"
import { useFeatureFlagsStore } from "./stores/featureFlagsStore";

export default defineComponent({
    name: 'App',
    data() {
        let theme: string = ""
        let loaded: Boolean = false
        let deviceOs: string = ""

        return {
            theme: theme,
            loaded: loaded,
            remoteConfigLoaded: false,
            deviceOs,
            THEME_DARK: THEME_DARK
        }
    },
    beforeMount() {
        window[FontSizeUpdatedHook] = {
            components: this,
            fontSizeUpdated: () => this.getFontSizeiOS(),
        };
        window['ThemeColorUpdatedHook'] = {
            components: this,
            themeColorUpdated: async () => await this.getTheme(),
        };
        window['PlayerPlayingHook'] = {
            components: this,
            playerPlaying: () => this.getAndSetPlayerStatePlaying(),
        };
        window['PlayerStoppedHook'] = {
            components: this,
            playerStopped: () => this.setPlayerStateStopped(),
        };
        
        window['PlaylistItemDidChangeHook'] = {
                components: this,
                update: () => this.setPlayerState(),
            };
    },
    async created() {
        appService.checkConnectionToNative()
        await this.versionStore.getAndSetVersion()
        await this.featureFlagsStore.getAndSetFeatureFlags()
        await this.experimentStore.setupExperiments()
        await this.setPlayerState()
        await this.getTheme()
        const os: string | undefined = appService.getOS();
        if (os === 'iOS') {
            await this.getFontSizeiOS()
            this.setOs('iOS')
        } else if (os === 'android') {
            this.setOs('android')
        } else if (!os) {
            let overrideFromUserAgent = this.getOverrideFromUserAgent();
            if (overrideFromUserAgent) {
                this.deviceOs = overrideFromUserAgent;
            }
        }
    },
    setup () {
        return {
            experimentStore: useExperimentStore(),
            versionStore: useVersionStore(),
            featureFlagsStore: useFeatureFlagsStore(),
            playerStateStore: usePlayerStateStore(),
            nativeStore: useNativeStore()
        }
    },
    methods: {
        setPlayerStateStopped() {
            this.playerStateStore.setStopped()
        },
        async getAndSetPlayerStatePlaying() {
            const currentItemId = await NativeRepository.getIdOfCurrentItemPlaying()
            const idsOfCurrentPlaylist = await NativeRepository.getIdsOfCurrentPlaylistPlaying()
            this.playerStateStore.setPlaying(currentItemId, idsOfCurrentPlaylist)
        },
        async setPlayerState() {
            const playerState = await NativeRepository.getPlayerState()
            if (playerState === PLAYER_STATE_PLAYING) {
                await this.getAndSetPlayerStatePlaying()
            } else {
                this.setPlayerStateStopped()
            }
        },
        async getTheme() {
            
            this.theme = await appService.getTheme()
            this.nativeStore.setCurrentTheme(this.theme)
            if (this.theme === THEME_DARK) {
                document.querySelector('body')?.setAttribute('style', `background-color:var(--color-background-primary-darkmode)`)
            } else {
                document.querySelector('body')?.setAttribute('style', 'background-color:var(--color-background-primary-lightmode)')
            }
            this.loaded = true
        },
        async getFontSizeiOS() {
            const fontSize = await appService.getFontSize();
            document.documentElement.style.setProperty("font-size", fontSize + "px");
        },
        setOs(os: string) {
            this.deviceOs = os
        },
        getOverrideFromUserAgent() {
            if (navigator.userAgent.includes('Android')) {
                return 'android'
            }
            if (navigator.userAgent.includes('iPhone')) {
                return 'iOS'
            }
            return '';
        }
    }
})
