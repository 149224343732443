import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b339b58"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "top-news-wrapper divider"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardImageCollectionSkeleton = _resolveComponent("CardImageCollectionSkeleton")!
  const _component_top_news_choose_p4_item = _resolveComponent("top-news-choose-p4-item")!
  const _component_TopNewsCard = _resolveComponent("TopNewsCard")!
  const _component_NewsCarousel = _resolveComponent("NewsCarousel")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_CardImageCollectionSkeleton, { key: 0 }))
          : (_ctx.hasData)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createVNode(_component_NewsCarousel, {
                    trackingLabel: _ctx.newsCollection[0].trackingLabel,
                    "without-gap": true
                  }, {
                    default: _withCtx(() => [
                      (_ctx.showChooseLocalChannelItem)
                        ? (_openBlock(), _createBlock(_component_top_news_choose_p4_item, {
                            key: 0,
                            onClick: _ctx.chooseP4
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCollection, (newsCollectionItem, index) => {
                        return (_openBlock(), _createBlock(_component_TopNewsCard, {
                          moduleName: _ctx.moduleName,
                          key: newsCollectionItem.listHeadline,
                          "news-collection-model": newsCollectionItem,
                          "local-channel-id": _ctx.localChannelId,
                          "is-last-child": index === _ctx.newsCollection.length - 1
                        }, null, 8, ["moduleName", "news-collection-model", "local-channel-id", "is-last-child"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["trackingLabel"])
                ])
              ])), [
                [_directive_observe_visibility, { callback: (isVisible) => _ctx.onBecomeVisible(isVisible), once: true, throttle: 300, }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, { callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible), intersection: { rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN}, once: true, throttle: 100, }]
      ])
    : _createCommentVNode("", true)
}