import { getBaseUrl } from "@/util/address-utils";
import { NewsCollectionModel } from '../../entities/news-collection-model'
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const NewsCollectionRepository = {
    async getOldTopNews(localChannelId: number | null | undefined, hideLocalNewsPlaylist: boolean): Promise<NewsCollectionModel[] | null> {
        let url = getBaseUrl() + `bff/news/topNews?hideLocalNewsPlaylist=${hideLocalNewsPlaylist}`
        if (localChannelId !== null && localChannelId !== undefined) {
            url = url + `&localChannelId=${localChannelId}`
        }
        return httpGetTypeOrNull<NewsCollectionModel[]>(url)
    },
    
    async getNewsPlaylists(localChannelId: number | null | undefined): Promise<NewsCollectionModel[] | null> {
        let url = getBaseUrl() + `bff/news/newsPlaylists`
        if (localChannelId !== null && localChannelId !== undefined) {
            url = url + `?localChannelId=${localChannelId}`
        }
        return httpGetTypeOrNull<NewsCollectionModel[]>(url)
    },
    
    async getLocalNewsPlaylist(localChannelId: number): Promise<NewsCollectionModel | null> {
        const url = getBaseUrl() + `bff/news/local/${localChannelId}`
        return httpGetTypeOrNull<NewsCollectionModel>(url)
    },
    
    async getStandaloneTopNews(): Promise<NewsCollectionModel | null> {
        const url = getBaseUrl() + `bff/news/standaloneTopNews`
        return httpGetTypeOrNull<NewsCollectionModel>(url)
    },
}