
import {defineComponent} from 'vue'
import ModuleTitle from "@/components/ModuleTitle.vue"
import * as appService from '../../public/appService.js'
import {ProgramModel} from "../../entities/program-model"
import {CarouselModel} from "../../entities/carousel-model"
import {ProgramsRepository} from '@/repositories/programs-repository'
import {NativeRepository} from '@/repositories/native-repository'
import {FirebaseTrackingEvent} from '../../entities/firebase-tracking-event'
import {GoogleAnalyticsTrackingEvent} from '../../entities/google-analytics-tracking-event'
import {CardTextImageSquare} from '@sverigesradio/dpp-vue-3-components'
import TemporarySkeletonItem from '@/components/TemporarySkeletonItem.vue'
import {ON_ALMOST_VISIBLE_ROOT_MARGIN} from "@/constants";
import ProgramCarousel from "@/components/carousels/ProgramCarousel.vue";
import { KilkayaClickEvent, kilkayaClick } from '@/util/tracking-utils'

export default defineComponent({
    name: "ListeningHistoryPrograms",
    components: { TemporarySkeletonItem, ProgramCarousel, ModuleTitle, CardTextImageSquare },
    props: {
        title: String,
        moduleName: String
    },
    data () {
        return {
            programCarousel: null as CarouselModel<ProgramModel> | null,
            programs: null as ProgramModel[] | undefined | null,
            isLoading: true,
            ON_ALMOST_VISIBLE_ROOT_MARGIN
        }
    },
    computed: {
      hasData(): boolean {
        return this.programs !== undefined && this.programs !== null && this.programCarousel!==null && this.programCarousel?.items.length > 0
      }
    },
    beforeMount () {
        window['ListeningHistoryPrograms'] = {
            components: this,
            listeningHistoryUpdated: () => this.fetchData()
        }
    },
    methods: {
        navigateToProgram (program: ProgramModel) {
            if (this.programCarousel) {
                appService.openProgram(program.id)
                this.sendProgramClickedTrackingEvents(this.programCarousel, program)
            }
        },
        async fetchData () {
            const programListeningHistory = await NativeRepository.getProgramListeningHistory()
            if (programListeningHistory !== null && programListeningHistory !== undefined && programListeningHistory?.length !== 0) {
                this.programCarousel = await ProgramsRepository.get(programListeningHistory.slice(0, 15))
                this.programs = this.programCarousel?.items
            }
            this.isLoading = false
        },
        onAlmostVisible (isAlmostVisible) {
            if (isAlmostVisible) {
                this.fetchData()
            }
        },
        onBecomeVisible (isVisible) {
            if (isVisible) {
                this.sendDisplayedTrackingEvents()
            }
        },
        sendProgramClickedTrackingEvents (programCarousel: CarouselModel<ProgramModel>, program: ProgramModel) {
            let firebaseTrackingEvent: FirebaseTrackingEvent = {
                eventName: "modulklick",
                parametersMap: {
                    kanalnamn: program.channelName,
                    kanal_id: program.channelId,
                    programnamn: program.title,
                    program_id: program.id,
                    programkategori: program.programCategory,
                    modultyp: "tbd",
                    skarmnamn: "hem",
                    ursprung: "tbd",
                    innehallstyp: "tbd",
                    modulnamn: programCarousel.trackingLabel,
                    eventkalla: "webview"
                }
            }
            let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                event: "klick_objekt",
                category: "Karusell",
                trackingLabel: programCarousel.trackingLabel,
                customDimensions: {
                    1: program.channelName,
                    2: program.channelId,
                    3: program.title,
                    4: program.id.toString(),
                    17: program.programCategory,
                    66: "webview"
                },
                customMetrics: {},
                campaignUrl: null,
                trackingValue: null
            }
            appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
            appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            const kilkayaEvent: KilkayaClickEvent = {
                audioType: "Episode",
                channelName: program.channelName,
                soundName: "", 
                moduleTitle: this.title || "",
                moduleName: this.moduleName || "",
                id: program.id,
                itemHeadline: program.title,
                interactionType: "klick",
                programName: program.title,
            };
            kilkayaClick(kilkayaEvent);
        },
        sendDisplayedTrackingEvents () {
            if (this.programCarousel) {
                if (this.programCarousel) {
                    let firebaseTrackingEvent: FirebaseTrackingEvent = {
                        eventName: "modul_visas",
                        parametersMap: {
                            modultyp: "tbd",
                            skarmnamn: "hem",
                            innehallstyp: "tbd",
                            modulnamn: this.programCarousel.trackingLabel,
                            eventkalla: "webview"
                        }
                    }
                    let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                        event: "visas",
                        category: "Karusell",
                        trackingLabel: this.programCarousel.trackingLabel,
                        customDimensions: {
                            66: "webview"
                        },
                        customMetrics: {},
                        campaignUrl: null,
                        trackingValue: null
                    }
                    appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                    appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
                }
            }
        }
    }
})
