/// Expects a version string that is made of digits with an optional dot in the middle.
/// Handles leading and trailing whitespace.
///
/// Return the default version 1.0 on invalid input.
export function parseVersion(version: string | undefined): number {
    const defaultVersion = 1.0;

    if (version === undefined) {
        return defaultVersion
    }

    const parsedVersion = parseFloat(version);

    const validPattern = version.match(/^\s*\d+(\.\d+)?\s*$/) 

    if (!validPattern || Number.isNaN(parsedVersion)) {
        return defaultVersion;
    }

    return parsedVersion;
}