
import { defineComponent } from "vue";
import * as appService from "../../public/appService.js";
import { EpisodeModel } from "../../entities/episode-model";
import { RecommendedEpisodesRepository } from "@/usecases/recommended-episodes-repository";
import ModuleTitle from "@/components/ModuleTitle.vue";
import { CarouselModel } from "../../entities/carousel-model";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { GoogleAnalyticsTrackingEvent } from "../../entities/google-analytics-tracking-event";
import {
  InteractionType,
  PersonalizationTrackingEvent,
} from "../../entities/personalization-tracking-event";
import { CardTextImage } from "@sverigesradio/dpp-vue-3-components";
import TemporarySkeletonItem from "@/components/TemporarySkeletonItem.vue";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import EpisodeCarousel from "@/components/carousels/EpisodeCarousel.vue";
import { NativeRepository } from "@/repositories/native-repository";
import CardMediumCarousel from "@/components/carousels/CardMediumCarousel.vue";
import CardMedium from "@/components/cards/CardMedium.vue";
import { KilkayaClickEvent, kilkayaClick } from "@/util/tracking-utils";

export default defineComponent({
  components: {
    TemporarySkeletonItem,
    ModuleTitle,
    EpisodeCarousel,
    CardTextImage,
    CardMedium,
    CardMediumCarousel,
  },
  props: {
    title: String,
    showAll: Boolean,
    moduleName: String,
  },
  data() {
    return {
      episodeCarousel: null as CarouselModel<EpisodeModel> | null,
      episodeModels: null as EpisodeModel[] | undefined | null,
      isLoading: true,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  computed: {
    hasData(): boolean {
      return (
        this.episodeModels !== null &&
        this.episodeModels !== undefined &&
        this.episodeCarousel !== null &&
        this.episodeCarousel?.items.length > 0
      );
    },
  },
  methods: {
    navigateToEpisode(episode: EpisodeModel) {
      if (this.episodeCarousel) {
        this.sendEpisodeClickedTrackingEvents(
          this.episodeCarousel,
          episode,
          "klick_objekt",
          "modulklick"
        );
        appService.openEpisode(episode.id);
      }
    },
    playEpisode(episode: EpisodeModel) {
      if (this.episodeCarousel) {
        this.sendEpisodeClickedTrackingEvents(
          this.episodeCarousel,
          episode,
          "start",
          "modulstart"
        );
      }
      appService.playEpisode(episode.id);
    },
    pausePlayer() {
      NativeRepository.pausePlayer();
    },
    async fetchData() {
      const userId = await appService.getUserId();
      if (userId != null) {
        this.episodeCarousel = await RecommendedEpisodesRepository.get(userId);
        this.episodeModels = this.episodeCarousel?.items;
      }
      this.isLoading = false;
    },
    onAlmostVisible(isAlmostVisible) {
      if (isAlmostVisible) {
        this.fetchData();
      }
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedEvent();
      }
    },
    sendEpisodeClickedTrackingEvents(
      episodeCarousel: CarouselModel<EpisodeModel>,
      episode: EpisodeModel,
      gaEvent: string,
      firebaseEvent: string
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: firebaseEvent,
        parametersMap: {
          kanalnamn: episode.channelName,
          kanal_id: episode.channelId,
          programnamn: episode.programName,
          program_id: episode.programId.toFixed(0).toString(),
          ljudnamn: episode.title,
          ljud_id: episode.id.toString(),
          programkategori: episode.programCategory,
          ljudlangd: Math.round(episode.duration / 60).toFixed(0),
          modultyp: "tbd",
          dagar_fran_publicering: (
            (Date.now() - episode.publishTimestampMillis) /
            86400000
          ).toFixed(0),
          skarmnamn: "hem",
          ursprung: "tbd",
          innehallstyp: "tbd",
          modulnamn: episodeCarousel.trackingLabel,
          eventkalla: "webview",
        },
      };
      let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
        event: gaEvent,
        category: "Karusell",
        trackingLabel: episodeCarousel.trackingLabel,
        customDimensions: {
          2: episode.channelId.toString(),
          3: episode.programName,
          4: episode.programId.toString(),
          5: episode.title,
          6: episode.id.toString(),
          17: episode.programCategory,
          18: Math.round(episode.duration / 60000).toString(),
          66: "webview",
        },
        customMetrics: {},
        campaignUrl: null,
        trackingValue: null,
      };
      if (episode.channelName != "[NO CHANNEL]") {
        googleAnalyticsTrackingEvent.customDimensions[1] = episode.channelName;
      }
      let personalizationTrackingEventTapped: PersonalizationTrackingEvent = {
        interactionTypeAsString: InteractionType.TAPPED.valueOf(),
        eventId: episodeCarousel?.listId ?? undefined,
        appSectionID: "home",
        source: "carousel",
        itemIds: null,
        itemId: episode.id.toString(),
        hitIndex: episodeCarousel?.itemIds?.indexOf(episode.id.toString()),
      };
      let personalizationTrackingEventReadMore: PersonalizationTrackingEvent = {
        interactionTypeAsString: InteractionType.READ_MORE.valueOf(),
        eventId: episodeCarousel?.listId ?? undefined,
        appSectionID: "home",
        source: "carousel",
        itemIds: null,
        itemId: episode.id.toString(),
        hitIndex: episodeCarousel?.itemIds?.indexOf(episode.id.toString()),
      };
      appService.sendPersonalizationTrackingEvent(
        personalizationTrackingEventTapped
      );
      appService.sendPersonalizationTrackingEvent(
        personalizationTrackingEventReadMore
      );
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
      appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);
      
      const kilkayaEvent: KilkayaClickEvent = {
        audioType: "Episode",
        channelName: episode.channelName,
        soundName: episode.title,
        moduleTitle: this.title || "",
        moduleName: this.moduleName || "",
        id: episode.id,
        itemHeadline: episode.title,
        interactionType: "klick",
        programName: episode.programName,
      };
      kilkayaClick(kilkayaEvent);
    },
    sendDisplayedEvent() {
      if (this.episodeCarousel) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "tbd",
            skarmnamn: "hem",
            innehallstyp: "tbd",
            modulnamn: this.episodeCarousel.trackingLabel,
            eventkalla: "webview",
          },
        };
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "visas",
          category: "Karusell",
          trackingLabel: this.episodeCarousel.trackingLabel,
          customDimensions: {
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );

        if (this.episodeCarousel && this.episodeCarousel.listId) {
          let personalizationTrackingEvent: PersonalizationTrackingEvent = {
            interactionTypeAsString: InteractionType.DISPLAYED.valueOf(),
            eventId: this.episodeCarousel.listId,
            appSectionID: "home",
            source: "carousel",
            itemIds: this.episodeCarousel.itemIds,
            itemId: null,
            hitIndex: null,
          };
          appService.sendPersonalizationTrackingEvent(
            personalizationTrackingEvent
          );
        }
      }
    },
  },
});
