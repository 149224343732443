import {getBaseUrl} from "@/util/address-utils";
import {ChannelModuleModel} from "../../entities/channel-entity";
import { httpGetTypeOrNull } from '@/api/http-get-type-or-null'

export const ChannelRepository = {
    async get(localChannelId, savedExtraChannelId, useBackground?): Promise<ChannelModuleModel | null> {
        const url = getBaseUrl() + 'bff/channels'
        const params = {}
        if(localChannelId != null) {
            params['localChannelId'] = localChannelId;
        }
        if(savedExtraChannelId != null) {
            params['savedExtraChannelId'] = savedExtraChannelId;
        }
        if(useBackground) {
            params['useBackground'] = true;
        }
        return httpGetTypeOrNull<ChannelModuleModel>(url, params)
    }
}
