import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "divider"
}
const _hoisted_2 = {
  key: 1,
  class: "card-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemporarySkeletonItem = _resolveComponent("TemporarySkeletonItem")!
  const _component_ModuleTitle = _resolveComponent("ModuleTitle")!
  const _component_CardMedium = _resolveComponent("CardMedium")!
  const _component_CardMediumCarousel = _resolveComponent("CardMediumCarousel")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_TemporarySkeletonItem, {
              key: 0,
              class: "card-padding"
            }))
          : (_ctx.hasData)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ModuleTitle, { title: _ctx.title }, null, 8, ["title"]),
                _createVNode(_component_CardMediumCarousel, {
                  trackingLabel: _ctx.episodeCarousel?.trackingLabel
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.episodeModels, (episodeModel) => {
                      return (_openBlock(), _createBlock(_component_CardMedium, {
                        key: episodeModel.id,
                        "image-url": episodeModel.picture.defaultUrl,
                        "image-alt-text": episodeModel.picture.altText,
                        type: "Episode",
                        headline: episodeModel.title,
                        "program-name": episodeModel.programName,
                        "duration-formatted": episodeModel.durationFormatted,
                        id: episodeModel.id,
                        ariaLabel: episodeModel.title,
                        onOpen: () => _ctx.navigateToEpisode(episodeModel),
                        onPlay: () => _ctx.playEpisode(episodeModel),
                        onPause: _ctx.pausePlayer
                      }, null, 8, ["image-url", "image-alt-text", "headline", "program-name", "duration-formatted", "id", "ariaLabel", "onOpen", "onPlay", "onPause"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["trackingLabel"])
              ])), [
                [_directive_observe_visibility, {
        callback: (isVisible, entry) => _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, {
      callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible),
      intersection: { rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN },
      once: true,
      throttle: 100,
    }]
      ])
    : _createCommentVNode("", true)
}