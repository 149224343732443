import httpClient from '@/api/http-client'
import {logger} from "@/util/logger";

export async function httpGetTypeOrNull<T>(url: string, params?: any): Promise<T | null>  {
  try {
    let response
    if (params === null) {
      response = await httpClient.get<T>(url)
    } else {
      response = await httpClient.get<T>(url, params)
    }
    if (response.status == 200) {
      return response.data
    } else {
      return null
    }
  } catch (e: unknown) {
    if (!params) {
      logger.logError("Http error in get request " + url, e as Error, url)
    } else {
      logger.logError("Http error in get request " + url + " with params: " + params, e as Error, url)
    }
    return null
  }
}