import { getBaseUrl } from "@/util/address-utils";
import { httpGetTypeOrNull } from "../api/http-get-type-or-null";
import { PuffPackage, RightNowResponse } from "../../entities/puff-package-model";

export const SkyltaRepository = {
  async getPuffPackageById(id: number): Promise<PuffPackage | null> {
    const url = getBaseUrl() + `bff/puff-package/${id}`;
    return httpGetTypeOrNull<PuffPackage>(url);
  },

  async getRightNowById(id: number): Promise<RightNowResponse | null> {
    const url = getBaseUrl() + `bff/right-now/${id}`;
    return httpGetTypeOrNull<RightNowResponse>(url);
  },
};
