import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d6549c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "program-promotion-card divider"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardTextImageTagSkeleton = _resolveComponent("CardTextImageTagSkeleton")!
  const _component_stil_image = _resolveComponent("stil-image")!
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_stil_grid = _resolveComponent("stil-grid")!
  const _component_stil_card = _resolveComponent("stil-card")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_CardTextImageTagSkeleton, { key: 0 }))
          : (_ctx.hasData)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_stil_card, {
                  flow: "column",
                  as: "button",
                  gap: 2,
                  onClick: _ctx.onClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_stil_image, {
                      slot: "media",
                      src: _ctx.standalonePodcast.imageUrl,
                      alt: _ctx.standalonePodcast.altText,
                      aspectRatio: "5x7",
                      rounded: ""
                    }, null, 8, ["src", "alt"]),
                    _createVNode(_component_stil_grid, {
                      slot: "content",
                      justifyContent: "center",
                      gap: 1
                    }, {
                      default: _withCtx(() => [
                        (_ctx.standalonePodcast?.overline)
                          ? (_openBlock(), _createBlock(_component_stil_text, {
                              key: 0,
                              variant: "overline2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.standalonePodcast.overline), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.standalonePodcast?.headline)
                          ? (_openBlock(), _createBlock(_component_stil_text, {
                              key: 1,
                              as: "p",
                              variant: "h1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.standalonePodcast.headline), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.standalonePodcast?.body)
                          ? (_openBlock(), _createBlock(_component_stil_text, {
                              key: 2,
                              variant: "body",
                              truncateLine: "3"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.standalonePodcast.body), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])), [
                [_directive_observe_visibility, {
        callback: (isVisible, entry) => _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, {
      callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible),
      intersection: { rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN },
      once: true,
      throttle: 100,
    }]
      ])
    : _createCommentVNode("", true)
}