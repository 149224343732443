function keepAll<T>(item: T) {
    return true;
}

export function wrapAround<T>(items: T[], startIndex: number, itemsToKeep: (item: T) => boolean = keepAll): T[] {
    const wrappedItems = startIndex < 0
        ? items
        : [...items.slice(startIndex), ...items.slice(0, startIndex)]

    return wrappedItems.filter(itemsToKeep);
}