
import {defineComponent} from "vue";
import ConfettiAnimation from "@/components/ConfettiAnimation.vue";

export default defineComponent({
    name: "FeedbackThumbButtons",
    components: {ConfettiAnimation},
    data () {
      return {
          positiveResponse: false,
          negativeResponse: false
      }
    },
    emits: ['click'],
    computed: {
        negativeIsDisabled(): boolean {
            return this.positiveResponse
        },
        positiveIsDisabled(): boolean {
            return this.negativeResponse
        }
    },
    methods: {
        submit (version) {
            this.animateButton(version)
            this.$emit('click', version)
        },
        animateButton(version: string) {
            if (version === "positiv") {
                this.positiveResponse = true
            } else {
                this.negativeResponse = true
            }
        }
    }

})
