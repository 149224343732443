import {SquareIconProps} from '@sverigesradio/dpp-vue-3-components/dist/src/components/button/SquareIcon/props';

export interface ChannelModuleModel {
    trackingLabel: string,
    national: ChannelModel[],
    local: SelectableChannelModel
    extra: SelectableChannelModel
}

export interface ChannelModel {
    iconName: SquareIconProps["name"],
    playChannelModel: PlayChannelModel,
    ariaLabel: string
    useBackground: boolean
    iconImageUrl: string
}

export interface SelectableChannelModel extends ChannelModel {
    selected: boolean
}

export interface PlayChannelModel {
    name: string,
    id: number,
    imageUrl: string,
    info: string,
    audioSourceUri: string,
    channelType: ChannelType,
    type: string
}

export enum ChannelType {
    National = "National",
    Local = "Local",
    Extra = "Extra"
}
