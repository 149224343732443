

import { defineComponent, PropType } from 'vue';
import { usePlayerStateStore } from "@/stores/playerState";
import PauseIcon from '@/components/icons/PauseIcon.vue';
import PlayIcon from '../icons/PlayIcon.vue';
import AspectRatioImage from '../AspectRatioImage.vue';
export default defineComponent({
    name: "NewsCard",
    components: {
    PauseIcon,
    PlayIcon,
    AspectRatioImage
},
    props: {
        listHeadline: String,
        newsHeadline: String,
        imageSrc: String,
        imageAlt: String,
        tag: String,
        meta: String,
        ariaLabel: String,
        idsOfArticlesInPlaylist: Array as PropType<string[]>,
    },
    setup() {
        return {
            playerStateStore: usePlayerStateStore()
        }
    },
    computed: {
        showPauseButton(): boolean {
            return (this.isPlayerPlaying && this.isCurrentPlaylistPlaying)
        },
        isCurrentPlaylistPlaying(): boolean {
            return (this.idsOfArticlesInPlaylist?.join(', ') === this.playerStateStore?.idsOfCurrentPlaylist)
        },
        isPlayerPlaying(): boolean {
            return !!this.playerStateStore?.isPlaying
        },
    },
})
