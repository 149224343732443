
import { PropType, defineComponent } from "vue";
import "@sverigesradio/stil/components/stil-card.js";
import "@sverigesradio/stil/components/stil-grid.js";
import "@sverigesradio/stil/components/stil-image.js";
import "@sverigesradio/stil/components/stil-text.js";
import * as appService from "../../public/appService.js";
import TwoColumnLayout from "./cards/TwoColumnLayout.vue";
import CardLarge from "./cards/CardLarge.vue";
import CardMedium from "./cards/CardMedium.vue";
import InlineDivider from "./cards/InlineDivider.vue";
import InteractiveCard from "./cards/InteractiveCard.vue";
import Vignette from "./typography/Vignette.vue";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { NativeRepository } from "../repositories/native-repository";
import {
  getTrackingAudioType,
  kilkayaClick,
  KilkayaClickEvent,
  mapChannelIdToChannelName,
} from "../util/tracking-utils";
import { wrapAround } from "../util/array-utils";
import { LayoutItem, getPuffPackageLayout } from "../util/puff-package-layout";
import {
  PuffCardItem,
  PuffPackage,
  mapPuffItemToPuffCardItem,
} from "../../entities/puff-package-model";
import { useVersionStore } from "../stores/version";
import { getBaseUrl } from "@/util/address-utils";

export default defineComponent({
  name: "PuffPackage",
  components: {
    CardLarge,
    CardMedium,
    TwoColumnLayout,
    InlineDivider,
    Vignette,
    InteractiveCard,
  },
  props: {
    puffPackage: {
      type: Object as PropType<PuffPackage>,
      required: true,
    },
    moduleName: {
      type: String as PropType<string>,
    },
  },
  computed: {
    layoutItems(): LayoutItem[] {
      return getPuffPackageLayout(
        this.puffPackage?.items.map((item) => item.type)
      );
    },
    cardItems(): PuffCardItem[] | null {
      if (!this.puffPackage) {
        return null;
      }

      return this.puffPackage.items.map((item, index) =>
        mapPuffItemToPuffCardItem({
          ...item,
          index,
        })
      );
    },
    articlesOnly(): boolean {
      return (
        this.puffPackage?.items.every(({ type }) => type === "Article") || false
      );
    },
    isAndroid(): boolean {
      return appService.getOS() === "android";
    },
  },
  setup() {
    return {
      versionStore: useVersionStore(),
    };
  },
  methods: {
    playNewsCollection(startIndex: number = 0) {
      if (!this.puffPackage) {
        return;
      }

      const articles = this.puffPackage.items.map(
        ({ articleModel }) => articleModel
      );
      const wrappedAroundArticles = wrapAround(articles, startIndex);

      const newsCollection = {
        listHeadline: this.puffPackage?.subject || "",
        newsHeadline: "",
        tag: "",
        meta: "",
        picture: {
          altText: this.puffPackage?.items[0]?.altText,
          defaultUrl: this.puffPackage?.items[0]?.image,
          sources: [],
        },
        articles: wrappedAroundArticles,
        variant: "Standard",
        type: "playArticleCollection",
      };

      const newsCollectionToPlay = {
        ...newsCollection,
        startIndex: 0,
        type: "playArticleCollection",
      };

      appService.playNewsCollection(JSON.stringify(newsCollectionToPlay));
    },
    playOnDemandPlaylist(startIndex: number = 0) {
      if (!this.puffPackage) {
        return;
      }

      const items = this.puffPackage.items.map((item) => ({
        id: item.referenceId,
        type: item.type,
      }));
      const trackedIdentifier = `puffpaket_${this.puffPackage.skyltaId}`;

      NativeRepository.playOnDemandPlaylist(
        this.puffPackage.subject,
        trackedIdentifier,
        items,
        startIndex
      );
    },
    createPlayItem(item: PuffCardItem) {
      return () => {
        this.sendClickedTrackingEvents(item, "spela", item.index);

        if (item.type === "Live") {
          appService.playChannel(item.id);
          return;
        }

        const webViewVersion = this.versionStore.webViewVersion;
        if (webViewVersion !== null && webViewVersion >= 1.5) {
          this.playOnDemandPlaylist(item.index);
          return;
        }

        if (this.articlesOnly) {
          this.playNewsCollection(item.index);
        } else if (item.type === "Article") {
          NativeRepository.playNewsArticle(item.id);
        } else if (item.type === "Episode") {
          NativeRepository.playEpisode(item.id);
        }
      };
    },
    pauseItem() {
      NativeRepository.pausePlayer();
    },
    createOpenItem(item: PuffCardItem) {
      return () => {
        if (item.type === "Article") {
          NativeRepository.openNewsArticle(item.id);
        } else if (item.type === "Live") {
          appService.playChannel(item.id);
        } else if (item.type === "Episode") {
          NativeRepository.openEpisode(item.id);
        } else if (item.type === "Interactive") {
          const url = this.urlForFeature(item);
          const title = item.interactiveViewHeader ?? "";
          if (this.isSummerPuff(item)) {
            NativeRepository.openSommar2024CampaignWebView(title);
          } else if (this.isUSApoddenPuff(item)) {
            const USA_PODDEN_PROGRAM_ID = 4923;
            appService.openProgram(USA_PODDEN_PROGRAM_ID);
          } else if (item.id === 33) {
            // P3-guld
            NativeRepository.openBrowser(url);
          } else if (this.isTextInteractivePuff(item)) {
            if (item.id === 37) {
              // Melodikrysset
              NativeRepository.openWebView(url, title, true);
            } else {
              NativeRepository.openBrowser(url);
            }
          } else {
            NativeRepository.openWebView(url, title);
          }
        }
        this.sendClickedTrackingEvents(item, "klick", item.index);
      };
    },

    isTextInteractivePuff(item: PuffCardItem) {
      const interactivePuffs = [32, 35, 36, 37, 39, 40];
      return interactivePuffs.includes(item.id);
    },
    isLargeFeature(puffIndex: number): boolean {
      return puffIndex === 0;
    },
    isSummerPuff(item: PuffCardItem): boolean {
      return item.id === 6;
    },
    isUSApoddenPuff(item: PuffCardItem): boolean {
      return item.id === 29;
    },
    urlForFeature(item: PuffCardItem): string {
      return item.type === "Interactive"
        ? `${getBaseUrl()}interactive/${item.id}/html`
        : "";
    },
    sendClickedTrackingEvents(
      item: PuffCardItem,
      interactionType: "spela" | "klick",
      index: number
    ) {
      if (!item) {
        return;
      }

      // Find original puff item for certain tracking props
      const puffItem = this.puffPackage?.items.find(
        (puffItem) =>
          puffItem.referenceId === item.id &&
          puffItem.headline === item.headline
      );

      const soundName =
        puffItem?.type === "Live"
          ? mapChannelIdToChannelName(puffItem?.referenceId) ||
            String(puffItem?.referenceId)
          : puffItem?.soundName;

      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          modultyp: "puffpaket",
          skarmnamn: "hem",
          modulnamn: this.moduleName || "puffpaket",
          rubrik_puffpaket: this.puffPackage?.subject,
          klickinteraktion_puffpaket: interactionType,
          puff_puffpaket: item.headline,
          ljudtyp: getTrackingAudioType(item.type),
          programnamn: puffItem?.programName,
          ljudnamn: soundName,
          kanalnamn: puffItem?.channelName,
          puffIndex: String(index + 1),
          ursprung: puffItem?.trackingLabel,
        },
      };

      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);

      const kilkayaEvent: KilkayaClickEvent = {
        audioType: item.type,
        channelName: puffItem?.channelName || "",
        soundName: soundName,
        moduleTitle: this.puffPackage.subject,
        moduleName: this.moduleName || "",
        position: index + 1, // 1-indexed, Same as puffIndex in FirebaseEvent, even though we don't do it anywhere else.
        id: item.id,
        itemHeadline: item.headline,
        interactionType: interactionType,
        programName: item.programName,
        origin: puffItem?.trackingLabel,
      };
      kilkayaClick(kilkayaEvent);
    },
  },
});
