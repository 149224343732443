<template>
    <div class="response">
        <stil-text variant="h4" color="primary">
            <span aria-live="polite">{{ responseText }}</span>
        </stil-text>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import "@sverigesradio/stil/components/stil-text.js";

export default defineComponent({
    name: "FeedbackResponse",
    props: {
        responseText: String
    }
})
</script>

<style scoped>

.response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 52px;
}
</style>
