
import {defineComponent} from 'vue'
import ModuleTitle from '@/components/ModuleTitle.vue'
import * as appService from '../../public/appService.js'
import {FirebaseTrackingEvent} from '../../entities/firebase-tracking-event'
import {GoogleAnalyticsTrackingEvent} from '../../entities/google-analytics-tracking-event'
import Logo from "@/components/Logo.vue"
import "@sverigesradio/stil/components/stil-text.js"

export default defineComponent({
      name: "PageFooter",
      components: {Logo, ModuleTitle},
      props: {
        title: String
      },
  
      methods: {
        onBecomeVisible(isVisible) {
          if (isVisible) {
            this.sendDisplayedEvent()
          }
        },
        scrollToTop() {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        },
        sendMail(email) {
          appService.openBrowser(`mailto:${email}`)
        },
        makeCall(phoneNumber) {
          appService.openBrowser(`tel:${phoneNumber}`)
        },
        sendDisplayedEvent() {
          let firebaseTrackingEvent: FirebaseTrackingEvent = {
            eventName: "modul_visas",
            parametersMap: {
              modultyp: "tbd",
              skarmnamn: "hem",
              innehallstyp: "tbd",
              modulnamn: "om sveriges radio",
              eventkalla: "webview"
            }
          }
          let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
            event: "visas",
            category: "Karusell",
            trackingLabel: "om sveriges radio",
            customDimensions: {
              66: "webview"
            },
            customMetrics: {},
            campaignUrl: null,
            trackingValue: null
          }
          appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
          appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
        },
      }
    }
)
