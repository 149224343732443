import { getBaseUrl } from "@/util/address-utils";
import { EpisodeModel } from '../../entities/episode-model'
import {CarouselModel} from "../../entities/carousel-model";
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const LatestNewsRepository = {
    async get(localChannelId: number | null): Promise<CarouselModel<EpisodeModel> | null> {
        let url = getBaseUrl() + "bff/latestNews";
        if (localChannelId !== null) {
            url = url + `?localChannelId=${localChannelId}`
        }
        return httpGetTypeOrNull<CarouselModel<EpisodeModel>>(url)
    }
}