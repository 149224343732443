
import { defineComponent, PropType } from "vue";

const imagePresets = [
  { width: 256, height: 144 },
  { width: 416, height: 234 },
  { width: 512, height: 288 },
  { width: 768, height: 432 },
  { width: 1024, height: 576 },
  { width: 1200, height: 675 },
  { width: 2048, height: 1152 },
];

export default defineComponent({
  name: "AspectRatioImage",
  props: {
    src: {
      type: String as PropType<string>,
      required: true,
    },
    alt: {
      type: String as PropType<string>,
      required: true,
    },
    horizontalRatio: {
      type: Number as PropType<number>,
      default: 16,
    },
    verticalRatio: {
      type: Number as PropType<number>,
      default: 9,
    },
    roundedBottom: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imagePresets() {
      const presets = imagePresets.map((preset, index) => {
        let srcset = `${this.srcWithoutQueryParams}?preset=${preset.width}x${preset.height}&format=webp`;
        if (index + 2 < imagePresets.length) {
          const doubledPixelDensityPreset = imagePresets[index + 2];
          srcset += ` 1x, `;
          srcset += `${this.srcWithoutQueryParams}?preset=${doubledPixelDensityPreset.width}x${doubledPixelDensityPreset.height}&format=webp 2x`;
        }

        return { ...preset, srcset };
      }, []);
      return presets;
    },
    paddingBottomStyle() {
      return `${(this.verticalRatio / this.horizontalRatio) * 100}%`;
    },
    srcWithoutQueryParams() {
      try {
        const url = new URL(this.src);
        if (!url) {
          return this.src;
        }
        return `${url.origin}${url.pathname}`;
      } catch (_) {
        return this.src;
      }
    },
  },
});
