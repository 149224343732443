import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07450a9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-row" }
const _hoisted_2 = { class: "header-row" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "play-pause" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AspectRatioImage = _resolveComponent("AspectRatioImage")!
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_stil_grid = _resolveComponent("stil-grid")!
  const _component_PauseIcon = _resolveComponent("PauseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    role: "button",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('open')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AspectRatioImage, {
        alt: $props.article?.imageAltText,
        src: $props.article?.imageUrl
      }, null, 8, ["alt", "src"]),
      _createVNode(_component_stil_grid, { flow: "row" }, {
        default: _withCtx(() => [
          _createVNode(_component_stil_text, {
            "aria-hidden": "true",
            class: "metadata",
            variant: "overline2",
            transform: "uppercase"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.article.programName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_stil_text, {
            "aria-hidden": "true",
            class: "metadata",
            variant: "overline2",
            transform: "uppercase"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.article.durationFormatted), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_stil_text, { variant: "h3" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.article.title), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        ($setup.showPauseButton)
          ? (_openBlock(), _createBlock(_component_PauseIcon, {
              key: 0,
              size: 24,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('pause')), ["stop"])),
              inverted: $setup.isDarkMode,
              role: "button",
              title: "pausa"
            }, null, 8, ["inverted"]))
          : (_openBlock(), _createBlock(_component_PlayIcon, {
              key: 1,
              size: 24,
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('play')), ["stop"])),
              inverted: $setup.isDarkMode,
              role: "button",
              title: "spela nyhet"
            }, null, 8, ["inverted"]))
      ])
    ])
  ]))
}