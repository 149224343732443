import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed531bc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "carousel",
  ref: "scrollArea"
}
const _hoisted_2 = ["onscroll"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['carousel-items', _ctx.withOutGap ? 'without-grid-gap' : '']),
      style: _normalizeStyle(`align-items: ${_ctx.alignItems}`),
      ref: "carouselItems",
      onscroll: _ctx.onScroll
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 14, _hoisted_2)
  ], 512))
}