import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4558bf7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "premium-outer-container divider"
}
const _hoisted_2 = { class: "card-padding" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardTextImageTagSkeleton = _resolveComponent("CardTextImageTagSkeleton")!
  const _component_CardLarge = _resolveComponent("CardLarge")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_CardTextImageTagSkeleton, { key: 0 }))
            : (_ctx.puffItem)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_CardLarge, _mergeProps(_ctx.puffItem, {
                    onOpen: _ctx.navigateToEpisode,
                    ariaLabel: `${_ctx.puffItem?.headline}. ${_ctx.puffItem?.text}`
                  }), null, 16, ["onOpen", "ariaLabel"])
                ])), [
                  [_directive_observe_visibility, {
    callback: (isVisible, entry) =>
      _ctx.onBecomeVisible(isVisible),
    once: true,
    throttle: 300,
  }]
                ])
              : _createCommentVNode("", true)
        ])
      ])), [
        [_directive_observe_visibility, {
    callback: (isAlmostVisible) =>
      _ctx.onAlmostVisible(isAlmostVisible),
    intersection: {
      rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN,
    },
    once: true,
    throttle: 100,
  }]
      ])
    : _createCommentVNode("", true)
}