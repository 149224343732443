
import {Article} from "../../../entities/news-collection-model";
import { ref, computed, onMounted } from 'vue';
import PauseIcon from "@/components/icons/PauseIcon.vue";
import {usePlayerStateStore} from "@/stores/playerState";
import {THEME_DARK} from '@/constants'
import {useNativeStore} from '@/stores/nativeStore'
import AspectRatioImage from '../AspectRatioImage.vue';
import PlayIcon from '../icons/PlayIcon.vue';

const elementRef = ref<Element | null>(null);

export default {
    name: 'NewsModuleCard',
    components: { PauseIcon, AspectRatioImage, PlayIcon },
    props: {
        article: {
            type: Object as () => Article | null,
            default: null,
        },
        isDarkMode: Boolean
    },
    setup(props) {
        const fontSize = ref('');

        onMounted(() => {
            const element = elementRef.value;
            if (element) {
                fontSize.value = window.getComputedStyle(element).getPropertyValue('font-size');
                setInterval(() => {
                    const newFontSize = window.getComputedStyle(element).getPropertyValue('font-size');
                    if (newFontSize !== fontSize.value) {
                        fontSize.value = newFontSize;
                    }
                }, 1000);
            }
        });
        
        const playerStateStore = usePlayerStateStore();
        const nativeStore = useNativeStore();
        
        const getNumberOfLines = computed(() => {
            const fontSizeNumber = parseInt(fontSize.value, 10);
            return fontSizeNumber > 1.1 * 16 ? "-webkit-line-clamp: 2" : "-webkit-line-clamp: 3";
        });
        const showPauseButton = computed(() => {
            return (isPlayerPlaying.value && isCurrentArticlePlaying.value);
        });
        const isPlayerPlaying = computed(() => {
            return !!playerStateStore?.isPlaying
        });
        const isCurrentArticlePlaying = computed(() => {
            return (props.article.id.toString() === playerStateStore.currentItemId)
        });
        
        const playButtonTheme = computed(() => {
            return (nativeStore.currentTheme === THEME_DARK) ? "light" : "dark"
        })
        
        return {
            elementRef,
            fontSize,
            getNumberOfLines,
            showPauseButton,
            playButtonTheme,
        };
    },
};
