import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f736dd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AspectRatioImage = _resolveComponent("AspectRatioImage")!
  const _component_LiveBadge = _resolveComponent("LiveBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AspectRatioImage, {
      src: _ctx.imageUrl,
      alt: _ctx.imageAltText,
      rounded: ""
    }, null, 8, ["src", "alt"]),
    (_ctx.isLive)
      ? (_openBlock(), _createBlock(_component_LiveBadge, {
          key: 0,
          class: "stil-badge"
        }))
      : _createCommentVNode("", true)
  ]))
}