
import {defineComponent} from 'vue';
import Carousel from "@/components/carousels/Carousel.vue";

export default defineComponent({
    name: "NewsCarousel",
    components: {Carousel},
    props: {
        trackingLabel: String,
        smallerWidth: Boolean,
        withoutGap: {
            type: Boolean,
            default: false,
        },
    }
})
