
import {defineComponent} from 'vue'
import ModuleTitle from "@/components/ModuleTitle.vue"
import * as appService from '../../public/appService.js'
import {WeatherModel} from "../../entities/weather-entity"
import {WeatherRepository} from "@/repositories/weather-repository"
import {FirebaseTrackingEvent} from '../../entities/firebase-tracking-event'
import {GoogleAnalyticsTrackingEvent} from '../../entities/google-analytics-tracking-event'
import TemporarySkeletonItem from '@/components/TemporarySkeletonItem.vue'

export default defineComponent({
        name: "Greeting",
        components: { TemporarySkeletonItem, ModuleTitle },
        data () {
            return {
                weather: null as WeatherModel | null,
                isLoading: true
            }
        },
        beforeMount () {
            window['WeatherStationSelectedHook'] = {
                components: this,
                weatherStationSelected: () => {
                    this.fetchWeather()
                }
            }
        },
        created () {
            this.fetchWeather()
        },
        methods: {
            onWeatherBecomeVisible (isVisible) {
                if (isVisible && this.weather) {
                    this.sendDisplayedEvent(this.weather.trackingLabel)
                }
            },
            sendDisplayedEvent (trackingLabel : string) {
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modul_visas",
                    parametersMap: {
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        innehallstyp: "tbd",
                        modulnamn: trackingLabel,
                        eventkalla: "webview"
                    }
                }
                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "visas",
                    category: "Karusell",
                    trackingLabel: trackingLabel,
                    customDimensions: {
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            },
            async fetchWeather () {
                const localChannelId = await appService.getLocalChannelId()
                const parsedLocalChannelId = parseInt(localChannelId);
                const hasLocalChannelId = parsedLocalChannelId && parsedLocalChannelId !== -1
                const localWeatherStation = await appService.getLocalWeatherStation()

                if (hasLocalChannelId && localWeatherStation) {
                    this.weather = await WeatherRepository.get(localChannelId, localWeatherStation)
                }
                this.isLoading = false
            },
            onWeatherClicked () {
                appService.openWeatherStationSetting()
            }
        }
    }
)
