
import { defineComponent } from "vue";
import PuffPackageComponent from "@/components/PuffPackage.vue";
import { PuffPackage } from "entities/puff-package-model";
import { IS_DEV, IS_LOCAL } from "@/util/dev-utils";
import PuffPackageContainerSkeleton from "@/components/cards/skeletons/PuffPackageContainerSkeleton.vue";

export default defineComponent({
  name: "PuffPackagePreview",
  components: {
    PuffPackageComponent,
    PuffPackageContainerSkeleton,
  },
  setup() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const puffPackageParam = urlParams.get("data");
    const backgroundColorParam = urlParams.get("backgroundColor");
    const decodedPuffPackage = decodeURIComponent(puffPackageParam as string);
    const puffPackage = JSON.parse(decodedPuffPackage);

    if (backgroundColorParam) {
      document.body.style.backgroundColor = backgroundColorParam
    }

    return {
      dataFromUrl: puffPackage as PuffPackage,
    };
  },
  data() {
    return {
      dataFromPostMessage: null as null | PuffPackage,
    };
  },
  computed: {
    hasData(): boolean {
      return Boolean(this.dataFromPostMessage || this.dataFromUrl);
    },
  },
  methods: {
    handleIncomingEvent(event) {
      const originUrl = new URL(event.origin);
      const originHost = originUrl.hostname;
      const isSrDomain = originHost.endsWith("sr.se");
      const isLocalhostInDevMode =
        originHost.endsWith("localhost") && (IS_LOCAL || IS_DEV);

      if (
        (isSrDomain || isLocalhostInDevMode) &&
        event?.data?.messageType === "puffPackagePreview"
      ) {
        const puffPackage = event?.data?.puffPackage;
        this.dataFromPostMessage = puffPackage;

        this.$nextTick(() => {
          const defaultScrollHeight = 325;
          const height = document.body.scrollHeight || defaultScrollHeight;
            window.parent.postMessage(
            {
              messageType: "puffPackagePreviewResponse",
              height,
            },
            {
              targetOrigin: event.origin,
            }
          );
        });
      }
    },
  },
  beforeMount() {
    window.addEventListener("message", this.handleIncomingEvent);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleIncomingEvent);
  },
});
