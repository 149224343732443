export function setEquals<T>(xs: Set<T>, ys: Set<T>) {
    if (xs.size !== ys.size) {
        return false;
    }
    
    let result = true;
    xs.forEach(x => {
        if (!ys.has(x)) {
            result = false;
        }
    })
    
    return result;
}