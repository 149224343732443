import {StructureModel} from "../../entities/structure-entity";
import {getBaseUrl} from "@/util/address-utils";
import {httpGetTypeOrNull} from '../api/http-get-type-or-null'

export const GetStructureUseCase = {
    async get(): Promise<StructureModel[] | null> {
        const url = getBaseUrl() + 'startpagestructure/webview'
        return httpGetTypeOrNull<StructureModel[]>(url)
    }
}

