
import {Article} from "../../../entities/news-collection-model";
import { computed } from 'vue';
import PauseIcon from "@/components/icons/PauseIcon.vue";
import {usePlayerStateStore} from "@/stores/playerState";
import {useNativeStore} from '@/stores/nativeStore'
import {THEME_DARK} from '@/constants'
import PlayIcon from '../icons/PlayIcon.vue';
import AspectRatioImage from "@/components/AspectRatioImage.vue";

export default {
    name: 'NewsCarouselCardSmall',
    components: {AspectRatioImage, PauseIcon, PlayIcon },
    props: {
        article: {
            type: Object as () => Article | null,
            default: null,
        },
    },
    setup(props) {
        
        const playerStateStore = usePlayerStateStore();
        const nativeStore = useNativeStore();
        
        const showPauseButton = computed(() => {
            return (isPlayerPlaying.value && isCurrentArticlePlaying.value);
        });
        const isPlayerPlaying = computed(() => {
            return !!playerStateStore?.isPlaying
        });
        const isCurrentArticlePlaying = computed(() => {
            return (props.article.id.toString() === playerStateStore.currentItemId)
        });
        const isDarkMode = computed(() => {
          return nativeStore.currentTheme === THEME_DARK
        });
        
        return {
            showPauseButton,
            isDarkMode,
        };
    },
};
