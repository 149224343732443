
import { PropType, defineComponent } from "vue";
import PauseIcon from "@/components/icons/PauseIcon.vue";
import PlayIcon from "@/components/icons/PlayIcon.vue";

export default defineComponent({
  name: "PlayPause",
  components: {
    PauseIcon,
    PlayIcon,
  },
  props: {
    isPlaying: {
      type: Boolean,
      required: true,
    },
    onPlay: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onPause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 40,
    },
  },
});
