import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PauseIcon = _resolveComponent("PauseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_stil_grid = _resolveComponent("stil-grid")!
  const _component_AspectRatioImage = _resolveComponent("AspectRatioImage")!
  const _component_stil_card = _resolveComponent("stil-card")!

  return (_openBlock(), _createBlock(_component_stil_card, {
    as: "button",
    "aria-label": _ctx.ariaLabel,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPauseButton ? _ctx.$emit('pause') : _ctx.$emit('play')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_stil_grid, {
        slot: "header",
        flow: "column",
        columns: "auto 1fr",
        p: 2,
        gap: 1
      }, {
        default: _withCtx(() => [
          (_ctx.showPauseButton)
            ? (_openBlock(), _createBlock(_component_PauseIcon, { key: 0 }))
            : (_openBlock(), _createBlock(_component_PlayIcon, { key: 1 })),
          _createVNode(_component_stil_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_stil_text, {
                variant: "h3",
                as: "h3",
                colorInverted: "",
                colorStatic: "",
                truncateLine: 1
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.listHeadline), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_stil_text, {
                variant: "caption",
                as: "p",
                color: "secondary",
                colorInverted: "",
                colorStatic: "",
                truncateLine: 1
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.meta), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AspectRatioImage, {
        slot: "media",
        src: _ctx.imageSrc || '',
        alt: _ctx.imageAlt || '',
        roundedBottom: "",
        gradient: ""
      }, null, 8, ["src", "alt"]),
      _createVNode(_component_stil_grid, {
        slot: "media-content",
        flow: "row",
        gap: 1,
        p: 2
      }, {
        default: _withCtx(() => [
          _createVNode(_component_stil_text, {
            as: "p",
            variant: "overline2",
            truncateLine: 1,
            color: "highlight",
            transform: "uppercase"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tag), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_stil_text, {
            as: "h5",
            variant: "h5",
            truncateLine: 2,
            colorStatic: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.newsHeadline), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["aria-label"]))
}