import { parseVersion } from "../util/version-parser";
import {defineStore} from "pinia"

export const useVersionStore = defineStore("version", {
  state: () => {
    return {
      version: null as number | null,
      versionLoaded: false
    };
  },
  actions: {
    async getAndSetVersion() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const versionParam = params['version']

      this.version = parseVersion(versionParam);
    }
  },
  getters: {
    webViewVersion: (state) => {
      return state.version
    }
  }
});
