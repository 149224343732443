import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39004fae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article-card" }
const _hoisted_2 = { class: "left-content" }
const _hoisted_3 = { class: "title-and-play" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AspectRatioImage = _resolveComponent("AspectRatioImage")!
  const _component_PauseIcon = _resolveComponent("PauseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AspectRatioImage, {
        src: $props.article.imageUrl,
        alt: $props.article.imageAltText,
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open'))),
        horizontalRatio: 4,
        verticalRatio: 3,
        rounded: ""
      }, null, 8, ["src", "alt"]),
      _createElementVNode("div", {
        ref: "element",
        class: "article-title",
        style: _normalizeStyle($setup.getNumberOfLines),
        role: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open')))
      }, _toDisplayString($props.article.title), 5)
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.showPauseButton)
        ? (_openBlock(), _createBlock(_component_PauseIcon, {
            key: 0,
            size: 24,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('pause'))),
            inverted: $props.isDarkMode,
            role: "button",
            title: "pausa",
            "aria-label": "pausa"
          }, null, 8, ["inverted"]))
        : (_openBlock(), _createBlock(_component_PlayIcon, {
            key: 1,
            size: 24,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('play'))),
            inverted: $props.isDarkMode,
            role: "button",
            title: "spela nyhet",
            "aria-label": "spela nyhet"
          }, null, 8, ["inverted"]))
    ])
  ]))
}