
import "@sverigesradio/stil/components/stil-card.js";
import "@sverigesradio/stil/components/stil-grid.js";
import "@sverigesradio/stil/components/stil-image.js";
import "@sverigesradio/stil/components/stil-text.js";
import "@sverigesradio/stil/components/stil-badge-live.js";
import { PropType, defineComponent } from "vue";
import PauseIcon from "@/components/icons/PauseIcon.vue";
import PlayIcon from "../icons/PlayIcon.vue";
import { PuffItemType } from "entities/puff-package-model";
import { usePlayerStateStore } from "@/stores/playerState";
import PlayPause from "../PlayPause.vue";
import { useNativeStore } from "@/stores/nativeStore";
import { THEME_DARK } from "@/constants";
import CardImage from "./CardImage.vue";

export default defineComponent({
  name: "CardLarge",
  components: {
    PauseIcon,
    PlayIcon,
    PlayPause,
    CardImage,
  },
  setup() {
    return {
      playerStateStore: usePlayerStateStore(),
      nativeStore: useNativeStore(),
    };
  },
  computed: {
    isPlaying(): boolean {
      return (
        this.playerStateStore?.isPlaying &&
        this.playerStateStore?.currentItemId === this.id.toString()
      );
    },
    isDarkMode(): boolean {
      return this.nativeStore.currentTheme === THEME_DARK;
    },
    hasPlayButton(): boolean {
      return this.onPlay !== undefined && this.onPause !== undefined;
    },
    isLive(): boolean {
      return this.type === "Live";
    },
  },
  props: {
    imageUrl: {
      type: String as PropType<string>,
      required: true,
    },
    imageAltText: {
      type: String as PropType<string>,
      required: true,
    },
    id: {
      type: Number as PropType<number>,
      required: true,
    },
    headline: {
      type: String as PropType<string>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    ariaLabel: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<PuffItemType>,
      required: true,
    },
    programName: {
      type: String as PropType<string>,
      required: true,
    },
    durationFormatted: {
      type: String as PropType<string>,
      required: true,
    },
    onPlay: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
    onOpen: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onPause: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
  },
});
