
import { defineComponent } from "vue";

export default defineComponent({
  name: "LineSkeleton",
  props: {
    width: {
      type: Number,
      default: 142,
    },
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
        height: "8px",
        "background-color": "var(--color-foreground-quaternary)",
        "border-radius": "4px",
      };
    },
  },
});
