import {LogData, LogLevel, PostLogData} from "./logger-models";
import httpClient from "../api/http-client";

export const logger = {
    logDebug: (message: string, error: Error): void => {
        log('debug', {message, error});
    },
    logError: (message: string, error: Error, url: string): void => {
        log('error', {message, error, url});
    }
};

async function log(level: LogLevel, data: LogData): Promise<void> {
    // The /bff/log endpoint didn't do anything (the code was commented out), and it threw a lot of 500 errors.
    // We'll just disable this, since it will be handled differently in the WebCore stack.
    return;

    try {
        const postData: PostLogData = mapToPostData(level, data);
        const url = '/bff/log';
        await httpClient.post<void>(url, postData);
    } catch (err) {
        console.error('Error logging failed.', err);
    }
}


function mapToPostData(level: LogLevel, data: LogData): PostLogData {
    return {
        level: level,
        message: data.message,
        source: data.source,
        jsonData: JSON.stringify(data.data),
        exceptionMessage: data.error?.message,
        exceptionType: data.error?.name,
        stacktrace: data.error?.stack,
        url: data.url || window.location.href,
        referrer: window.location.href,
        userAgent: 'tbd'
    };
}
