import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["news-carousel", {'smaller-width': _ctx.smallerWidth}])
  }, [
    _createVNode(_component_Carousel, {
      trackingLabel: _ctx.trackingLabel,
      "align-items": "start",
      withOutGap: true
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["trackingLabel"])
  ], 2))
}