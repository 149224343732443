
import { defineComponent } from "vue";
import Carousel from "@/components/carousels/Carousel.vue";

export default defineComponent({
  name: "CardMediumCarousel",
  components: { Carousel },
  props: {
    trackingLabel: String,
    smallerWidth: Boolean,
  },
});
