
import {defineComponent} from 'vue';
import * as appService from '../../public/appService.js';
import {NewsCollectionModel} from '../../entities/news-collection-model';
import {FirebaseTrackingEvent} from '../../entities/firebase-tracking-event';
import {GoogleAnalyticsTrackingEvent} from '../../entities/google-analytics-tracking-event';
import {CardImageCollection, CardImageCollectionSkeleton} from '@sverigesradio/dpp-vue-3-components';
import TemporarySkeletonItem from '@/components/TemporarySkeletonItem.vue';
import NewsCarousel from "@/components/carousels/NewsCarousel.vue";
import {ON_ALMOST_VISIBLE_ROOT_MARGIN} from "@/constants";
import {NewsCollectionRepository} from "@/usecases/news-collection-repository";
import {useExperimentStore} from "@/stores/experiments";
import {useVersionStore} from "@/stores/version";
import TopNewsChooseP4Item from '@/components/TopNewsChooseP4Item.vue';

export default defineComponent({
        name: "LocalNewsPlaylist",
        components: {TemporarySkeletonItem, NewsCarousel, CardImageCollection, CardImageCollectionSkeleton, TopNewsChooseP4Item,},
        data() {
            return {
                localChannelId: null as number | null | undefined,
                showModule: false,
                isLoading: true,
                newsCollection: null as NewsCollectionModel | null,
                abTrackingLabel: null as string | null,
                ON_ALMOST_VISIBLE_ROOT_MARGIN,
                componentKey: 0
            };
        },
        beforeMount() {
            window['LocalChannelSelectedLocalNewsPlaylistHook'] = {
                components: this,
                localChannelSelected: () => this.fetchData(),
            };
        },
        computed: {
            hasData(): boolean {
                return this.newsCollection !== null;
            },
            isLocalChannelSelected(): boolean {
                return !!this.localChannelId
            }
        },
        setup() {
            return {
                experimentStore: useExperimentStore(),
                versionStore: useVersionStore()
            };
        },
        methods: {
            playNewsCollection(newsCollectionModel) {
                newsCollectionModel.type = "playArticleCollection";
                appService.playNewsCollection(JSON.stringify(newsCollectionModel));
                this.sendClickedTrackingEvents(newsCollectionModel);
            },
            chooseP4() {
                appService.showLocalChannelSelection()
            },
            async fetchData() {
                const webViewVersion = this.versionStore.webViewVersion
                if (webViewVersion !== null && webViewVersion >= 1.1) {
                    this.localChannelId = await appService.getLocalChannelId();
                    this.showModule = this.experimentStore.start_show_local_news_single;
                    if (this.showModule && this.isLocalChannelSelected && this.localChannelId) {
                        this.newsCollection = await NewsCollectionRepository.getLocalNewsPlaylist(this.localChannelId);
                    }
                }
                this.isLoading = false;
                this.forceRerender()
            },
            forceRerender() {
                this.componentKey += 1;
            },
            onAlmostVisible(isAlmostVisible) {
                if (isAlmostVisible) {
                    this.fetchData();

                    window['WebViewVisibleIntervalEventLocalNewsHook'] = {
                        components: this,
                        update: () => {
                            this.fetchData();
                        },
                    };
                }
            },
            onBecomeVisible(isVisible) {
                if (isVisible) {
                    this.sendDisplayedTrackingEvents();
                }
            },
            sendClickedTrackingEvents(newsCollection: NewsCollectionModel) {
                let title = "";
                if (newsCollection.genre == "Local") {
                    title = "lokala nyheter";
                } else {
                    title = newsCollection.listHeadline.toLowerCase();
                }
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modulstart",
                    parametersMap: {
                        spellista: newsCollection.listHeadline,
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        ursprung: "tbd",
                        innehallstyp: "tbd",
                        modulnamn: newsCollection.trackingLabel,
                        eventkalla: "webview",
                    },
                };

                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "start",
                    category: "Karusell",
                    trackingLabel: newsCollection.trackingLabel,
                    customDimensions: {
                        36: title,
                        46: title,
                        66: "webview",
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null,
                };
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);
            },
            sendDisplayedTrackingEvents() {
                if (this.newsCollection) {
                    let firebaseTrackingEvent: FirebaseTrackingEvent = {
                        eventName: "modul_visas",
                        parametersMap: {
                            modultyp: "tbd",
                            skarmnamn: "hem",
                            innehallstyp: "tbd",
                            modulnamn: this.newsCollection.trackingLabel,
                            eventkalla: "webview",
                        },
                    };
                    let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                        event: "visas",
                        category: "Karusell",
                        trackingLabel: this.newsCollection.trackingLabel,
                        customDimensions: {
                            66: "webview",
                        },
                        customMetrics: {},
                        campaignUrl: null,
                        trackingValue: null,
                    };
                    appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
                    appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);
                }
            },
        },
    },
);
