
import { defineComponent } from "vue";
import ModuleTitle from "@/components/ModuleTitle.vue";
import { EditorialCollectionsRepository } from "@/usecases/editorial-collections-repository";
import * as appService from "../../public/appService.js";
import { EditorialCollectionModel } from "../../entities/editorial-collection-entity";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { GoogleAnalyticsTrackingEvent } from "../../entities/google-analytics-tracking-event";
import { CardTextMetaImage } from "@sverigesradio/dpp-vue-3-components";
import TemporarySkeletonItem from "@/components/TemporarySkeletonItem.vue";
import { Source } from "../../entities/picture-model";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import { KilkayaClickEvent, kilkayaClick } from "@/util/tracking-utils";

export default defineComponent({
  name: "EditorialCollection",
  components: { TemporarySkeletonItem, ModuleTitle, CardTextMetaImage },
  props: {
    title: String,
    variant: String,
    moduleName: String,
  },
  data() {
    let editorialCollectionViewModels: any[] = [];
    let editorialCollectionInteractionModels: EditorialCollectionModel[] = [];

    return {
      isLoading: true,
      display: false,
      editorialCollectionViewModels,
      editorialCollectionInteractionModels,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  computed: {
    hasData(): boolean {
      return this.editorialCollectionInteractionModels.length > 0;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    navigateToEditorialCollection(index) {
      const editorialCollection =
        this.editorialCollectionInteractionModels[index];

      if (editorialCollection) {
        appService.openEditorialCollection(
          editorialCollection.id,
          this.variant,
          index,
          editorialCollection.trackingLabel
        );
        this.sendEditorialCollectionClickedTrackingEvents(editorialCollection);
      }
    },
    async fetchData() {
      if (this.variant) {
        const editorialCollections = await EditorialCollectionsRepository.get(
          this.variant
        );
        this.isLoading = false;

        if (editorialCollections != null) {
          this.editorialCollectionInteractionModels = editorialCollections;
          this.editorialCollectionViewModels = editorialCollections.map(
            (item) => {
              const sources = item.picture.sources.map(
                (source: any): Source => ({
                  url: source.url,
                  media: source.media,
                })
              );
              return {
                headline: item.title,
                description: item.description,
                meta: item.itemCount + " avsnitt",
                ariaLabel: item.ariaLabel,
                picture: {
                  defaultUrl: item.picture.defaultUrl,
                  sources: sources,
                },
              };
            }
          );
        }
      }
    },
    onAlmostVisible(isAlmostVisible) {
      // Lazy load only images, not data (data is fetched in each module and we do not
      // want the same editorial collection to be shown more than once if editors change the order)
      if (isAlmostVisible) {
        this.display = true;
      }
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvent();
      }
    },
    sendEditorialCollectionClickedTrackingEvents(
      editorialCollection: EditorialCollectionModel
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          spellista: editorialCollection.title,
          modultyp: "samling",
          skarmnamn: "hem",
          modulnamn: editorialCollection.trackingLabel,
        },
      };
      let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
        event: "klick_objekt",
        category: "Karusell",
        trackingLabel: editorialCollection.trackingLabel,
        customDimensions: {
          46: editorialCollection.title,
          36: "playdesken",
          66: "webview",
        },
        customMetrics: {},
        campaignUrl: null,
        trackingValue: null,
      };
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
      appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);

      const kilkayaEvent: KilkayaClickEvent = {
        audioType: "Episode",
        channelName: "",
        soundName: "",
        moduleTitle: this.title || "",
        moduleName: this.moduleName || "",
        id: editorialCollection.id,
        itemHeadline: editorialCollection.title,
        interactionType: "klick",
        programName: "",
      };
      kilkayaClick(kilkayaEvent);
    },
    sendDisplayedTrackingEvent() {
      if (this.editorialCollectionInteractionModels[0]) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "samling",
            skarmnamn: "hem",
            modulnamn:
              this.editorialCollectionInteractionModels[0].trackingLabel,
          },
        };
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "visas",
          category: "Karusell",
          trackingLabel:
            this.editorialCollectionInteractionModels[0].trackingLabel,
          customDimensions: {
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
  },
});
