import { channelsById } from "@/data/channelsById";
import { PuffItemType } from "entities/puff-package-model";

export const mapChannelIdToChannelName = (channelId: number) => channelsById[String(channelId)]?.name

export function getTrackingAudioType(audioType: PuffItemType): string {
  if (audioType === "Article") {
    return "klipp";
  } else if (audioType === "Episode") {
    return "avsnitt";
  } else if (audioType === "Live") {
    return "direkt";
  } else if (audioType === "Interactive") {
    return "";
  } else {
    return audioType;
  }
}

export type KilkayaClickEvent = {
  audioType: PuffItemType,
  channelName: string,
  soundName: string,
  moduleTitle: string,
  moduleName: string,
  position?: number,
  id: number,
  itemHeadline: string,
  interactionType: "spela" | "klick" | "spela_alla" | "klick_fler_nyheter",
  programName: string,
  origin?: string
}

export function kilkayaClick(event: KilkayaClickEvent) {
  const tags = [getTrackingAudioType(event.audioType)]
  if (event.origin) tags.push(event.origin)

  const toUrl = event.id !== -1 ? `${location.origin}/${event.id}` : `${location.href}/${event.itemHeadline}/${event.interactionType}`
  const kilkayaEvent = {
    url: location.href,
    type: event.soundName,
    Position:
    event.moduleTitle === "just nu" ? event.itemHeadline : event.moduleTitle,
    section: event.moduleName,
    Pos: event.position,
    tag: tags,
    toUrl: toUrl,
    toTitle: event.itemHeadline,
    toType: event.audioType,
    cntTag: [event.interactionType],
    toAuthor: event.programName,
  }
  window["kilkaya"].click(kilkayaEvent);
}