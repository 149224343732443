// NATIVE EVENTS
const ListeningHistoryUpdated = "LISTENING_HISTORY_UPDATED";
const LocalChannelSelected = "LOCAL_CHANNEL_SELECTED";
const ExtraChannelSelected = "EXTRA_CHANNEL_SELECTED";
const WeatherStationSelected = "WEATHER_STATION_SELECTED";
const FontSizeUpdated = "FONT_SIZE_UPDATED";
const ThemeColorUpdated = "THEME_COLOR_UPDATED";
const WebViewResumed = "WEBVIEW_RESUMED";
const PlayerPlaying = "PLAYER_PLAYING";
const PlayerStopped = "PLAYER_STOPPED";
const PlaylistItemDidChange = "PLAYLIST_ITEM_DID_CHANGE";

// HOOKS
export const FontSizeUpdatedHook = "FontSizeUpdatedHook";

window.nativeEvent = function (msg) {
  if (msg === PlayerPlaying) {
    window.PlayerPlayingHook && window.PlayerPlayingHook.playerPlaying();
  }
  if (msg === PlayerStopped) {
    window.PlayerStoppedHook && window.PlayerStoppedHook.playerStopped();
  }
  if (msg === PlaylistItemDidChange) {
    window.PlaylistItemDidChangeHook &&
      window.PlaylistItemDidChangeHook.update();
  }
  if (msg === ListeningHistoryUpdated) {
    window.ListeningHistoryPrograms &&
      window.ListeningHistoryPrograms.listeningHistoryUpdated();
  }
  if (msg === LocalChannelSelected) {
    window.LocalChannelSelectedNewsBroadcastsHook &&
      window.LocalChannelSelectedNewsBroadcastsHook.localChannelSelected();
    window.LocalChannelSelectedTopNewsHook &&
      window.LocalChannelSelectedTopNewsHook.localChannelSelected();
    window.LocalChannelSelectedHook &&
      window.LocalChannelSelectedHook.localChannelSelected();
    window.LocalChannelSelectedLocalNewsPlaylistHook &&
      window.LocalChannelSelectedLocalNewsPlaylistHook.localChannelSelected();
  }
  if (msg === ExtraChannelSelected) {
    window.ExtraChannelSelectedHook &&
      window.ExtraChannelSelectedHook.extraChannelSelected();
  }
  if (msg === WeatherStationSelected) {
    window.WeatherStationSelectedHook &&
      window.WeatherStationSelectedHook.weatherStationSelected();
  }
  if (msg === FontSizeUpdated) {
    window[FontSizeUpdatedHook] &&
      window[FontSizeUpdatedHook].fontSizeUpdated();
  }
  if (msg === ThemeColorUpdated) {
    window.ThemeColorUpdatedHook &&
      window.ThemeColorUpdatedHook.themeColorUpdated();
  }
  if (msg === WebViewResumed) {
    window.WebViewVisibleIntervalEventLiveHook &&
      window.WebViewVisibleIntervalEventLiveHook.update();
    window.WebViewVisibleIntervalEventOldTopNewsHook &&
      window.WebViewVisibleIntervalEventOldTopNewsHook.update();
    window.WebViewVisibleIntervalEventTopNewsHook &&
      window.WebViewVisibleIntervalEventTopNewsHook.update();
    window.WebViewVisibleIntervalEventStandaloneTopNewsHook &&
      window.WebViewVisibleIntervalEventStandaloneTopNewsHook.update();
    window.WebViewVisibleIntervalEventPuffPackageHook &&
      window.WebViewVisibleIntervalEventPuffPackageHook.update() 
    window.WebViewVisibleIntervalCuratedNewsHook &&
      window.WebViewVisibleIntervalCuratedNewsHook.update();
    window.WebViewVisibleIntervalEventLocalNewsHook &&
      window.WebViewVisibleIntervalEventLocalNewsHook.update();
    window.WebViewVisibleIntervalEventRightNowHook &&
      window.WebViewVisibleIntervalEventRightNowHook.update();
    window.WebViewVisibleIntervalEventStartPageHook &&
      window.WebViewVisibleIntervalEventStartPageHook.update();
    window.PlaylistItemDidChangeHook &&
      window.PlaylistItemDidChangeHook.update();
  }
};
