import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a1a8900"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "column-left" }
const _hoisted_4 = { class: "column-right" }
const _hoisted_5 = { class: "column-right-text" }
const _hoisted_6 = { class: "column-right-button" }
const _hoisted_7 = { class: "bottom-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_PauseIcon = _resolveComponent("PauseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_news_module_card = _resolveComponent("news-module-card")!
  const _component_stil_icon = _resolveComponent("stil-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'news-module-card',
        _ctx.isLastChild ? 'without-right-padding' : '',
        _ctx.newsCollectionModel.genre === 'Local' ? 'without-left-padding' : '',
      ])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.newsCollectionModel.genre === 'Local')
                ? (_openBlock(), _createBlock(_component_stil_text, {
                    key: 0,
                    as: "span",
                    variant: "caption",
                    truncateLine: 1,
                    color: "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.newsCollectionModel.listHeadline), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_stil_text, {
                as: "h2",
                variant: "h2",
                truncateLine: 2,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.newsCollectionModel.newsModuleHeadline), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_stil_text, {
                  as: "span",
                  variant: "overline1",
                  truncateLine: 2,
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.newsCollectionModel.modulePlayText), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_stil_text, {
                  as: "span",
                  variant: "caption",
                  truncateLine: 2,
                  color: "secondary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.newsCollectionModel.moduleDuration), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.showPauseButton)
                  ? (_openBlock(), _createBlock(_component_PauseIcon, {
                      key: 0,
                      onClick: _ctx.pausePlayer,
                      inverted: _ctx.isDarkMode,
                      size: 32,
                      role: "button",
                      title: "pausa",
                      "aria-label": "pausa"
                    }, null, 8, ["onClick", "inverted"]))
                  : (_openBlock(), _createBlock(_component_PlayIcon, {
                      key: 1,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.playAllClicked(_ctx.newsCollectionModel))),
                      inverted: _ctx.isDarkMode,
                      size: 32,
                      role: "button",
                      title: 
                'spela alla nyheter i ' + _ctx.newsCollectionModel.newsModuleHeadline
              ,
                      "aria-label": 
                'spela alla nyheter i ' + _ctx.newsCollectionModel.newsModuleHeadline
              
                    }, null, 8, ["inverted", "title", "aria-label"]))
              ])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.newsCollectionModel?.articles || []).slice(
          0,
          3
        ), (article, index) => {
            return (_openBlock(), _createBlock(_component_news_module_card, {
              article: article,
              "is-dark-mode": _ctx.isDarkMode,
              key: article.id,
              onOpen: ($event: any) => (_ctx.articleClicked(article.id, index, _ctx.newsCollectionModel)),
              onPlay: ($event: any) => (_ctx.playArticleClicked(_ctx.newsCollectionModel, index)),
              onPause: _ctx.pausePlayer
            }, null, 8, ["article", "is-dark-mode", "onOpen", "onPlay", "onPause"]))
          }), 128)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "navigation",
              role: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigationClicked(_ctx.newsCollectionModel)))
            }, [
              _createVNode(_component_stil_text, {
                class: "navigation-text",
                as: "p",
                variant: "body",
                truncateLine: 1,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.newsCollectionModel.navigationText), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_stil_icon, {
                class: "chevron",
                variant: "chevron-down",
                size: "16"
              })
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.isLastChild)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle({
        'outline-color': _ctx.isDarkMode
          ? 'var(--color-foreground-tertiary-darkmode)'
          : 'var(--color-foreground-tertiary-lightmode)',
      }),
          class: "border-divider"
        }, null, 4))
      : _createCommentVNode("", true)
  ]))
}