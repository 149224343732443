
import { defineComponent } from "vue";
import * as appService from "../../public/appService.js";
import {
  NewsCollectionModel,

} from "../../entities/news-collection-model";
import { NewsCollectionRepository } from "@/usecases/news-collection-repository";
import {
  CardImageCollection,
  CardImageCollectionSkeleton,
} from "@sverigesradio/dpp-vue-3-components";
import TemporarySkeletonItem from "@/components/TemporarySkeletonItem.vue";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN, THEME_DARK } from "@/constants";
import NewsCarousel from "@/components/carousels/NewsCarousel.vue";
import FixedNewsCarousel from "@/components/carousels/FixedNewsCarousel.vue";
import "@sverigesradio/stil/components/CardImageCollection.lit.js";
import "@sverigesradio/stil/components/stil-button-rounded.js";
import { useExperimentStore } from "@/stores/experiments";
import { useVersionStore } from "@/stores/version";
import Carousel from "@/components/carousels/Carousel.vue";
import TopNewsChooseP4Item from "@/components/TopNewsChooseP4Item.vue";
import NewsCard from "@/components/cards/NewsCard.vue";
import "@sverigesradio/stil/components/stil-card.js";
import "@sverigesradio/stil/components/stil-grid.js";
import "@sverigesradio/stil/components/stil-image.js";
import "@sverigesradio/stil/components/stil-text.js";
import TopNewsCard from "@/components/TopNewsCard.vue";
import Pill from "@/components/Pill.vue";
import { useNativeStore } from "@/stores/nativeStore";
import StandaloneNewsModuleCard from "@/components/cards/StandaloneNewsModuleCard.vue";
import StandaloneTopNewsCarousel from "@/components/carousels/StandaloneTopNewsCarousel.vue";
import { NativeRepository } from "@/repositories/native-repository";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { wrapAround } from "@/util/array-utils";
import PlayIcon from "@/components/icons/PlayIcon.vue";
import PauseIcon from "@/components/icons/PauseIcon.vue";
import { setEquals } from "@/util/set-utils";
import { usePlayerStateStore } from "@/stores/playerState";
import NewsCarouselCardSmall from "./cards/NewsCarouselCardSmall.vue";
import { GoogleAnalyticsTrackingEvent } from "entities/google-analytics-tracking-event";
import CardMedium from "@/components/cards/CardMedium.vue";

export default defineComponent({
  name: "FixedNews",
  components: {
    CardMedium,
    PlayIcon,
    PauseIcon,
    Pill,
    StandaloneTopNewsCarousel,
    StandaloneNewsModuleCard,
    TopNewsCard,
    TopNewsChooseP4Item,
    Carousel,
    TemporarySkeletonItem,
    NewsCarousel,
    CardImageCollection,
    CardImageCollectionSkeleton,
    NewsCard,
    NewsCarouselCardSmall,
    FixedNewsCarousel,
  },
  data() {
    return {
      localChannelId: null as number | null | undefined,
      isLoading: true,
      newsCollection: null as NewsCollectionModel[] | null,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
      hideLocalNewsPlaylist: false,
      selectedPlaylistIndex: 0,
    };
  },
  beforeMount() {
    window["LocalChannelSelectedTopNewsHook"] = {
      components: this,
      localChannelSelected: () => this.fetchData(),
    };
  },
  computed: {
    hasData(): boolean {
      return this.newsCollection !== null && this.newsCollection?.length > 0;
    },
    showChooseLocalChannelItem(): boolean {
      return (
        !this.localChannelId &&
        this.playlistSelection?.[this.selectedPlaylistIndex]
          ?.newsCollectionIndex === -1
      );
    },
    selectedPlaylist(): NewsCollectionModel | null | undefined {
      const playlistIndex =
        this.playlistSelection?.[this.selectedPlaylistIndex]
          .newsCollectionIndex ?? -1;
      return this.newsCollection?.[playlistIndex];
    },
    playlistSelection():
      | { listHeadline: string; newsCollectionIndex: number }[]
      | null {
      if (!this.newsCollection) {
        return [];
      }
      const selectionData = this.newsCollection?.map(
        ({ newsModuleHeadline }, index) => {
          return {
            listHeadline: newsModuleHeadline,
            newsCollectionIndex: index,
          };
        }
      );
      if (!this.localChannelId) {
        return [
          { listHeadline: "Lokalt", newsCollectionIndex: -1 },
          ...selectionData,
        ];
      }
      return selectionData;
    },
    isDarkMode(): boolean {
      return this.nativeStore.currentTheme === THEME_DARK;
    },
    showPauseButton(): boolean {
      return this.isPlayerPlaying && this.isCurrentPlaylistPlaying;
    },
    isCurrentPlaylistPlaying(): boolean {
      const playerStateArticlesSet = new Set(
        (this.playerStateStore?.idsOfCurrentPlaylist || "").split(", ")
      );
      const playlistArticlesSet = new Set(this.idsOfArticlesInPlaylist);
      return setEquals(playerStateArticlesSet, playlistArticlesSet);
    },
    isPlayerPlaying(): boolean {
      return !!this.playerStateStore?.isPlaying;
    },
    idsOfArticlesInPlaylist(): string[] {
      if (this.selectedPlaylist) {
        return this.selectedPlaylist.articles.map((article) =>
          article.id.toString()
        );
      } else {
        return [];
      }
    },
  },
  setup() {
    return {
      experimentStore: useExperimentStore(),
      versionStore: useVersionStore(),
      nativeStore: useNativeStore(),
      playerStateStore: usePlayerStateStore(),
    };
  },
  methods: {
    chooseP4() {
      appService.showLocalChannelSelection();
    },
    async fetchData() {
      this.localChannelId = await appService.getLocalChannelId();
      var webViewVersion = this.versionStore.webViewVersion;
      if (webViewVersion !== null && webViewVersion >= 1.1) {
        this.hideLocalNewsPlaylist =
          this.experimentStore.start_show_local_news_single;
      }

      this.newsCollection =
        (await NewsCollectionRepository.getNewsPlaylists(
          this.localChannelId
        )) || [];
      this.isLoading = false;
    },
    onAlmostVisible(isAlmostVisible) {
      if (isAlmostVisible) {
        this.fetchData();

        window["WebViewVisibleIntervalEventTopNewsHook"] = {
          components: this,
          update: () => {
            this.fetchData();
          },
        };
      }
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvents();
      }
    },
    selectPlaylist(index) {
      this.selectedPlaylistIndex = index;
      this.sendSelectPlaylistTrackingEvent(index);
    },
    async articleClicked(
      articleId,
      index: Number,
      newsCollectionModel: NewsCollectionModel | null | undefined
    ) {
      await this.openNewsArticle(articleId);
      if (newsCollectionModel) {
        this.sendArticleClickTrackingEvent(newsCollectionModel, index);
      }
    },
    async openNewsArticle(articleId) {
      NativeRepository.openNewsArticle(articleId);
    },
    playArticleClicked(newsCollectionModel, index: number) {
      this.playNewsCollection(newsCollectionModel, index);
      this.sendPlayArticleTrackingEvent(newsCollectionModel, index);
    },
    playNewsCollection(newsCollectionModel, startIndex: number = 0) {
      const wrappedAroundArticles = wrapAround(
        newsCollectionModel.articles,
        startIndex
      );
      const newsCollectionToPlay = {
        ...newsCollectionModel,
        articles: wrappedAroundArticles,
        startIndex: 0,
        type: "playArticleCollection",
      };
      appService.playNewsCollection(JSON.stringify(newsCollectionToPlay));
    },
    pausePlayer() {
      NativeRepository.pausePlayer();
    },
    async navigationClicked(newsCollectionModel: NewsCollectionModel) {
      await NativeRepository.openNewsPage(
        newsCollectionModel,
        this.localChannelId
      );
      this.sendNavigationClickTrackingEvent(newsCollectionModel);
    },
    playAllClicked(newsCollectionModel) {
      this.playNewsCollection(newsCollectionModel, 0);
      this.sendPlayAllTrackingEvent(newsCollectionModel);
    },
    sendPlayArticleTrackingEvent(
      newsCollectionModel: NewsCollectionModel,
      index
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          modulnamn: newsCollectionModel.trackingLabel,
          modultyp: "nyhetsmodul",
          skarmnamn: "hem",
          spellista: newsCollectionModel.newsModuleHeadline,
          kanalnamn: newsCollectionModel.articles[index].channelName,
          ljudlangd: Math.round(
            newsCollectionModel.articles[index].audio.durationInMillis /
              1000 /
              60
          ).toFixed(0),
          klickinteraktion: "spela",
          listposition: index.toFixed(0),
        },
      };
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
    },
    sendArticleClickTrackingEvent(
      newsCollectionModel: NewsCollectionModel,
      index: Number
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          modulnamn: newsCollectionModel.trackingLabel,
          modultyp: "nyhetsmodul",
          skarmnamn: "hem",
          spellista: newsCollectionModel.newsModuleHeadline,
          klickinteraktion: "klick",
          listposition: index.toFixed(0),
        },
      };
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
    },
    sendNavigationClickTrackingEvent(newsCollectionModel: NewsCollectionModel) {
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          modulnamn: newsCollectionModel.trackingLabel,
          modultyp: "nyhetsmodul",
          skarmnamn: "hem",
          spellista: newsCollectionModel.newsModuleHeadline,
          klickinteraktion: "klick",
          listposition: "fler_nyheter",
        },
      };
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
    },
    sendSelectPlaylistTrackingEvent(playlistIndex) {
      const playlist = this.playlistSelection?.[playlistIndex];

      if (!playlist) {
        return;
      }

      const firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "nyhetsmodul_piller",
        parametersMap: {
          nyhetsmodul_kategori: playlist.listHeadline,
          skarmnamn: "hem",
        },
      };

      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
    },
    sendDisplayedTrackingEvents() {
      if (this.newsCollection) {
        if (this.newsCollection?.[0]) {
          let firebaseTrackingEvent: FirebaseTrackingEvent = {
            eventName: "modul_visas",
            parametersMap: {
              modultyp: "tbd",
              skarmnamn: "hem",
              innehallstyp: "tbd",
              modulnamn: this.newsCollection[0].trackingLabel,
              eventkalla: "webview",
            },
          };
          let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
            event: "visas",
            category: "Karusell",
            trackingLabel: this.newsCollection[0].trackingLabel,
            customDimensions: {
              66: "webview",
            },
            customMetrics: {},
            campaignUrl: null,
            trackingValue: null,
          };
          appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
          appService.sendGoogleAnalyticsTrackingEvent(
            googleAnalyticsTrackingEvent
          );
        }
      }
    },
    sendPlayAllTrackingEvent(newsCollectionModel: NewsCollectionModel) {
      let title = "";
      if (newsCollectionModel.genre == "Local") {
        title = "lokala nyheter";
      } else {
        title = newsCollectionModel.listHeadline.toLowerCase();
      }
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          modulnamn: newsCollectionModel.trackingLabel,
          modultyp: "nyhetsmodul",
          skarmnamn: "hem",
          spellista: newsCollectionModel.trackingLabel,
          klickinteraktion: "spela",
          listposition: "spela_alla",
        },
      };
      let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
        event: "start",
        category: "Karusell",
        trackingLabel: newsCollectionModel.trackingLabel,
        customDimensions: {
          36: title,
          46: title,
          66: "webview",
        },
        customMetrics: {},
        campaignUrl: null,
        trackingValue: null,
      };
      appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
    },
  },
});
