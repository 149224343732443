import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "divider"
}
const _hoisted_2 = {
  key: 1,
  class: "card-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardTextImageTagSkeleton = _resolveComponent("CardTextImageTagSkeleton")!
  const _component_Vignette = _resolveComponent("Vignette")!
  const _component_CardLarge = _resolveComponent("CardLarge")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_CardTextImageTagSkeleton, {
              key: 0,
              class: "card-padding"
            }))
          : (_ctx.hasData)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.liveItem?.subtitle)
                  ? (_openBlock(), _createBlock(_component_Vignette, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.liveItem?.subtitle), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_CardLarge, {
                  "image-url": _ctx.liveItem?.picture.defaultUrl,
                  imageAltText: _ctx.liveItem?.picture.altText,
                  id: _ctx.liveItem?.channelId,
                  headline: _ctx.liveItem?.headline,
                  text: "",
                  type: "Live",
                  programName: "",
                  durationFormatted: "",
                  onOpen: _ctx.playLiveArticle,
                  onPlay: _ctx.playLiveArticle,
                  onPause: _ctx.pausePlayer,
                  ariaLabel: `Direkt: ${_ctx.liveItem?.headline}`
                }, null, 8, ["image-url", "imageAltText", "id", "headline", "onOpen", "onPlay", "onPause", "ariaLabel"])
              ])), [
                [_directive_observe_visibility, {
        callback: (isVisible, entry) => _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
              ])
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}