import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c0b5986"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-padding",
  role: "dialog",
  "aria-modal": "true",
  "aria-labelledby": "promotionText"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "promotion-container" }
const _hoisted_4 = { class: "promotion-content" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = ["srcset"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "promotion-body" }
const _hoisted_9 = {
  class: "text-body",
  id: "promotionText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stil_text = _resolveComponent("stil-text")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || (_ctx.hasData && !_ctx.modalSpent))
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.hasData && !_ctx.modalSpent)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("picture", null, [
                      _createElementVNode("source", {
                        srcset: _ctx.promotions[_ctx.promotionIndex].imageUrl,
                        media: "(min-height: 650px)"
                      }, null, 8, _hoisted_6),
                      _createElementVNode("img", {
                        class: "promotion-image",
                        src: _ctx.promotions[_ctx.promotionIndex].imageUrl,
                        alt: ""
                      }, null, 8, _hoisted_7)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      (_ctx.promotions[_ctx.promotionIndex].title)
                        ? (_openBlock(), _createBlock(_component_stil_text, {
                            key: 0,
                            variant: "h3"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.promotions[_ctx.promotionIndex].title), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.promotions[_ctx.promotionIndex].text)
                        ? (_openBlock(), _createBlock(_component_stil_text, {
                            key: 1,
                            variant: "h3"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.promotions[_ctx.promotionIndex].text), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("button", {
                      class: "btn",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePromotionAction(_ctx.promotions[_ctx.promotionIndex])))
                    }, [
                      _createVNode(_component_stil_text, {
                        variant: "h4",
                        color: "quaternary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.promotions[_ctx.promotionIndex].positiveActionText), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("button", {
                      class: "close",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
                      type: "button"
                    }, [
                      _createVNode(_component_stil_text, { variant: "h4" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.promotions[_ctx.promotionIndex].negativeActionText), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ])
              ])
            ])), [
              [_directive_observe_visibility, {
        callback: (isVisible) => _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
            ])
          : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, {
      callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible),
      intersection: { rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN },
      once: true,
      throttle: 100,
    }]
      ])
    : _createCommentVNode("", true)
}