import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8559bdc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardImage = _resolveComponent("CardImage")!
  const _component_PlayPause = _resolveComponent("PlayPause")!
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_stil_grid = _resolveComponent("stil-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.fullWidth)
      ? (_openBlock(), _createBlock(_component_stil_grid, _mergeProps({ key: 0 }, _ctx.$attrs, {
          onClick: _ctx.onOpen,
          variant: "flex",
          flow: "column",
          alignItems: "stretch",
          gap: 1,
          class: "card-container"
        }), {
          default: _withCtx(() => [
            _createVNode(_component_CardImage, {
              isLive: _ctx.isLive,
              imageUrl: _ctx.imageUrl,
              imageAltText: _ctx.imageAltText
            }, null, 8, ["isLive", "imageUrl", "imageAltText"]),
            _createVNode(_component_stil_grid, {
              flow: "column",
              columns: "auto 1fr",
              alignItems: "center",
              gap: 1,
              style: {"position":"relative"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  id: `${_ctx.id}-summary`,
                  class: "screen-reader-only"
                }, _toDisplayString(_ctx.ariaLabel), 9, _hoisted_1),
                _createVNode(_component_PlayPause, {
                  isPlaying: _ctx.isPlaying,
                  onPlay: _ctx.onPlay,
                  onPause: _ctx.onPause,
                  size: 24,
                  inverted: _ctx.isDarkMode
                }, null, 8, ["isPlaying", "onPlay", "onPause", "inverted"]),
                _createVNode(_component_stil_grid, { flow: "row" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_stil_text, {
                      "aria-hidden": "true",
                      class: "metadata",
                      truncateLine: 1,
                      variant: "overline2",
                      transform: "uppercase"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.programName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_stil_text, {
                      "aria-hidden": "true",
                      class: "metadata",
                      variant: "overline2",
                      transform: "uppercase"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.durationFormatted), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_stil_text, {
              "aria-describedby": `${_ctx.id}-summary`,
              "aria-hidden": "true",
              class: "headline",
              variant: "h5"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.headline), 1)
              ]),
              _: 1
            }, 8, ["aria-describedby"])
          ]),
          _: 1
        }, 16, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.fullWidth)
      ? (_openBlock(), _createBlock(_component_stil_grid, _mergeProps({ key: 1 }, _ctx.$attrs, {
          onClick: _ctx.onOpen,
          flow: "column",
          alignItems: "center",
          gap: 2
        }), {
          default: _withCtx(() => [
            _createVNode(_component_CardImage, {
              imageUrl: _ctx.imageUrl,
              imageAltText: _ctx.imageAltText,
              isLive: _ctx.isLive
            }, null, 8, ["imageUrl", "imageAltText", "isLive"]),
            _createVNode(_component_stil_grid, {
              flow: "row",
              gap: 1
            }, {
              default: _withCtx(() => [
                _createVNode(_component_stil_grid, {
                  flow: "column",
                  columns: "auto 1fr",
                  alignItems: "center",
                  gap: 1
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PlayPause, {
                      isPlaying: _ctx.isPlaying,
                      onPlay: _ctx.onPlay,
                      onPause: _ctx.onPause,
                      size: 24,
                      inverted: _ctx.isDarkMode
                    }, null, 8, ["isPlaying", "onPlay", "onPause", "inverted"]),
                    _createVNode(_component_stil_grid, { flow: "row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_stil_text, {
                          "aria-hidden": "true",
                          class: "metadata",
                          truncateLine: 1,
                          variant: "overline2",
                          transform: "uppercase"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.programName), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_stil_text, {
                          "aria-hidden": "true",
                          class: "metadata",
                          variant: "overline2",
                          transform: "uppercase"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.durationFormatted), 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", {
                          id: `${_ctx.id}-summary`,
                          class: "screen-reader-only"
                        }, _toDisplayString(_ctx.ariaLabel), 9, _hoisted_2)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_stil_text, {
                  "aria-describedby": `${_ctx.id}-summary`,
                  "aria-hidden": "true",
                  class: "headline",
                  variant: "h5"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.headline), 1)
                  ]),
                  _: 1
                }, 8, ["aria-describedby"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 16, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}