
import { defineComponent, PropType } from "vue";
import "@sverigesradio/stil/components/stil-text.js";
import "@sverigesradio/stil/components/stil-button-rounded.js";
import AspectRatioImage from "../AspectRatioImage.vue";
import CardImage from "@/components/cards/CardImage.vue";
export default defineComponent({
  name: "InteractiveCard",
  components: {
    CardImage,
    AspectRatioImage,
  },
  props: {
    imageUrl: {
      type: String as PropType<string>,
      required: true,
    },
    imageAltText: {
      type: String as PropType<string>,
      required: true,
    },
    headline: {
      type: String as PropType<string>,
      required: true,
    },
    ariaLabel: {
      type: String as PropType<string>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    ctaText: {
      type: String as PropType<string>,
      required: true,
    },
    isLarge: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isSummerPuff: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    isProgramPuff: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    id: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  computed: {
    headlineVariant(): string {
      return this.isLarge ? "h1" : "h3";
    },
  },
});
