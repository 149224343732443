
import { defineComponent } from 'vue'
import { FeedbackRepository } from "@/repositories/feedback-repository"
import TemporarySkeletonItem from "@/components/TemporarySkeletonItem.vue"
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants"
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event"
import * as appService from "../../public/appService"
import { FeedbackModel } from "../../entities/feedback-model"
import "@sverigesradio/stil/components/stil-text.js"
import FeedbackResponse from "@/components/FeedbackResponse.vue"
import FeedbackThumbButtons from "@/components/FeedbackThumbButtons.vue"
import FeedbackClickWebLink from "@/components/FeedbackClickWebLink.vue"
import { storageUntilExpiry } from "@/util/storage-utils"

export default defineComponent({
    name: "Feedback",
    components: { TemporarySkeletonItem, FeedbackResponse, FeedbackThumbButtons, FeedbackClickWebLink },
    props: {
        variant: String
    },
    data() {
        return {
            activeComponent: 'FeedbackClickWebLink',
            isLoading: true,
            feedbackData: null as FeedbackModel | null,
            ON_ALMOST_VISIBLE_ROOT_MARGIN,
            storeKey: null as string | null,
            givenFeedback: false as Boolean,
            showModule: true
        }
    },
    watch: {
        givenFeedback(givenFeedbackValue) {
            if (givenFeedbackValue) {
                const expireDate = new Date()
                // Expiry: 90 days
                expireDate.setHours(2160, 0, 0, 0)
                storageUntilExpiry.setStoredValue(this.storeKey, '1', expireDate.toUTCString())
            }
        }
    },
    computed: {
        hasData(): boolean {
            return this.feedbackData !== null && this.feedbackData.enabled
        },
        showIntroduction(): boolean {
            if (!this.feedbackData?.introduction) {
                return false
            }
            return this.feedbackData?.introduction.length > 0
        }
    },
    methods: {
        async fetchData() {
            this.feedbackData = await FeedbackRepository.get()
            if (this.feedbackData !== null) {
                this.storeKey = 'feedback-' + this.variant
                this.givenFeedback = this.consumedOrExpired(this.storeKey)
                if (this.givenFeedback) {
                    this.feedbackData = null
                }
            }
            this.isLoading = false
        },
        consumedOrExpired(name): Boolean {
            return storageUntilExpiry.consumedOrExpired(name)
        },
        onAlmostVisible(isAlmostVisible) {
            if (isAlmostVisible) {
                this.fetchData()
            }
        },
        onBecomeVisible(isVisible) {
            if (isVisible) {
                this.sendDisplayedEvent()
            }
        },
        async submit(version) {
            if (this.givenFeedback) {
                return
            }
            this.sendClickedEvent(version)
            if (version === "positiv") {
                setTimeout(() => {
                    this.activeComponent = 'FeedbackResponse'
                }, 1600)
                setTimeout(() => {
                    this.showModule = false
                }, 4000)
            } else if (version === "negativ") {
                setTimeout(() => {
                    this.activeComponent = 'FeedbackResponse'
                }, 800)
                setTimeout(() => {
                    this.showModule = false
                }, 4000)
            } else if (version === "clickedLink") {
                appService.openBrowser("https://sverigesradio.se/artikel/testpanel-sveriges-radio-play-app-webbplats")
                setTimeout(() => {
                    this.activeComponent = 'FeedbackResponse'
                }, 800)
            }
            this.setGivenFeedback()
        },
        closeFeedback() {
            this.showModule = false
            this.setGivenFeedback()
        },
        setGivenFeedback() {
            this.givenFeedback = true
        },
        sendClickedEvent(version) {
            let firebaseTrackingEvent: FirebaseTrackingEvent = {
                eventName: "feedbackmodul_klick",
                parametersMap: {
                    skarmnamn: "hem",
                    modulnamn: 'testpanel',
                    eventkalla: "webview",
                    pufftext: this.feedbackData?.question,
                    value: version
                }
            }
            appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
        },
        sendDisplayedEvent() {
            let firebaseTrackingEvent: FirebaseTrackingEvent = {
                eventName: "feedbackmodul_visas",
                parametersMap: {
                    skarmnamn: "hem",
                    modulnamn: 'testpanel',
                    eventkalla: "webview",
                    pufftext: this.feedbackData?.question
                }
            }
            appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
        }
    }
}
)
