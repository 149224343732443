import { PremiumItemModel } from "../../entities/premium-item-entity";
import { getBaseUrl } from "@/util/address-utils";
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const GetPremiumTertiaryItemUseCase = {
    async get(): Promise<PremiumItemModel | null> {
        const url = getBaseUrl() + 'bff/premium/tertiary'
        return httpGetTypeOrNull<PremiumItemModel>(url)
    }
}

