import { createRouter, createWebHistory } from "vue-router";
import Premium from "@/views/Premium.vue";
import Startpage from "@/views/Startpage.vue";
import DeveloperHelpPage from "@/views/DeveloperHelpPage.vue";
import PuffPackagePreview from "@/views/PuffPackagePreview.vue";

const routes = [
  {
    path: "/",
    name: "DeveloperHelpPage",
    component: DeveloperHelpPage,
  },
  {
    path: "/web/one-module",
    name: "OneModule",
    component: Premium,
  },
  {
    path: "/v1/web/start",
    name: "Startpage",
    component: Startpage,
  },
  {
    path: "/preview/puffpackage",
    name: "PuffPackagePreview",
    component: PuffPackagePreview,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
