import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loaded && _ctx.experimentStore.remoteConfigLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view, {
          class: _normalizeClass(_ctx.theme===_ctx.THEME_DARK ? 'dark' : 'light'),
          os: _ctx.deviceOs
        }, null, 8, ["class", "os"])
      ]))
    : _createCommentVNode("", true)
}