import { defineStore } from "pinia";

export const useFeatureFlagsStore = defineStore("featureFlags", {
  state: () => {
    return {
      _newFixedNewsDesign: false,
      _localChannelId: 0,
      _useChannelBackground: false,
    };
  },
  actions: {
    async getAndSetFeatureFlags() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      const newFixedNewsDesign = params["newfixednewsdesign"]
      const localChannelId = params["localchannelid"]
      const useChannelBackgrounds = params["usechannelbackground"]

      if (newFixedNewsDesign === "true") {
        this._newFixedNewsDesign = true;
      }
      if (Number(localChannelId)) {
        this._localChannelId = Number(localChannelId)
      }
      if (useChannelBackgrounds === "true") {
        this._useChannelBackground = true;
      }
    },
  },
  getters: {
    newFixedNewsDesignEnabled: (state) => {
      return state._newFixedNewsDesign;
    },
    localChannelId: (state) => {
      return state._localChannelId;
    },
    useChannelBackground: (state) => {
      return state._useChannelBackground;
    }
  },
});
