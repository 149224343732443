import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_PauseIcon = _resolveComponent("PauseIcon")!

  return (!_ctx.isPlaying)
    ? (_openBlock(), _createBlock(_component_PlayIcon, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onPlay()), ["stop"])),
        size: _ctx.size,
        inverted: _ctx.inverted,
        role: "button",
        "aria-label": "spela"
      }, null, 8, ["size", "inverted"]))
    : (_openBlock(), _createBlock(_component_PauseIcon, {
        key: 1,
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onPause()), ["stop"])),
        size: _ctx.size,
        inverted: _ctx.inverted,
        role: "button",
        "aria-label": "pausa"
      }, null, 8, ["size", "inverted"]))
}