
import {defineComponent} from 'vue';
import * as appService from '../../public/appService.js';
import {NewsCollectionModel} from '../../entities/news-collection-model';
import {NewsCollectionRepository} from '@/usecases/news-collection-repository';
import {CardImageCollection, CardImageCollectionSkeleton} from '@sverigesradio/dpp-vue-3-components';
import {FirebaseTrackingEvent} from "../../entities/firebase-tracking-event";
import {GoogleAnalyticsTrackingEvent} from "../../entities/google-analytics-tracking-event";
import TemporarySkeletonItem from "@/components/TemporarySkeletonItem.vue";
import {ON_ALMOST_VISIBLE_ROOT_MARGIN} from "@/constants";
import NewsCarousel from "@/components/carousels/NewsCarousel.vue";
import "@sverigesradio/stil/components/CardImageCollection.lit.js";
import {useExperimentStore} from "@/stores/experiments";
import {useVersionStore} from "@/stores/version";
import Carousel from '@/components/carousels/Carousel.vue';
import OldTopNewsChooseP4Item from '@/components/OldTopNewsChooseP4Item.vue';
import NewsCard from '@/components/cards/NewsCard.vue';
import {NativeRepository} from "@/repositories/native-repository";

export default defineComponent({
        name: "OldTopNews",
        components: {
            OldTopNewsChooseP4Item,
            Carousel, TemporarySkeletonItem, NewsCarousel, CardImageCollection,
            CardImageCollectionSkeleton,NewsCard
        },
        data() {
            return {
                localChannelId: null as number | null | undefined,
                isLoading: true,
                newsCollection: null as NewsCollectionModel[] | null,
                ON_ALMOST_VISIBLE_ROOT_MARGIN,
                hideLocalNewsPlaylist: false,
            };
        },
        beforeMount() {
            window['LocalChannelSelectedTopNewsHook'] = {
                components: this,
                localChannelSelected: () => this.fetchData(),
            };
        },
        computed: {
            hasData(): boolean {
                return this.newsCollection !== null && this.newsCollection?.length > 0;
            },
            showChooseLocalChannelItem(): boolean {
                return !this.hideLocalNewsPlaylist && !this.localChannelId
            }
        },
        setup() {
            return {
                experimentStore: useExperimentStore(),
                versionStore: useVersionStore(),
            };
        },
        methods: {
            playNewsCollection(newsCollectionModel) {
                newsCollectionModel.type = "playArticleCollection";
                newsCollectionModel.startIndex = 0
                appService.playNewsCollection(JSON.stringify(newsCollectionModel));
                this.sendClickedTrackingEvents(newsCollectionModel);
            },
            pausePlayer() {
                NativeRepository.pausePlayer()
            },
            chooseP4() {
                appService.showLocalChannelSelection()
            },
            async fetchData() {
                this.localChannelId = await appService.getLocalChannelId();
                var webViewVersion = this.versionStore.webViewVersion
                if (webViewVersion !== null && webViewVersion >= 1.1) {
                    this.hideLocalNewsPlaylist = this.experimentStore.start_show_local_news_single;
                }
                this.newsCollection = await NewsCollectionRepository.getOldTopNews(this.localChannelId, this.hideLocalNewsPlaylist);
                this.isLoading = false;
            },
            onAlmostVisible(isAlmostVisible) {
                if (isAlmostVisible) {
                    this.fetchData();

                    window['WebViewVisibleIntervalEventOldTopNewsHook'] = {
                        components: this,
                        update: () => {
                            this.fetchData();
                        },
                    };
                }
            },
            onBecomeVisible(isVisible) {
                if (isVisible) {
                    this.sendDisplayedTrackingEvents();
                }
            },
            sendClickedTrackingEvents(newsCollection: NewsCollectionModel) {
                let title = "";
                if (newsCollection.genre == "Local") {
                    title = "lokala nyheter";
                } else {
                    title = newsCollection.listHeadline.toLowerCase();
                }
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modulstart",
                    parametersMap: {
                        spellista: newsCollection.listHeadline,
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        ursprung: "tbd",
                        innehallstyp: "tbd",
                        modulnamn: newsCollection.trackingLabel,
                        eventkalla: "webview",
                    },
                };

                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "start",
                    category: "Karusell",
                    trackingLabel: newsCollection.trackingLabel,
                    customDimensions: {
                        36: title,
                        46: title,
                        66: "webview",
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null,
                };
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);
            },
            sendDisplayedTrackingEvents() {
                if (this.newsCollection) {
                    if (this.newsCollection?.[0]) {
                        let firebaseTrackingEvent: FirebaseTrackingEvent = {
                            eventName: "modul_visas",
                            parametersMap: {
                                modultyp: "tbd",
                                skarmnamn: "hem",
                                innehallstyp: "tbd",
                                modulnamn: this.newsCollection[0].trackingLabel,
                                eventkalla: "webview",
                            },
                        };
                        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                            event: "visas",
                            category: "Karusell",
                            trackingLabel: this.newsCollection[0].trackingLabel,
                            customDimensions: {
                                66: "webview",
                            },
                            customMetrics: {},
                            campaignUrl: null,
                            trackingValue: null,
                        };
                        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
                        appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent);
                    }
                }
            },
        },
    },
);
