
import {defineComponent} from 'vue';
import {FirebaseTrackingEvent} from '../../../entities/firebase-tracking-event'
import {GoogleAnalyticsTrackingEvent} from '../../../entities/google-analytics-tracking-event'
import * as appService from '../../../public/appService'

export default defineComponent({
    name: "Carousel",
    props: {
        trackingLabel: String,
        type: String,
        alignItems: {
            type: String,
            default: "start"
        },
        withOutGap: {
            type: Boolean,
            defaultValue: false,
        },
    },
    data() {
        return {
            hasSentScrollEvent: false,
            hasSentScrollToEndEvent: false,
            scrollToEndObserver: null as IntersectionObserver | null
        }
    },
    mounted() {
        const items = (this.$refs as any).carouselItems.children
        if (items.length > 0) {
            const options = {
                root: this.$refs.scrollArea as Element,
                rootMargin: '0px',
                threshold: 0.5
            }
            const target = items[items.length - 1];

            this.scrollToEndObserver = new IntersectionObserver(this.onEndElementIntersectionChange, options);
            this.scrollToEndObserver.observe(target)
        }
    },
    methods: {
        // We use two different methods to observe scroll and scroll to end
        // For scroll, we simply use the onScroll event on the carousel.
        // For scroll to end, we check the intersection with the last element and the parent.
        // But for that to work without sending erronous scroll to end events, we must make sure the user has scrolled at all.
        onScroll() {
            if (!this.hasSentScrollEvent) {
                this.sendScrollEvent()
                this.hasSentScrollEvent = true;
            }
        },
        onEndElementIntersectionChange(event) {
            if (event[0].isIntersecting
                && !this.hasSentScrollToEndEvent
                && this.hasSentScrollEvent) { // Because user needs to have scroll before being able to have scrolled to the end.
                this.sendScrollToEndEvent()
                this.hasSentScrollToEndEvent = true
            }
        },
        sendScrollEvent() {
            if (this.trackingLabel) {
                // Firebase event
                const firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modul_swipe",
                    parametersMap: {
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        innehallstyp: "tbd",
                        modulnamn: this.trackingLabel,
                        eventkalla: "webview"
                    }
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)

                // Google Analytics event
                const googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "swipe",
                    category: "Karusell",
                    trackingLabel: this.trackingLabel,
                    customDimensions: {
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            }
        },
        sendScrollToEndEvent() {
            if (this.trackingLabel) {
                // Firebase event
                const firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modul_swipe_till_slut",
                    parametersMap: {
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        innehallstyp: "tbd",
                        modulnamn: this.trackingLabel,
                        eventkalla: "webview"
                    }
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)

                // Google Analytics event
                const googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "swipe_till_slut",
                    category: "Karusell",
                    trackingLabel: this.trackingLabel,
                    customDimensions: {
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            }
        }
    }
})
