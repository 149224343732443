
import {defineComponent} from 'vue'

import { IS_LOCAL }  from "../util/dev-utils";

export default defineComponent({
  name: 'DeveloperHelpPage',
    created() {
      if(this.isDevelopment) {
          this.$router.push("/v1/web/start")
      }
    },
    data() {
    return {
      isDevelopment: IS_LOCAL
    }
  }
})
