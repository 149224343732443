
import { defineComponent } from "vue";
export default defineComponent({
  name: "PlayIcon",
  props: {
    inverted: Boolean,
    size: {
      type: Number,
      default: 40,
    },
  },
});
