
import { defineComponent } from "vue";
import * as appService from "../../public/appService.js";
import { NewsCollectionModel } from "../../entities/news-collection-model";
import { NewsCollectionRepository } from "@/usecases/news-collection-repository";
import { CardImageCollectionSkeleton } from "@sverigesradio/dpp-vue-3-components";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { GoogleAnalyticsTrackingEvent } from "../../entities/google-analytics-tracking-event";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import "@sverigesradio/stil/components/CardImageCollection.lit.js";
import NewsCard from "@/components/cards/NewsCard.vue";
import "@sverigesradio/stil/components/stil-card.js";
import "@sverigesradio/stil/components/stil-grid.js";
import "@sverigesradio/stil/components/stil-image.js";
import "@sverigesradio/stil/components/stil-text.js";
import StandaloneTopNewsCard from "@/components/cards/StandaloneTopNewsCard.vue";

export default defineComponent({
  name: "StandaloneTopNewsContainer",
  components: {
    StandaloneTopNewsCard,
    CardImageCollectionSkeleton,
    NewsCard,
  },
  props: {
    moduleName: String,
  },
  data() {
    return {
      isLoading: true,
      newsCollection: null as NewsCollectionModel | null,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  computed: {
    hasData(): boolean {
      return this.newsCollection !== null;
    },
  },
  methods: {
    async fetchData() {
      this.newsCollection =
        await NewsCollectionRepository.getStandaloneTopNews();
      this.isLoading = false;
    },
    onAlmostVisible(isAlmostVisible) {
      if (isAlmostVisible) {
        this.fetchData();

        window["WebViewVisibleIntervalEventStandaloneTopNewsHook"] = {
          components: this,
          update: () => {
            this.fetchData();
          },
        };
      }
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvents();
      }
    },
    sendDisplayedTrackingEvents() {
      if (this.newsCollection) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "tbd",
            skarmnamn: "hem",
            innehallstyp: "tbd",
            spellista: this.newsCollection.trackingLabel,
            modulnamn: this.newsCollection.trackingLabel,
            eventkalla: "webview",
          },
        };
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "visas",
          category: "Karusell",
          trackingLabel: this.newsCollection.trackingLabel,
          customDimensions: {
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
  },
});
