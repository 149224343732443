import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09aa21df"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "divider"
}
const _hoisted_2 = {
  key: 0,
  class: "card-padding"
}
const _hoisted_3 = {
  key: 1,
  class: "card-padding"
}
const _hoisted_4 = { class: "channels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SquareIconSkeleton = _resolveComponent("SquareIconSkeleton")!
  const _component_SquareIcon = _resolveComponent("SquareIcon")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4], (item) => {
                return _createVNode(_component_SquareIconSkeleton, { key: item })
              }), 64))
            ]))
          : (_ctx.hasData)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.module.national, (item, index) => {
                    return (_openBlock(), _createBlock(_component_SquareIcon, {
                      key: index,
                      name: item.iconName,
                      "img-url": item.useBackground ? item.iconImageUrl : null,
                      onClick: ($event: any) => (_ctx.nationalChannelClick(item)),
                      ariaLabel: item.ariaLabel
                    }, null, 8, ["name", "img-url", "onClick", "ariaLabel"]))
                  }), 128)),
                  _createVNode(_component_SquareIcon, {
                    name: _ctx.module.local.iconName,
                    selected: _ctx.module.local.selected,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.localChannelClick(_ctx.module.local))),
                    ariaLabel: _ctx.module.local.ariaLabel
                  }, null, 8, ["name", "selected", "ariaLabel"]),
                  _createVNode(_component_SquareIcon, {
                    name: _ctx.module.extra.iconName,
                    selected: _ctx.module.extra.selected,
                    "img-url": 
            _ctx.module.extra.useBackground ? _ctx.module.extra.iconImageUrl : null
          ,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.extraChannelClick(_ctx.module.extra))),
                    ariaLabel: _ctx.module.extra.ariaLabel
                  }, null, 8, ["name", "selected", "img-url", "ariaLabel"])
                ])
              ])), [
                [_directive_observe_visibility, {
        callback: (isVisible, entry) => _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
              ])
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}