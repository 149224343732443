
import { PropType, defineComponent } from "vue";
import AspectRatioImage from "../AspectRatioImage.vue";
import LiveBadge from "../LiveBadge.vue";
export default defineComponent({
  name: "CardImage",
  components: {
    AspectRatioImage,
    LiveBadge,
  },
  props: {
    isLive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    imageUrl: {
      type: String as PropType<string>,
      required: true,
    },
    imageAltText: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
