
import "@sverigesradio/stil/components/stil-text.js";
import {Article} from "../../../entities/news-collection-model";
import {ref, computed} from 'vue';
import PauseIcon from "@/components/icons/PauseIcon.vue";
import {usePlayerStateStore} from "@/stores/playerState";
import {THEME_DARK} from '@/constants'
import {useNativeStore} from '@/stores/nativeStore'
import AspectRatioImage from '../AspectRatioImage.vue';
import PlayIcon from '../icons/PlayIcon.vue';

export default {
  name: 'NewsArticleHeroCard',
  components: { PauseIcon, AspectRatioImage, PlayIcon },
  props: {
    article: {
      type: Object as () => Article | null,
      default: null,
    },
    playButtonTheme: "",
  },
  setup(props) {
    const fontSize = ref('');

    const nativeStore = useNativeStore()

    const playerStateStore = usePlayerStateStore();

    const showPauseButton = computed(() => {
      return (isPlayerPlaying.value && isCurrentArticlePlaying.value);
    });
    const isPlayerPlaying = computed(() => {
      return !!playerStateStore?.isPlaying
    });
    const isCurrentArticlePlaying = computed(() => {
      return (props.article.id.toString() === playerStateStore.currentItemId)
    });

    const isDarkMode = computed(() => {
      return (nativeStore.currentTheme === THEME_DARK)
    });

    return {
      fontSize,
      showPauseButton,
      isDarkMode
    };
  },
};
