import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e482499"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article-card" }
const _hoisted_2 = { class: "title-and-play" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AspectRatioImage = _resolveComponent("AspectRatioImage")!
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_PauseIcon = _resolveComponent("PauseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AspectRatioImage, {
      src: $props.article.imageUrl,
      alt: $props.article.imageAltText,
      onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open'))),
      horizontalRatio: 16,
      verticalRatio: 9,
      rounded: ""
    }, null, 8, ["src", "alt"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_stil_text, {
        variant: "h1",
        role: "button",
        "aria-label": $props.article.title,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.article.title), 1)
        ]),
        _: 1
      }, 8, ["aria-label"]),
      _createElementVNode("div", null, [
        ($setup.showPauseButton)
          ? (_openBlock(), _createBlock(_component_PauseIcon, {
              key: 0,
              size: 24,
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('pause')), ["stop"])),
              inverted: $setup.isDarkMode,
              role: "button",
              "aria-labek": "pausa",
              title: "pausa"
            }, null, 8, ["inverted"]))
          : (_openBlock(), _createBlock(_component_PlayIcon, {
              key: 1,
              size: 24,
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('play')), ["stop"])),
              inverted: $setup.isDarkMode,
              role: "button",
              "aria-label": "spela nyhet",
              title: "spela nyhet"
            }, null, 8, ["inverted"]))
      ])
    ])
  ]))
}