import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fbf16eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "play-pause" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_PauseIcon = _resolveComponent("PauseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['article-card', $props.isDarkMode ? 'divider-dark-mode' : 'divider-light-mode'])
  }, [
    _createElementVNode("div", {
      role: "button",
      "aria-label": $props.article.title,
      class: "title",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open')))
    }, [
      _createVNode(_component_stil_text, { variant: "h3" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.article.title), 1)
        ]),
        _: 1
      })
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      ($setup.showPauseButton)
        ? (_openBlock(), _createBlock(_component_PauseIcon, {
            key: 0,
            size: 24,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('pause'))),
            inverted: $props.isDarkMode,
            role: "button",
            "aria-label": "pausa",
            title: "pausa"
          }, null, 8, ["inverted"]))
        : (_openBlock(), _createBlock(_component_PlayIcon, {
            key: 1,
            size: 24,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('play'))),
            inverted: $props.isDarkMode,
            role: "button",
            "aria-label": "spela nyhet",
            title: "spela nyhet"
          }, null, 8, ["inverted"]))
    ])
  ], 2))
}