
import {defineComponent} from 'vue';
import Carousel from "@/components/carousels/Carousel.vue";

export default defineComponent({
  name: "EpisodeCarousel",
  components: { Carousel },
  props: {
    trackingLabel: String,
  }
})
