import { Article } from "./news-collection-model";

export type PuffPackage = {
  skyltaId: number;
  subject: string;
  items: PuffItem[];
};

export type PuffItem = {
  skyltaId: number;
  type: PuffItemType;
  referenceId: number;
  image: string;
  headline: string;
  text: string;
  altText: string;
  programName: string;
  durationFormatted: string;
  publishTimeFormatted: string;
  channelName: string;
  soundName: string;
  articleModel: Article;
  ctaText: string;
  interactiveViewHeader: string;
  trackingLabel: string;
};

export type PuffCardItem = {
  imageUrl: string;
  imageAltText: string;
  isLive: boolean;
  type: PuffItemType;
  publishTimeFormatted: string;
  headline: string;
  text: string;
  ariaLabel: string;
  programName: string;
  durationFormatted: string;
  id: number;
  index: number;
  ctaText?: string;
  interactiveViewHeader?: string;
};

export type RightNowResponse = {
  items: RightNowItem[]
}

export type RightNowItem = {
  prefix: string;
  headline: string;
  type: PuffItemType;
  referenceId: number;
  programName: string;
  soundName: string;
}

export type PuffItemType = "Episode" | "Article" | "Live" | "Interactive";

export const mapPuffItemToPuffCardItem = (item: PuffItem & { index: number }): PuffCardItem => ({
  imageUrl: item.image,
  imageAltText: item.altText,
  isLive: item.type === "Live",
  type: item.type,
  publishTimeFormatted: item.publishTimeFormatted,
  headline: item.headline,
  text: item.text,
  ariaLabel: `${item.headline}. ${item.text}`,
  programName: item.programName,
  durationFormatted: item.durationFormatted,
  id: item.referenceId,
  index: item.index,
  ctaText: item.ctaText,
  interactiveViewHeader: item.interactiveViewHeader,
})

export const mapArticleToPuffCardItem = (article: Article, index: number): PuffCardItem => ({
  imageUrl: article.imageUrl,
  imageAltText: article.imageAltText,
  isLive: false,
  id: article.id,
  headline: article.title,
  text: "",
  ariaLabel: article.title,
  programName: article.programName,
  durationFormatted: article.durationFormatted,
  type: "Article",
  publishTimeFormatted: "",
  index,
  ctaText: "",
  interactiveViewHeader: ""
})