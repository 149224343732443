
import {
  ChannelModel,
  ChannelModuleModel,
  ChannelType,
  PlayChannelModel,
  SelectableChannelModel,
} from "../../entities/channel-entity";
import { defineComponent } from "vue";
import {
  SquareIcon,
  SquareIconSkeleton,
} from "@sverigesradio/dpp-vue-3-components";
import { ChannelRepository } from "@/usecases/channel-repository";
import * as appService from "../../public/appService.js";
import { GoogleAnalyticsTrackingEvent } from "../../entities/google-analytics-tracking-event";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { useFeatureFlagsStore } from "@/stores/featureFlagsStore";

export default defineComponent({
  name: "Channels",
  components: { SquareIcon, SquareIconSkeleton },
  setup() {
    return {
      featureFlagsStore: useFeatureFlagsStore(),
    };
  },
  data() {
    let localChannelId: number | null = null;
    let savedExtraChannel: number | null = null;
    return {
      isLoading: true,
      localChannelId: localChannelId,
      savedExtraChannel: savedExtraChannel,
      module: null as ChannelModuleModel | null,
      mouseOverStarted: 0,
      p3WithIcon: null as ChannelModel | null,
    };
  },
  computed: {
    hasData(): boolean {
      return this.module !== null;
    },
  },
  beforeMount() {
    window["LocalChannelSelectedHook"] = {
      components: this,
      localChannelSelected: () => {
        this.fetchData(() => {
          if (this.module?.local) {
            this.sendChooseChannelTrackingEvent(
              this.module.local.playChannelModel
            );
          }
        });
      },
    };
    window["ExtraChannelSelectedHook"] = {
      components: this,
      extraChannelSelected: () => {
        this.fetchData(() => {
          if (this.module?.extra) {
            this.sendChooseChannelTrackingEvent(
              this.module.extra.playChannelModel
            );
          }
        });
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedEvent();
      }
    },
    nationalChannelClick(channelModel: ChannelModel) {
      channelModel.playChannelModel.channelType = ChannelType.National;
      this.playChannel(channelModel.playChannelModel);
      if (this.module) {
        this.sendPlayChannelTrackingEvents(channelModel.playChannelModel);
      }
    },
    localChannelClick(selectableChannelModel: SelectableChannelModel) {
      if (selectableChannelModel.selected) {
        selectableChannelModel.playChannelModel.channelType = ChannelType.Local;
        this.playChannel(selectableChannelModel.playChannelModel);
        if (this.module) {
          this.sendPlayChannelTrackingEvents(
            selectableChannelModel.playChannelModel
          );
        }
      } else {
        appService.showLocalChannelSelection();
      }
    },
    extraChannelClick(selectableChannelModel: SelectableChannelModel) {
      if (selectableChannelModel.selected) {
        selectableChannelModel.playChannelModel.channelType = ChannelType.Extra;
        this.playChannel(selectableChannelModel.playChannelModel);
        if (this.module) {
          this.sendPlayChannelTrackingEvents(
            selectableChannelModel.playChannelModel
          );
        }
      } else {
        appService.showExtraChannelSelection();
      }
    },
    playChannel(playChannelModel: PlayChannelModel) {
      appService.playChannel(playChannelModel.id);
    },
    sendChooseChannelTrackingEvent(playChannelModel: PlayChannelModel) {
      if (this.module) {
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "välj kanal",
          category: "Karusell",
          trackingLabel: this.module.trackingLabel,
          customDimensions: {
            1: playChannelModel.name,
            2: playChannelModel.id.toString(),
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_valj_kanal",
          parametersMap: {
            modultyp: "tbd",
            skarmnamn: "hem",
            innehallstyp: "tbd",
            modulnamn: this.module?.trackingLabel,
            direktsockerbit: playChannelModel.name,
            eventkalla: "webview",
          },
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
    sendPlayChannelTrackingEvents(playChannelModel: PlayChannelModel) {
      if (this.module) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modulstart",
          parametersMap: {
            kanalnamn: playChannelModel.name,
            kanal_id: playChannelModel.id.toFixed(0),
            modultyp: "tbd",
            skarmnamn: "hem",
            ursprung: "tbd",
            innehallstyp: "tbd",
            modulnamn: this.module?.trackingLabel,
            eventkalla: "webview",
          },
        };
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "start",
          category: "Karusell",
          trackingLabel: this.module.trackingLabel,
          customDimensions: {
            1: playChannelModel.name,
            2: playChannelModel.id.toString(),
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
    async fetchData(callback?: () => void) {
      this.localChannelId = await appService.getLocalChannelId();
      this.savedExtraChannel = await appService.getSavedExtraChannel();
      ChannelRepository.get(this.localChannelId, this.savedExtraChannel).then(
        (module) => {
          this.module = module;
          this.isLoading = false;
          callback && callback();
        }
      );
    },
    sendDisplayedEvent() {
      if (this.module != null) {
        let gaDisplayedEvent: GoogleAnalyticsTrackingEvent = {
          event: "visas",
          category: "Karusell",
          trackingLabel: this.module.trackingLabel,
          customDimensions: {
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendGoogleAnalyticsTrackingEvent(gaDisplayedEvent);
        let firebaseDisplayedEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "tbd",
            skarmnamn: "hem",
            innehallstyp: "tbd",
            modulnamn: this.module.trackingLabel,
            eventkalla: "webview",
          },
        };
        appService.sendFirebaseTrackingEvent(firebaseDisplayedEvent);
      }
    },
  },
});
