
  import {defineComponent} from 'vue';
  import Carousel from "@/components/carousels/Carousel.vue";
  
  export default defineComponent({
    name: "FixedNewsCarousel",
    components: { Carousel },
    props: {
      trackingLabel: String,
      smallerWidth: Boolean  
    }
  })
  