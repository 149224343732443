
import "@sverigesradio/stil/components/stil-card.js";
import "@sverigesradio/stil/components/stil-grid.js";
import "@sverigesradio/stil/components/stil-image.js";
import "@sverigesradio/stil/components/stil-text.js";
import * as appService from "../../public/appService.js";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { defineComponent } from "vue";
import { StandalonePodcastModel } from "../../entities/StandalonePodcastModel";
import { StandalonePodcastRepository } from "@/repositories/StandalonePodcastRepository";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import { CardTextImageTagSkeleton } from "@sverigesradio/dpp-vue-3-components";
import { KilkayaClickEvent, kilkayaClick } from "@/util/tracking-utils";

export default defineComponent({
  name: "StandalonePodcast",
  components: {
    CardTextImageTagSkeleton,
  },
  props: {
    variant: String,
    moduleName: String,
  },
  data() {
    return {
      isLoading: true,
      standalonePodcast: null as StandalonePodcastModel | null,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  computed: {
    hasData(): boolean {
      return (
        this.standalonePodcast !== null && this.standalonePodcast !== undefined
      );
    },
  },
  methods: {
    async fetchData() {
      this.standalonePodcast = await StandalonePodcastRepository.get(
        this.variant
      );
      this.isLoading = false;
    },
    onClick() {
      this.sendProgramClickedTrackingEvent();
      appService.openProgram(this.standalonePodcast?.podcastId);
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvent();
      }
    },
    onAlmostVisible(isAlmostVisible) {
      if (isAlmostVisible) {
        this.fetchData();
      }
    },
    sendProgramClickedTrackingEvent() {

      if (!this.standalonePodcast) return
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modulklick",
        parametersMap: {
          programnamn: this.standalonePodcast?.podcastTitle,
          program_id: this.standalonePodcast?.podcastId,
          programkategori: this.standalonePodcast?.podcastCategoryName,
          pufftext: this.standalonePodcast?.headline,
          skarmnamn: "hem",
          modulnamn: this.standalonePodcast?.trackingLabel,
          eventkalla: "webview",
        },
      };

      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);

      const kilkayaEvent: KilkayaClickEvent = {
          audioType: "Episode",
          channelName: "",
          soundName: "",
          moduleTitle: this.standalonePodcast.headline || "",
          moduleName: this.moduleName || "",
          id: this.standalonePodcast?.podcastId,
          itemHeadline: "",
          interactionType: "klick",
          programName: this.standalonePodcast.podcastTitle,
        };
        kilkayaClick(kilkayaEvent);
    },
    sendDisplayedTrackingEvent() {
      let firebaseTrackingEvent: FirebaseTrackingEvent = {
        eventName: "modul_visas",
        parametersMap: {
          skarmnamn: "hem",
          modulnamn: this.standalonePodcast?.trackingLabel,
          eventkalla: "webview",
          pufftext: this.standalonePodcast?.headline ?? "",
        },
      };
      appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
      
    },
  },
});
