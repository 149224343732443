<template>
    <div class="skeleton" :class="{ 'small': size === 'sm'}" />

</template>

<script>
export default {
    name: "TemporarySkeletonItem",
    props: {
        size: String
    }
}
</script>

<style scoped>
.skeleton {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 4px;
    background-image:
        linear-gradient(lightgrey 100%, transparent 0);
}

.small {
    height: 30px !important;
}
</style>