import { getBaseUrl } from "@/util/address-utils";
import { EpisodeModel } from '../../entities/episode-model'
import {CarouselModel} from "../../entities/carousel-model";
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const RecommendedEpisodesRepository = {
    async get(userId: string): Promise<CarouselModel<EpisodeModel> | null> {
        const url = getBaseUrl() + `bff/episodes/recommended/${userId}`
        return httpGetTypeOrNull<CarouselModel<EpisodeModel>>(url)
    }
}