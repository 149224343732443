import {getBaseUrl} from "@/util/address-utils";
import {EditorialCollectionModel} from "../../entities/editorial-collection-entity";
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const EditorialCollectionsRepository = {

    async get(variant : String): Promise<EditorialCollectionModel[] | null> {
        const url = `${getBaseUrl()}bff/editorialCollections/${variant}`
        return httpGetTypeOrNull<EditorialCollectionModel[]>(url)
    }

}