
import { defineComponent } from "vue";
import * as appService from "../../public/appService.js";
import { PremiumItemModel } from "../../entities/premium-item-entity";
import { PremiumTrackingData } from "../../entities/premium-tracking-data";
import { PremiumRepository } from "@/repositories/premium-repository";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { GoogleAnalyticsTrackingEvent } from "../../entities/google-analytics-tracking-event";
import {
  CardTextImageTag,
  CardTextImageTagSkeleton,
} from "@sverigesradio/dpp-vue-3-components";
import TemporarySkeletonItem from "@/components/TemporarySkeletonItem.vue";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import CardLarge from "./cards/CardLarge.vue";
import { PuffCardItem } from "entities/puff-package-model";
import { KilkayaClickEvent, kilkayaClick } from "@/util/tracking-utils";


export default defineComponent({
  name: "PremiumItem",
  components: {
    TemporarySkeletonItem,
    CardTextImageTag,
    CardTextImageTagSkeleton,
    CardLarge,
  },
  props: {
    variant: String,
    moduleName: String,
  },
  data() {
    return {
      premiumItem: null as PremiumItemModel | null,
      trackingData: null as PremiumTrackingData | undefined | null,
      puffItem: null as PuffCardItem | null,
      isLoading: true,
      display: false,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  computed: {
    hasData(): boolean {
      return Boolean(this.premiumItem && this.puffItem);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    navigateToEpisode() {
      this.sendOpenEpisodeEvent();
      appService.openEpisode(this.premiumItem?.episodeId);
    },
    async fetchData() {
      this.premiumItem = await PremiumRepository.get(this.variant);
      if (this.premiumItem) {
        const headline = this.premiumItem.premiumTitle;
        const text = this.premiumItem?.description;

        this.puffItem = {
          imageUrl: this.premiumItem.imageUrl,
          imageAltText: "",
          isLive: false,
          type: "Episode",
          publishTimeFormatted: "",
          headline,
          text,
          ariaLabel: `${headline}. ${text}`,
          programName: this.premiumItem.theme,
          durationFormatted: this.premiumItem?.durationFormatted ?? "",
          id: this.premiumItem?.episodeId,
          index: 0,
        };

        this.trackingData = {
          id: this.premiumItem?.id ?? 0,
          premiumTitle: this.premiumItem?.premiumTitle ?? "",
          episodeId: this.premiumItem?.episodeId ?? 0,
          episodeTitle: this.premiumItem?.episodeTitle ?? "",
          episodeProgramCategory:
            this.premiumItem?.episodeProgramCategory ?? "",
          episodeDuration: this.premiumItem?.episodeDuration ?? 0,
          programId: this.premiumItem?.programId ?? "",
          programName: this.premiumItem?.programName ?? "",
          channelId: this.premiumItem?.channelId ?? "",
          channelName: this.premiumItem?.channelName ?? "",
          publishTimestampMillis: this.premiumItem?.publishTimestampMillis ?? 0,
          trackingLabel: this.premiumItem?.trackingLabel ?? "",
        };
      }
      this.isLoading = false;
    },
    sendOpenEpisodeEvent() {
      if (this.trackingData != null) {
        // Firebase event
        const firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modulklick",
          parametersMap: {
            kanalnamn: this.trackingData.channelName,
            kanal_id: this.trackingData.channelId,
            programnamn: this.trackingData.programName,
            program_id: this.trackingData.programId,
            ljudnamn: this.trackingData.episodeTitle,
            ljud_id: this.trackingData.episodeId.toFixed(0),
            programkategori: this.trackingData.episodeProgramCategory,
            ljudlangd: Math.round(
              this.trackingData.episodeDuration / 60
            ).toFixed(0),
            modultyp: "tbd",
            dagar_fran_publicering: (
              (Date.now() - this.trackingData.publishTimestampMillis) /
              86400000
            ).toFixed(0), // 86400000 = (1000 * 60 * 60 * 24) = millis in a day,
            skarmnamn: "hem",
            ursprung: "tbd",
            innehallstyp: "tbd",
            modulnamn: this.trackingData.trackingLabel,
            eventkalla: "webview",
            pufftext: this.puffItem?.headline,
          },
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);

        const kilkayaEvent: KilkayaClickEvent = {
          audioType: "Episode",
          channelName: this.trackingData.channelName,
          soundName: this.trackingData.episodeTitle,
          moduleTitle: this.puffItem?.headline || "",
          moduleName: this.moduleName || "",
          id: this.trackingData.id,
          itemHeadline: this.puffItem?.headline || "",
          interactionType: "klick",
          programName: this.trackingData.programName,
        };
        kilkayaClick(kilkayaEvent);


        // Google analytics event
        const googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "klick_objekt",
          category: "Karusell",
          trackingLabel: this.trackingData.trackingLabel,
          customDimensions: {
            1: this.trackingData.channelName,
            2: this.trackingData.channelId,
            3: this.trackingData.programName,
            4: this.trackingData.programId,
            5: this.trackingData.episodeTitle,
            6: this.trackingData.episodeId.toString(),
            17: this.trackingData.episodeProgramCategory,
            18: (this.trackingData.episodeDuration * 1000).toString(),
            62: this.trackingData.premiumTitle,
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
    onAlmostVisible(isAlmostVisible) {
      // Lazy load only images, not data (data is fetched in each module and we do not
      // want the same premium item to be shown more than once if editors change the order)
      if (isAlmostVisible) {
        this.display = true;
      }
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedEvent();
      }
    },
    sendDisplayedEvent() {
      if (this.trackingData) {
        // Firebase event
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "tbd",
            skarmnamn: "hem",
            pufftext: this.puffItem?.headline,
            modulnamn: this.trackingData?.trackingLabel,
            modulposition: "tbd",
            ursprung: "tbd",
            eventkalla: "webview",
          },
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);

        // Google Analytics event
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "visas",
          category: "Karusell",
          trackingLabel: this.trackingData?.trackingLabel,
          customDimensions: {
            62: this.trackingData.premiumTitle,
            6: this.trackingData.episodeId.toString(),
            5: this.trackingData.episodeTitle,
            17: this.trackingData.episodeProgramCategory,
            18: (this.trackingData.episodeDuration * 1000).toString(),
            4: this.trackingData.programId,
            3: this.trackingData.programName,
            2: this.trackingData.channelId,
            1: this.trackingData.channelName,
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
  },
});
