import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stil_text = _resolveComponent("stil-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({pill: true, selected: _ctx.selected})
  }, [
    _createVNode(_component_stil_text, {
      color: "primary",
      colorInverted: _ctx.selected
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["colorInverted"])
  ], 2))
}