
import {defineComponent} from 'vue'
import * as appService from '../../public/appService.js'
import {NewsCollectionModel} from '../../entities/news-collection-model'
import {CuratedNewsCollectionRepository} from '@/usecases/curated-news-collection-repository'
import {FirebaseTrackingEvent} from '../../entities/firebase-tracking-event'
import {GoogleAnalyticsTrackingEvent} from '../../entities/google-analytics-tracking-event'
import {CardImageCollection, CardImageCollectionSkeleton} from '@sverigesradio/dpp-vue-3-components'
import TemporarySkeletonItem from '@/components/TemporarySkeletonItem.vue'
import NewsCarousel from "@/components/carousels/NewsCarousel.vue";
import NewsCard from "@/components/cards/NewsCard.vue";
import {ON_ALMOST_VISIBLE_ROOT_MARGIN} from "@/constants";
import "@sverigesradio/stil/components/CardImageCollection.lit.js";
import {NativeRepository} from "@/repositories/native-repository";

export default defineComponent({
        name: "CuratedNews",
        components: { TemporarySkeletonItem, NewsCarousel, CardImageCollection, CardImageCollectionSkeleton, NewsCard },
        data () {
            return {
                isLoading: true,
                newsCollection: null as NewsCollectionModel[] | null,
                ON_ALMOST_VISIBLE_ROOT_MARGIN
            }
        },
        computed: {
          hasData(): boolean {
            return this.newsCollection !== null && this.newsCollection?.length > 0
          }
        },
        methods: {
            playNewsCollection (newsCollectionModel) {
                newsCollectionModel.type = "playArticleCollection"
                newsCollectionModel.startIndex = 0
                appService.playNewsCollection(JSON.stringify(newsCollectionModel))
                this.sendClickedTrackingEvents(newsCollectionModel)
            },
            async fetchData () {
                this.newsCollection = await CuratedNewsCollectionRepository.get()
                this.isLoading = false
            },
            pausePlayer() {
                NativeRepository.pausePlayer()
            },
            onAlmostVisible (isAlmostVisible) {
                if (isAlmostVisible) {
                    this.fetchData()

                    window['WebViewVisibleIntervalCuratedNewsHook'] = {
                        components: this,
                        update: () => {
                            this.fetchData()
                        }
                    };
                }
            },
            onBecomeVisible (isVisible) {
                if (isVisible) {
                    this.sendDisplayedTrackingEvents()
                }
            },
            sendClickedTrackingEvents (newsCollection: NewsCollectionModel) {
                let title = ""
                if (newsCollection.genre == "Local") {
                    title = "lokala nyheter"
                } else {
                    title = newsCollection.listHeadline.toLowerCase()
                }
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modulstart",
                    parametersMap: {
                        spellista: newsCollection.listHeadline,
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        ursprung: "tbd",
                        innehallstyp: "tbd",
                        modulnamn: newsCollection.trackingLabel,
                        eventkalla: "webview"
                    }
                }

                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "start",
                    category: "Karusell",
                    trackingLabel: newsCollection.trackingLabel,
                    customDimensions: {
                        36: title,
                        46: title,
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            },
            sendDisplayedTrackingEvents () {
                if (this.newsCollection) {
                    if (this.newsCollection?.[0]) {
                        let firebaseTrackingEvent: FirebaseTrackingEvent = {
                            eventName: "modul_visas",
                            parametersMap: {
                                modultyp: "tbd",
                                skarmnamn: "hem",
                                innehallstyp: "tbd",
                                modulnamn: this.newsCollection[0].trackingLabel,
                                eventkalla: "webview"
                            }
                        }
                        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                            event: "visas",
                            category: "Karusell",
                            trackingLabel: this.newsCollection[0].trackingLabel,
                            customDimensions: {
                                66: "webview"
                            },
                            customMetrics: {},
                            campaignUrl: null,
                            trackingValue: null
                        }
                        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                        appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
                    }
                }
            },
        }
    }
)
