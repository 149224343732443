
import { defineComponent } from "vue";
import "@sverigesradio/stil/components/stil-text.js";
export default defineComponent({
  name: "TextPuff",
  components: {},
  props: {
    prefix: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    separator: {
      type: String,
      required: true,
    },
  },
});
