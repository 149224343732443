
import {defineComponent} from "vue"
import {ON_ALMOST_VISIBLE_ROOT_MARGIN} from "@/constants"
import {PromotionModel} from "../../entities/promotion-model"
import {PromotionRepository} from "@/repositories/promotion-repository"
import * as appService from "../../public/appService"
import {NativeRepository} from "@/repositories/native-repository";
import {storageUntilExpiry} from "@/util/storage-utils"
import "@sverigesradio/stil/components/stil-text.js"
import "@sverigesradio/stil/components/stil-image.js"
import "@sverigesradio/stil/components/stil-button.js"
import {FirebaseTrackingEvent} from "../../entities/firebase-tracking-event"
import {GoogleAnalyticsTrackingEvent} from "../../entities/google-analytics-tracking-event"
import {PromotionAction} from "../../entities/promotion-action";

export default defineComponent({
    name: "Promotion",
    props: {
        promotionIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isLoading: true,
            promotions: null as PromotionModel[] | null,
            title: null as string | null,
            text: null as string | null,
            imageUrl: null as string | null,
            modalSpent: false as Boolean,
            storeKey: null as string | null,
            ON_ALMOST_VISIBLE_ROOT_MARGIN
        }
    },
    computed: {
        hasData(): boolean {
            if (this.promotions) {
                return this.promotions[this.promotionIndex] !== null && this.promotions[this.promotionIndex].enabled
            } else {
                return false
            }
        }
    },
    watch: {
        modalSpent(newModalSpent) {
            if (newModalSpent && this.promotions) {
                const expiration = this.promotions[this.promotionIndex]?.expiration
                if (expiration === null || expiration === undefined) {
                    const inOneYear = new Date()
                    inOneYear.setFullYear(inOneYear.getFullYear() + 1)
                    storageUntilExpiry.setStoredValue(
                        this.storeKey,
                        "1",
                        inOneYear.toUTCString()
                    )
                } else {
                    const formattedDate = new Date(expiration)
                    storageUntilExpiry.setStoredValue(
                        this.storeKey,
                        "1",
                        formattedDate.toUTCString()
                    )
                }
            }

            if (!this.modalSpent) {
                document.body.classList.add("modal-open")
                document.getElementById("start")?.setAttribute("aria-hidden", "true")
            } else {
                document.body.classList.remove("modal-open")
                document.getElementById("start")?.removeAttribute("aria-hidden")
            }
        },
        hasData() {
            if (!this.modalSpent) {
                document.body.classList.add("modal-open")
                document.getElementById("start")?.setAttribute("aria-hidden", "true")
            } else {
                document.body.classList.remove("modal-open")
                document.getElementById("start")?.removeAttribute("aria-hidden")
            }
        }
    },
    methods: {
        async fetchData() {
            this.promotions = await PromotionRepository.get()
            if (this.promotions !== null) {
                const puffText = this.promotions[this.promotionIndex]?.trackingPuffText
                    ?.replace(/\s/g, "")
                    .toLowerCase()
                this.storeKey =
                    "promotion-start-page-" +
                    puffText +
                    "-" +
                    this.promotions[this.promotionIndex]?.expiration
                this.modalSpent = this.hasStoredValue(this.storeKey)
            }
            this.isLoading = false
        },
        hasStoredValue(name): Boolean {
            return storageUntilExpiry.consumedOrExpired(name)
        },
        onAlmostVisible(isAlmostVisible) {
            if (isAlmostVisible) {
                this.fetchData()
            }
        },
        onBecomeVisible(isVisible) {
            if (isVisible) {
                this.sendVisibleEvent()
            }
        },
        handlePromotionAction(promotionData: PromotionModel | null) {
            if (promotionData !== null) {
                if (promotionData.promotionAction !== null) {
                    switch (promotionData.promotionAction) {
                        case PromotionAction.OpenProgram: {
                            appService.openProgram(promotionData.programId)
                            break
                        }
                        case PromotionAction.ShowPushSettings: {
                            NativeRepository.showPushSettings()
                            break
                        }
                        case PromotionAction.OpenNewsTab: {
                            NativeRepository.openNewsTab()
                            break
                        }
                        default: {
                            this.close()
                            break
                        }
                    }
                } else {
                    appService.openProgram(promotionData.programId)
                }
            } else {
                this.close()
            }
            this.setModalSpent();
            this.sendClickedEvent();
        },
        close() {
            document.getElementById("greeting")?.focus()
            this.setModalSpent()
            this.sendClosedEvent()
        }
        ,
        setModalSpent() {
            this.modalSpent = true
        }
        ,
        sendClosedEvent() {
            if (this.promotions) {
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modul_stang",
                    parametersMap: {
                        skarmnamn: "hem",
                        modulnamn: "takeover",
                        eventkalla: "webview",
                        pufftext: this.promotions[this.promotionIndex]?.trackingPuffText,
                        program_id: this.promotions[this.promotionIndex]?.programId
                    }
                }

                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "stang",
                    category: "Karusell",
                    trackingLabel: "takeover",
                    customDimensions: {
                        4: this.promotions[this.promotionIndex]?.programId?.toString() ?? "",
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            }
        }
        ,
        sendVisibleEvent() {
            if (this.promotions) {
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modul_visas",
                    parametersMap: {
                        skarmnamn: "hem",
                        modulnamn: "takeover",
                        eventkalla: "webview",
                        pufftext: this.promotions[this.promotionIndex]?.trackingPuffText,
                        program_id: this.promotions[this.promotionIndex]?.programId
                    }
                }
                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "visas",
                    category: "Karusell",
                    trackingLabel: "takeover",
                    customDimensions: {
                        4: this.promotions[this.promotionIndex]?.programId?.toString() ?? "",
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            }
        }
        ,
        sendClickedEvent() {
            if (this.promotions) {
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modulklick",
                    parametersMap: {
                        skarmnamn: "hem",
                        modulnamn: "takeover",
                        eventkalla: "webview",
                        pufftext: this.promotions[this.promotionIndex]?.trackingPuffText,
                        program_id: this.promotions[this.promotionIndex]?.programId
                    }
                }

                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "klick_objekt",
                    category: "Karusell",
                    trackingLabel: "takeover",
                    customDimensions: {
                        4: this.promotions[this.promotionIndex]?.programId?.toString() ?? "",
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            }
        }
    }
})
