import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "divider"
}
const _hoisted_2 = {
  key: 1,
  class: "card-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardImageCollectionSkeleton = _resolveComponent("CardImageCollectionSkeleton")!
  const _component_NewsCard = _resolveComponent("NewsCard")!
  const _component_OldTopNewsChooseP4Item = _resolveComponent("OldTopNewsChooseP4Item")!
  const _component_NewsCarousel = _resolveComponent("NewsCarousel")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_CardImageCollectionSkeleton, {
              key: 0,
              class: "card-padding"
            }))
          : (_ctx.hasData)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createVNode(_component_NewsCarousel, {
                    trackingLabel: _ctx.newsCollection[0].trackingLabel
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_NewsCard, {
                        key: _ctx.newsCollection[0].listHeadline,
                        onPlay: _cache[0] || (_cache[0] = ($event: any) => (_ctx.playNewsCollection(_ctx.newsCollection[0]))),
                        onPause: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pausePlayer())),
                        listHeadline: _ctx.newsCollection[0].listHeadline,
                        newsHeadline: _ctx.newsCollection[0].newsHeadline,
                        imageSrc: _ctx.newsCollection[0].picture.defaultUrl,
                        imageAlt: _ctx.newsCollection[0].picture.altText,
                        tag: _ctx.newsCollection[0].tag,
                        meta: _ctx.newsCollection[0].meta,
                        ariaLabel: _ctx.newsCollection[0].ariaLabel,
                        idsOfArticlesInPlaylist: _ctx.newsCollection[0].articles.map(a => a.id.toString())
                      }, null, 8, ["listHeadline", "newsHeadline", "imageSrc", "imageAlt", "tag", "meta", "ariaLabel", "idsOfArticlesInPlaylist"])),
                      (_ctx.showChooseLocalChannelItem)
                        ? (_openBlock(), _createBlock(_component_OldTopNewsChooseP4Item, {
                            key: 0,
                            onClick: _ctx.chooseP4
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCollection.slice(1), (newsCollectionItem) => {
                        return (_openBlock(), _createBlock(_component_NewsCard, {
                          key: newsCollectionItem.listHeadline,
                          onPlay: ($event: any) => (_ctx.playNewsCollection(newsCollectionItem)),
                          onPause: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pausePlayer())),
                          listHeadline: newsCollectionItem.listHeadline,
                          newsHeadline: newsCollectionItem.newsHeadline,
                          imageSrc: newsCollectionItem.picture.defaultUrl,
                          imageAlt: newsCollectionItem.picture.altText,
                          tag: newsCollectionItem.tag,
                          meta: newsCollectionItem.meta,
                          ariaLabel: newsCollectionItem.ariaLabel,
                          idsOfArticlesInPlaylist: newsCollectionItem.articles.map(a => a.id.toString())
                        }, null, 8, ["onPlay", "listHeadline", "newsHeadline", "imageSrc", "imageAlt", "tag", "meta", "ariaLabel", "idsOfArticlesInPlaylist"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["trackingLabel"])
                ])
              ])), [
                [_directive_observe_visibility, { callback: (isVisible) => _ctx.onBecomeVisible(isVisible), once: true, throttle: 300, }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, { callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible), intersection: { rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN}, once: true, throttle: 100, }]
      ])
    : _createCommentVNode("", true)
}