

import {defineComponent} from 'vue'

export default defineComponent({
    name: "ConfettiAnimation",
    props: {
        check: Boolean
    },
    data () {
        return {
            checkedValue: false
        }
    },
    watch: {
        check (value) {
            if (value) {
                this.checkedValue = true
            }
        }
    }
});

