import { PuffItemType } from "entities/puff-package-model";

export type LayoutItem = {
  component:
  | "CardMedium"
  | "CardLarge"
  | "Divider"
  | "CardMediumPair"
  | "Interactive";
  puffIndex?: number;
};

const fullWidthComponents: PuffItemType[] = ["Interactive"];
export function getPuffPackageLayout(types?: PuffItemType[]): LayoutItem[] {
  if (!types) {
    return [];
  }

  const hasSpecialFullWidthComponent = types.some(type => fullWidthComponents.includes(type));

  const layout: LayoutItem[] = [];

  for (let i = 0; i < types.length; i++) {
    const currentType = types[i];

    // First item is always full width
    if (i === 0) {
      layout.push({
        component: currentType === "Interactive" ? "Interactive" : "CardLarge",
        puffIndex: i,
      });
      continue;
    }

    // The next thing we render always is full width - either as 
    // a pair or as a single full width component. Therefore we always
    // want to render a divider here.
    layout.push({
      component: "Divider",
    });

    // Our current design breaks the algorithm - when we have four items,
    // we want the second item to be full width IF we don't have any special
    // full width components (like valkompass). The algorithm would make
    // the last item full width instead, so we handle it with a special case.
    const isSpecialCaseForSecondItemWhenPackageHasFourItems =
      types.length === 4 && i === 1 && !hasSpecialFullWidthComponent;

    const currentTypeIsFullWidth = fullWidthComponents.includes(types[i])
    const nextTypeIsFullWidth = fullWidthComponents.includes(types[i + 1])
    const isLast = i + 1 === types.length;

    const thisAndNextItemShouldBePair =
      !isSpecialCaseForSecondItemWhenPackageHasFourItems &&
      !isLast &&
      !currentTypeIsFullWidth &&
      !nextTypeIsFullWidth
    if (thisAndNextItemShouldBePair) {
      layout.push({
        component: "CardMediumPair",
        puffIndex: i,
      });

      // Since we rendered a pair, we already consumed the next index
      // and need to skip it.
      i++;
    } else {
      layout.push({
        component: currentType === "Interactive" ? "Interactive" : "CardMedium",
        puffIndex: i,
      });
    }
  }

  return layout;
}
