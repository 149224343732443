
import {defineComponent} from 'vue'
import * as appService from '../../public/appService.js'
import {GetPremiumTertiaryItemUseCase} from "@/usecases/get-premium-tertiary-item-use-case";
import {PremiumItemModel} from "../../entities/premium-item-entity";
import {THEME_DARK, THEME_LIGHT} from "@/constants"
import {PremiumTrackingData} from "../../entities/premium-tracking-data";
import { CardTextImageTag } from '@sverigesradio/dpp-vue-3-components';
import {useNativeStore} from '@/stores/nativeStore'

export default defineComponent({
    name: 'Premium',
    components: { CardTextImageTag },
    data() {
        let premiumItem: PremiumItemModel | undefined | null
        let loaded: Boolean = false
        let hasError: Boolean = false
        let premiumProps = {}
        let useComponentLibrary = false

        return {
            premiumItem: premiumItem,
            loaded: loaded,
            hasError: hasError,
            theme: THEME_LIGHT,
            THEME_DARK: THEME_DARK,
            premiumProps,
            useComponentLibrary
        }
    },
    setup() {
        return {
            nativeStore: useNativeStore()
        };
    },
    created() {
        this.fetchData()
        this.theme = this.nativeStore.currentTheme
    },
    methods: {
        onBecomeVisible(isVisible) {
            if (isVisible) {
                // In the future, log events like this.
            }
        },
        openEpisode() {
            appService.sendWebViewPremiumClickedEvent()
            appService.openEpisode(this.premiumItem?.episodeId)
        },
        async fetchData() {
            this.premiumItem = await GetPremiumTertiaryItemUseCase.get()
            if (this.premiumItem) {

                this.premiumProps = {
                    "tag": this.premiumItem?.theme ?? "",
                    "headline": this.premiumItem?.premiumTitle ?? "",
                    "description": this.premiumItem?.description ?? "",
                    "picture": {
                        "defaultUrl": this.premiumItem?.imageUrl ?? "",
                        "altText": "",
                        "sources": []
                    }
                }

                const trackingData: PremiumTrackingData = {
                    id: this.premiumItem?.id ?? 0,
                    premiumTitle: this.premiumItem?.premiumTitle ?? "",
                    episodeId: this.premiumItem?.episodeId ?? 0,
                    episodeTitle: this.premiumItem?.episodeTitle ?? "",
                    episodeProgramCategory: this.premiumItem?.episodeProgramCategory ?? "",
                    episodeDuration: this.premiumItem?.episodeDuration ?? 0,
                    programId: this.premiumItem?.programId ?? "",
                    programName: this.premiumItem?.programName ?? "",
                    channelId: this.premiumItem?.channelId ?? "",
                    channelName: this.premiumItem?.channelName ?? "",
                    publishTimestampMillis: this.premiumItem?.publishTimestampMillis ?? 0,
                    trackingLabel: this.premiumItem?.trackingLabel ?? ""
                }
                appService.updateWebViewPremiumTrackingData(JSON.stringify(trackingData))
                this.loaded = true
            } else {
                console.error("Something went wrong when getting the premium tertiary module")
                this.hasError = true;
            }
        }
    },
})


