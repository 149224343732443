import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stil_text = _resolveComponent("stil-text")!

  return (_openBlock(), _createElementBlock("button", null, [
    _createVNode(_component_stil_text, {
      variant: "h3",
      color: "inherit",
      class: "prefix"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.prefix + _ctx.separator), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_stil_text, {
      as: "span",
      variant: "h3"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.headline), 1)
      ]),
      _: 1
    })
  ]))
}