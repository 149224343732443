export const storageUntilExpiry = {
    consumedOrExpired: (storeKey): boolean => {
        if (window.localStorage) {
            const storageItem = window.localStorage.getItem(storeKey)
            if (!storageItem) {
                return false
            }
            const item = JSON.parse(storageItem)
            const now = new Date()

            if (Date.parse(now.toUTCString()) > Date.parse(item.expiry)) {
                localStorage.removeItem(storeKey)
                return false
            }
            return item.value
        }
        
        const cookieArr = document.cookie.split(";")
        for (const item of cookieArr) {
            const cookiePair = item.split("=")
            if (storeKey == cookiePair[0].trim()) {
                return true
            }
        }
        return false
    },
    setStoredValue: (storeKey, value, expireDateUTC): void => {
        if (window.localStorage) {
            const item = {
                value: value,
                expiry: expireDateUTC,
            }
            window.localStorage.setItem(storeKey, JSON.stringify(item))

        } else {
            const expires = "; expires=" + expireDateUTC;
            document.cookie = storeKey + "=" + value  + expires + "; path=/";
        }
    }
}