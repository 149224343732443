
import { defineComponent } from 'vue'
import ModuleTitle from '@/components/ModuleTitle.vue'
import * as appService from '../../public/appService.js'
import { FirebaseTrackingEvent } from '../../entities/firebase-tracking-event'
import { GoogleAnalyticsTrackingEvent } from '../../entities/google-analytics-tracking-event'
import { GoogleAnalyticsTrackingScreen } from '../../entities/google-analytics-tracking-screen'

interface Link {
    title: string,
    url: string, 
    trackingScreenName: string,
    firebaseLinkName: string
}

const IOS_STORE_URL = 'https://itunes.apple.com/se/developer/sveriges-radio/id300548247'
const ANDROID_STORE_URL = 'https://play.google.com/store/apps/dev?id=6947261284997412858'

export default defineComponent({
        name: "Links",
        components: { ModuleTitle },
        props: {
            title: String
        },
        data () {
            const items : Link[] = [
                {
                    title: 'Supportforum',
                    url: 'https://kundo.se/org/sverigesradio',
                    trackingScreenName: 'supportforum',
                    firebaseLinkName: 'Supportforum'
                },
                {
                    title: 'Om personuppgifter',
                    url: 'https://sverigesradio.se/artikel/6956074?utm_source=srplay&utm_medium=app&utm_campaign=home',
                    trackingScreenName: 'om_personuppgifter',
                    firebaseLinkName: 'Om personuppgifter'
                },
                {
                    title: 'Om företaget',
                    url: 'https://sverigesradio.se/omsverigesradio?utm_source=srplay&utm_medium=app&utm_campaign=home',
                    trackingScreenName: 'om företaget',
                    firebaseLinkName: 'Om företaget'
                },
                {
                    title: 'Kontakta oss',
                    url: 'https://sverigesradio.se/artikel/1971594?utm_source=srplay&utm_medium=app&utm_campaign=home',
                    trackingScreenName: 'kontakta oss',
                    firebaseLinkName: 'Kontakta oss'
                }]

            const os = appService.getOS()
            if (os === 'iOS' || os === 'android') {
                const all_apps_url = (os === 'iOS') ? IOS_STORE_URL : ANDROID_STORE_URL
                items.unshift({
                    title: 'Appar från Sveriges Radio',
                    url: all_apps_url,
                    trackingScreenName: 'alla_våra_appar',
                    firebaseLinkName: 'Appar från Sveriges Radio'
                })
            }
            
            return {
            items
            }
        },
        methods: {
            click (item: Link) {
                this.sendLinkClickedEvents(item)
                appService.openBrowser(item.url)
            },
            onBecomeVisible (isVisible) {
                if (isVisible) {
                    this.sendDisplayedEvent()
                }
            },
            sendDisplayedEvent () {
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "modul_visas",
                    parametersMap: {
                        modultyp: "tbd",
                        skarmnamn: "hem",
                        innehallstyp: "tbd",
                        modulnamn: "från sveriges radio",
                        eventkalla: "webview"
                    }
                }
                let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
                    event: "visas",
                    category: "Karusell",
                    trackingLabel: "från sveriges radio",
                    customDimensions: {
                        66: "webview"
                    },
                    customMetrics: {},
                    campaignUrl: null,
                    trackingValue: null
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                appService.sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent)
            },
            sendLinkClickedEvents(item: Link) {
                // Firebase event
                let firebaseTrackingEvent: FirebaseTrackingEvent = {
                    eventName: "utlank_klick",
                    parametersMap: {
                        utlank: item.firebaseLinkName,
                        skarmnamn: "hem",
                        eventkalla: "webview"
                    }
                }
                appService.sendFirebaseTrackingEvent(firebaseTrackingEvent)
                // Google analytics screen
                let googleAnalyticsTrackingScreen: GoogleAnalyticsTrackingScreen = {
                    name: item.trackingScreenName,
                    customDimensions: {
                        66: "webview"
                    }
                }
                appService.sendGoogleAnalyticsTrackingScreenEvent(googleAnalyticsTrackingScreen)
            }
        }
    }
)
