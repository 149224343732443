<template>
  <div class="module-title">
    <stil-text as="h2" variant="h4">{{ title }}</stil-text>
    <span v-if="showAll" class="show-all">Visa alla →</span>
  </div>
</template>

<script>
import "@sverigesradio/stil/components/stil-text.js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModuleTitle",
  props: {
    title: String,
    showAll: false,
  },
});
</script>

<style scoped>
.module-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--size-1);
  margin-top: var(--size-3);
}
.show-all {
  color: var(--color-foreground-secondary-static);
}
</style>
