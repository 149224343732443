import {Picture} from "./picture-model";

export interface NewsCollectionModel {
    listHeadline: string,
    newsHeadline: string,
    newsModuleHeadline: string,
    tag: string,
    meta: string,
    genre: string,
    picture: Picture,
    ariaLabel: string,
    articles: Article[],
    type: string,
    trackingLabel: string,
    variant: string,
    startIndex: number,
    articlePlaylistId: string | null,
    playlistLookupId: number,
    navigationText: string,
    modulePlayText: string,
    moduleDuration: string,
}

export const localChannelNameOrUndefined = (newsCollectionModel: NewsCollectionModel) =>
    newsCollectionModel.genre === "Local" ? newsCollectionModel.listHeadline : undefined

export const TabTitleFromGenre: Record<string, string> = {
    'TopNews': 'Toppnyheter',
    'Sports': 'Sport',
    'Science': 'Vetenskap',
    'Arts': 'Kultur',
    'Local': 'Lokalt'
};


export interface Article {
    id: number;
    soundId: number;
    title: string;
    subTitle: string;
    audio: Audio;
    programId: number;
    programName: string;
    programCategoryName: string;
    channelId: number;
    channelName: string;
    imageUrl: string;
    imageAltText: string;
    publishTime: string;
    durationFormatted: string;
}

export interface Audio {
    lowQualityUrl: string,  
    mediumQualityUrl: string,
    highQualityUrl: string,
    offlineUrl: string,
    durationInMillis: number,
    statKey: string,
}
