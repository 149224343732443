import { getBaseUrl } from "@/util/address-utils";
import {ProgramModel} from "../../entities/program-model";
import {CarouselModel} from "../../entities/carousel-model";
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const ProgramsRepository = {
    async get(programIds): Promise<CarouselModel<ProgramModel> | null> {
        const url = getBaseUrl() + `bff/programs/getPrograms/${programIds}`
        return httpGetTypeOrNull<CarouselModel<ProgramModel>>(url)
    }
}