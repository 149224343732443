export const channelsById = {
  '132': {
    id: 132,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/132',
      html: 'https://sverigesradio.se/p1'
    },
    type: 'national',
    name: 'P1',
    branding: { id: 132, name: 'P1', theme: 'p1', color: '#31a1bd' },
    image: { altText: 'P1 logotyp', aspectRatio: '1x1', variants: [Array] },
    audio: { variants: [Array] }
  },
  '163': {
    id: 163,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/163',
      html: 'https://sverigesradio.se/p2'
    },
    type: 'web',
    name: 'P2 Språk och musik',
    branding: {
      id: 163,
      name: 'P2 Språk och musik',
      theme: 'p2',
      color: '#ff5a00'
    },
    image: { altText: 'P2 logotyp', aspectRatio: '1x1', variants: [Array] },
    audio: { variants: [Array] }
  },
  '164': {
    id: 164,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/164',
      html: 'https://sverigesradio.se/p3'
    },
    type: 'national',
    name: 'P3',
    branding: { id: 164, name: 'P3', theme: 'p3', color: '#19a972' },
    image: { altText: 'P3 logotyp', aspectRatio: '1x1', variants: [Array] },
    audio: { variants: [Array] }
  },
  '166': {
    id: 166,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/166',
      html: 'https://sverigesradio.se/international'
    },
    type: 'minorityLanguage',
    name: 'P6',
    branding: { id: 166, name: 'P6', theme: 'ekot', color: '#0065bd' },
    image: {
      altText: 'Konvolut för P6 kanalen',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '200': {
    id: 200,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/200',
      html: 'https://sverigesradio.se/jamtland'
    },
    type: 'local',
    name: 'P4 Jämtland',
    branding: { id: 200, name: 'P4 Jämtland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Jämtland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '201': {
    id: 201,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/201',
      html: 'https://sverigesradio.se/kalmar'
    },
    type: 'local',
    name: 'P4 Kalmar',
    branding: { id: 201, name: 'P4 Kalmar', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Kalmar - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '202': {
    id: 202,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/202',
      html: 'https://sverigesradio.se/sormland'
    },
    type: 'local',
    name: 'P4 Sörmland',
    branding: { id: 202, name: 'P4 Sörmland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Sörmland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '203': {
    id: 203,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/203',
      html: 'https://sverigesradio.se/jonkoping'
    },
    type: 'local',
    name: 'P4 Jönköping',
    branding: { id: 203, name: 'P4 Jönköping', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Jönköping - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '204': {
    id: 204,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/204',
      html: 'https://sverigesradio.se/varmland'
    },
    type: 'local',
    name: 'P4 Värmland',
    branding: { id: 204, name: 'P4 Värmland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Värmland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '205': {
    id: 205,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/205',
      html: 'https://sverigesradio.se/gotland'
    },
    type: 'local',
    name: 'P4 Gotland',
    branding: { id: 205, name: 'P4 Gotland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Gotland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '206': {
    id: 206,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/206',
      html: 'https://sverigesradio.se/sjuharad'
    },
    type: 'local',
    name: 'P4 Sjuhärad',
    branding: { id: 206, name: 'P4 Sjuhärad', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Sjuhärad - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '207': {
    id: 207,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/207',
      html: 'https://sverigesradio.se/malmo'
    },
    type: 'local',
    name: 'P4 Malmöhus',
    branding: { id: 207, name: 'P4 Malmöhus', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Malmöhus - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '208': {
    id: 208,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/208',
      html: 'https://sverigesradio.se/skaraborg'
    },
    type: 'local',
    name: 'P4 Skaraborg',
    branding: { id: 208, name: 'P4 Skaraborg', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Skaraborg - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '209': {
    id: 209,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/209',
      html: 'https://sverigesradio.se/norrbotten'
    },
    type: 'local',
    name: 'P4 Norrbotten',
    branding: { id: 209, name: 'P4 Norrbotten', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Norrbotten - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '210': {
    id: 210,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/210',
      html: 'https://sverigesradio.se/gavleborg'
    },
    type: 'local',
    name: 'P4 Gävleborg',
    branding: { id: 210, name: 'P4 Gävleborg', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Gävleborg - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '211': {
    id: 211,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/211',
      html: 'https://sverigesradio.se/kristianstad'
    },
    type: 'local',
    name: 'P4 Kristianstad',
    branding: { id: 211, name: 'P4 Kristianstad', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Kristianstad - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '212': {
    id: 212,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/212',
      html: 'https://sverigesradio.se/goteborg'
    },
    type: 'local',
    name: 'P4 Göteborg',
    branding: { id: 212, name: 'P4 Göteborg', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Göteborg - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '213': {
    id: 213,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/213',
      html: 'https://sverigesradio.se/blekinge'
    },
    type: 'local',
    name: 'P4 Blekinge',
    branding: { id: 213, name: 'P4 Blekinge', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Blekinge - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '214': {
    id: 214,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/214',
      html: 'https://sverigesradio.se/kronoberg'
    },
    type: 'local',
    name: 'P4 Kronoberg',
    branding: { id: 214, name: 'P4 Kronoberg', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Kronoberg - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '215': {
    id: 215,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/215',
      html: 'https://sverigesradio.se/vasterbotten'
    },
    type: 'local',
    name: 'P4 Västerbotten',
    branding: { id: 215, name: 'P4 Västerbotten', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Västerbotten - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '216': {
    id: 216,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/216',
      html: 'https://sverigesradio.se/vasternorrland'
    },
    type: 'local',
    name: 'P4 Västernorrland',
    branding: {
      id: 216,
      name: 'P4 Västernorrland',
      theme: 'p4',
      color: '#c31eaa'
    },
    image: {
      altText: 'Konvolut för P4 Västernorrland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '217': {
    id: 217,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/217',
      html: 'https://sverigesradio.se/vastmanland'
    },
    type: 'local',
    name: 'P4 Västmanland',
    branding: { id: 217, name: 'P4 Västmanland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Västmanland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '218': {
    id: 218,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/218',
      html: 'https://sverigesradio.se/uppland'
    },
    type: 'local',
    name: 'P4 Uppland',
    branding: { id: 218, name: 'P4 Uppland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Uppland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '219': {
    id: 219,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/219',
      html: 'https://sverigesradio.se/vast'
    },
    type: 'local',
    name: 'P4 Väst',
    branding: { id: 219, name: 'P4 Väst', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Väst - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '220': {
    id: 220,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/220',
      html: 'https://sverigesradio.se/halland'
    },
    type: 'local',
    name: 'P4 Halland',
    branding: { id: 220, name: 'P4 Halland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Halland - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '221': {
    id: 221,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/221',
      html: 'https://sverigesradio.se/orebro'
    },
    type: 'local',
    name: 'P4 Örebro',
    branding: { id: 221, name: 'P4 Örebro', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Örebro - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '222': {
    id: 222,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/222',
      html: 'https://sverigesradio.se/ostergotland'
    },
    type: 'local',
    name: 'P4 Östergötland',
    branding: { id: 222, name: 'P4 Östergötland', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Östergötland - Kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '223': {
    id: 223,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/223',
      html: 'https://sverigesradio.se/dalarna'
    },
    type: 'local',
    name: 'P4 Dalarna',
    branding: { id: 223, name: 'P4 Dalarna', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Dalarna - kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '224': {
    id: 224,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/224',
      html: 'https://sverigesradio.se/sameradion'
    },
    type: 'minorityLanguage',
    name: 'SR Sápmi',
    branding: { id: 224, name: 'SR Sápmi', theme: 'sameblå', color: '#1642a8' },
    image: {
      altText: 'sameradion logotyp',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '226': {
    id: 226,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/226',
      html: 'https://sverigesradio.se/finska'
    },
    type: 'minorityLanguage',
    name: 'Sveriges Radio Finska',
    branding: {
      id: 226,
      name: 'Sveriges Radio Finska',
      theme: 'sisublå',
      color: '#0065bd'
    },
    image: {
      altText: 'Konvolut för Sveriges Radio Finska - grundsidan (fd Sisuradio)',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '701': {
    id: 701,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/701',
      html: 'https://sverigesradio.se/stockholm'
    },
    type: 'local',
    name: 'P4 Stockholm',
    branding: { id: 701, name: 'P4 Stockholm', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Stockholm – kanalflöde',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '2562': {
    id: 2562,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/2562',
      html: 'https://sverigesradio.se/sida/tabla.aspx?programid=2562'
    },
    type: 'national',
    name: 'P2',
    branding: { id: 2562, name: 'P2', theme: 'p2', color: '#ff5a00' },
    image: {
      altText: 'Spridningsbild för P2 Musik',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '2576': {
    id: 2576,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/2576',
      html: 'https://sverigesradio.se/sida/default.aspx?programid=2576'
    },
    type: 'web',
    name: 'P3 Din gata',
    branding: { id: 2576, name: 'P3 Din gata', theme: 'p3', color: '#19a972' },
    image: {
      altText: 'Konvolut för P3 Din Gata',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '2755': {
    id: 2755,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/2755',
      html: 'https://sverigesradio.se/sida/default.aspx?programid=2755'
    },
    type: 'web',
    name: 'Radioapans knattekanal',
    branding: {
      id: 2755,
      name: 'Radioapans knattekanal',
      theme: 'barnwebben',
      color: '#a345b3'
    },
    image: {
      altText: 'Konvolut för Radioapans knattekanal',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '4540': {
    id: 4540,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/4540',
      html: 'https://sverigesradio.se/'
    },
    type: 'web',
    name: 'Ekot sänder direkt',
    branding: {
      id: 4540,
      name: 'Ekot sänder direkt',
      theme: 'ekot',
      color: '#0065bd'
    },
    image: {
      photographer: '',
      altText: 'Konvolut för Ekots redaktionssida',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '4951': {
    id: 4951,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/4951',
      html: 'https://sverigesradio.se/sida/default.aspx?programid=4951'
    },
    type: 'web',
    name: 'P4 Plus',
    branding: { id: 4951, name: 'P4 Plus', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för P4 Plus',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  },
  '5283': {
    id: 5283,
    links: {
      self: 'https://bridge-api.int.sr.se/v1/channels/5283',
      html: 'https://sverigesradio.se/sodertalje'
    },
    type: 'local',
    name: 'P4 Södertälje',
    branding: { id: 5283, name: 'P4 Södertälje', theme: 'p4', color: '#c31eaa' },
    image: {
      altText: 'Konvolut för Morgon i P4 Södertälje',
      aspectRatio: '1x1',
      variants: [Array]
    },
    audio: { variants: [Array] }
  }
}
