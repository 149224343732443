import {getBaseUrl} from "@/util/address-utils";
import {WeatherModel} from "../../entities/weather-entity";
import { httpGetTypeOrNull } from '@/api/http-get-type-or-null'

export const WeatherRepository = {
    async get(localChannelId : number, city: string): Promise<WeatherModel | null> {
        const url = getBaseUrl() + 'bff/weather'
        const params = {
            localChannelId: localChannelId,
            city: city
        }
        return httpGetTypeOrNull<WeatherModel>(url, params)
    }
}
