import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PuffPackageContainerSkeleton = _resolveComponent("PuffPackageContainerSkeleton")!
  const _component_PuffPackageComponent = _resolveComponent("PuffPackageComponent")!

  return (!_ctx.hasData)
    ? (_openBlock(), _createBlock(_component_PuffPackageContainerSkeleton, {
        key: 0,
        variant: 2
      }))
    : (_openBlock(), _createBlock(_component_PuffPackageComponent, {
        key: 1,
        puffPackage: _ctx.dataFromPostMessage || _ctx.dataFromUrl,
        moduleName: "Puff package preview"
      }, null, 8, ["puffPackage"]))
}