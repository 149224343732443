
import '@sverigesradio/stil/components/stil-grid.js';
import '@sverigesradio/stil/components/stil-image.js';
import '@sverigesradio/stil/components/stil-text.js';
import { defineComponent } from 'vue';
import PlayIcon from './icons/PlayIcon.vue';
export default defineComponent({
    name: "TopNewsChooseP4Item",
    components: { PlayIcon }
});
