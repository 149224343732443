import {getBaseUrl} from "@/util/address-utils";
import {NewsCollectionModel} from '../../entities/news-collection-model'
import {httpGetTypeOrNull} from '../api/http-get-type-or-null'

export const CuratedNewsCollectionRepository = {
    get(): Promise<NewsCollectionModel[] | null> {
        const url = getBaseUrl() + 'bff/news/curated'
        return httpGetTypeOrNull<NewsCollectionModel[]>(url)
    }
}
