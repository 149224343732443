
import {defineComponent} from "vue";
import "@sverigesradio/stil/components/stil-button.js"
import "@sverigesradio/stil/components/stil-text.js"

export default defineComponent({
    name: "FeedbackClickWebLink",
    emits: ['click'],
    methods: {
        submit (version) {
            this.$emit('click', version)
        }
    }

})
