
import "@sverigesradio/stil/components/stil-card.js";
import "@sverigesradio/stil/components/stil-button.js";
import "@sverigesradio/stil/components/stil-grid.js";
import "@sverigesradio/stil/components/stil-image.js";
import "@sverigesradio/stil/components/stil-text.js";
import { defineComponent } from "vue";
import { useNativeStore } from "@/stores/nativeStore";
import { THEME_DARK } from "@/constants";
import PlayIcon from "./icons/PlayIcon.vue";
import TextSkeleton from "@/components/skeletons/TextSkeleton.vue";
import LineSkeleton from "@/components/skeletons/LineSkeleton.vue";

export default defineComponent({
  name: "TopNewsChooseP4Item",
  setup() {
    return {
      nativeStore: useNativeStore(),
    };
  },
  computed: {
    isDarkMode(): boolean {
      return this.nativeStore.currentTheme === THEME_DARK;
    },
  },
  components: { PlayIcon, TextSkeleton, LineSkeleton },
});
