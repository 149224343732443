export interface PersonalizationTrackingEvent {
  interactionTypeAsString: string,
  eventId: string | undefined,
  appSectionID: string,
  source: string,
  itemIds: string[] | null,
  itemId: string | null,
  hitIndex: number | null | undefined,
}

export enum InteractionType {
  READ_MORE = "READ_MORE",
  DISPLAYED = "DISPLAYED",
  TAPPED = "TAPPED"
}
