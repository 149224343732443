import { defineStore } from "pinia";

export const useNativeStore = defineStore("nativeStore", {
  state: () => {
    return {
      currentTheme: ""
    };
  },
  actions: {
    setCurrentTheme(theme) {
      this.currentTheme = theme;
    },
  },
});
