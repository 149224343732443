
import { defineComponent } from "vue";
import "@sverigesradio/stil/components/stil-grid.js";
import CardLargeSkeleton from "./CardLargeSkeleton.vue";
import CardSmallSkeleton from "./CardSmallSkeleton.vue";
import InlineDivider from "../InlineDivider.vue";
import CardMediumSkeleton from "./CardMediumSkeleton.vue";

export default defineComponent({
  name: "PuffPackageContainerSkeleton",
  components: {
    CardLargeSkeleton,
    CardSmallSkeleton,
    InlineDivider,
    CardMediumSkeleton,
  },
  props: {
    variant: Number,
  },
});
