
import { defineComponent } from "vue";
import {
  BadgeDirect,
  CardImageHeadlineTag,
  CardTextImageTagSkeleton,
} from "@sverigesradio/dpp-vue-3-components";
import { LiveModel } from "../../entities/live-model";
import { FirebaseTrackingEvent } from "../../entities/firebase-tracking-event";
import { GoogleAnalyticsTrackingEvent } from "../../entities/google-analytics-tracking-event";
import * as appService from "../../public/appService";
import CardLarge from "@/components/cards/CardLarge.vue";
import Vignette from "@/components/typography/Vignette.vue";
import { LiveRepository } from "@/repositories/live-repository";
import { NativeRepository } from "@/repositories/native-repository";
import { KilkayaClickEvent, kilkayaClick } from "@/util/tracking-utils";
export default defineComponent({
  name: "Live",
  components: {
    CardLarge,
    CardImageHeadlineTag,
    BadgeDirect,
    CardTextImageTagSkeleton,
    Vignette,
  },
  props: {
    variant: String,
    moduleName: String,
  },
  data() {
    return {
      isLoading: true,
      liveItem: null as LiveModel | null,
    };
  },
  computed: {
    hasData(): boolean {
      return this.liveItem !== null;
    },
  },
  beforeMount() {
    window["WebViewVisibleIntervalEventLiveHook"] = {
      components: this,
      update: () => {
        this.fetchData();
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    playLiveArticle() {
      appService.playChannel(this.liveItem?.channelId);
      this.sendClickedTrackingEvents();
    },
    pausePlayer() {
      NativeRepository.pausePlayer();
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvents();
      }
    },
    sendClickedTrackingEvents() {
      if (this.liveItem != null) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modulstart",
          parametersMap: {
            kanalnamn: this.liveItem.channelName,
            kanal_id: this.liveItem.channelId,
            programnamn: this.liveItem.programName,
            program_id: this.liveItem.programId,
            ljudnamn: this.liveItem.episodeTitle,
            ljud_id: this.liveItem.episodeId,
            programkategori: this.liveItem.programCategory,
            modultyp: "tbd",
            skarmnamn: "hem",
            ursprung: "tbd",
            innehallstyp: "tbd",
            modulnamn: this.liveItem.trackingLabel,
            eventkalla: "webview",
            pufftext: this.liveItem.headline,
          },
        };

        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "start",
          category: "Karusell",
          trackingLabel: this.liveItem.trackingLabel,
          customDimensions: {
            21: this.liveItem.episodeTitle,
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );

        const kilkayaEvent: KilkayaClickEvent = {
          audioType: "Live",
          channelName: this.liveItem.channelName,
          soundName: this.liveItem.episodeTitle,
          moduleTitle: this.liveItem.headline,
          moduleName: this.moduleName || "",
          id: this.liveItem.id,
          itemHeadline: this.liveItem.headline,
          interactionType: "klick",
          programName: this.liveItem.programName,
        };
        kilkayaClick(kilkayaEvent);
      }
    },
    sendDisplayedTrackingEvents() {
      if (this.liveItem) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "tbd",
            skarmnamn: "hem",
            innehallstyp: "tbd",
            modulnamn: this.liveItem.trackingLabel,
            eventkalla: "webview",
            pufftext: this.liveItem.headline,
          },
        };
        let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent = {
          event: "visas",
          category: "Karusell",
          trackingLabel: this.liveItem.trackingLabel,
          customDimensions: {
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
        appService.sendGoogleAnalyticsTrackingEvent(
          googleAnalyticsTrackingEvent
        );
      }
    },
    async fetchData() {
      this.liveItem = await LiveRepository.get(this.variant);
      this.isLoading = false;
    },
  },
});
