
import { defineComponent } from "vue";
import "@sverigesradio/stil/components/stil-text.js";
import { NativeRepository } from "@/repositories/native-repository";
import TextPuff from "@/components/TextPuff.vue";
import TextSkeleton from "@/components/skeletons/TextSkeleton.vue";
import LineSkeleton from "@/components/skeletons/LineSkeleton.vue";
import { FirebaseTrackingEvent } from "entities/firebase-tracking-event";
import * as appService from "../../public/appService.js";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import { SkyltaRepository } from "@/usecases/skylta-repository";
import {
  RightNowItem,
  RightNowResponse,
} from "entities/puff-package-model";
import {
  KilkayaClickEvent,
  getTrackingAudioType,
  kilkayaClick,
} from "@/util/tracking-utils";

export default defineComponent({
  name: "RightNow",
  components: {
    TextPuff,
    TextSkeleton,
    LineSkeleton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      rightNowReponse:
        null as RightNowResponse | null,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  computed: {
    hasData(): boolean {
      return (
        this.rightNowReponse !== null &&
        this.rightNowReponse?.items.length > 0
      );
    },
  },

  methods: {
    async fetchData() {
      this.rightNowReponse =
        await SkyltaRepository.getRightNowById(
          this.id
        );
      this.isLoading = false;
    },
    onAlmostVisible(isAlmostVisible) {
      if (isAlmostVisible) {
        this.fetchData();

        window[
          "WebViewVisibleIntervalEventRightNowHook"
        ] = {
          components: this,
          update: () => {
            this.fetchData();
          },
        };
      }
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvents();
      }
    },

    openItem(item: RightNowItem) {
      this.sendOpenItemTrackingEvent(item);
      if (item.type === "Article") {
        NativeRepository.openNewsArticle(
          item.referenceId
        );
      } else if (item.type === "Episode") {
        NativeRepository.openEpisode(
          item.referenceId
        );
      } else if (item.type === "Live") {
        appService.playChannel(item.referenceId);
      }
    },

    sendOpenItemTrackingEvent(
      item: RightNowItem
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent =
        {
          eventName: "modulklick",
          parametersMap: {
            modulnamn: "just nu",
            modultyp: "just nu",
            skarmnamn: "hem",
            klickinteraktion: "klick",
            ljudtyp: getTrackingAudioType(
              item.type
            ),
            id: item.referenceId,
            pufftext: item.headline,
            ljudnamn: item.soundName,
            programnamn: item.programName,
          },
        };
      appService.sendFirebaseTrackingEvent(
        firebaseTrackingEvent
      );

      const kilkayaEvent: KilkayaClickEvent = {
        audioType: item.type,
        channelName: "",
        soundName: item.soundName,
        moduleTitle: "just nu",
        moduleName: this.moduleName,
        id: item.referenceId,
        itemHeadline: item.headline,
        interactionType: "klick",
        programName: item.programName,
      };
      kilkayaClick(kilkayaEvent);
    },

    sendDisplayedTrackingEvents() {
      if (this.rightNowReponse) {
        this.rightNowReponse.items.forEach(
          (item) => {
            let firebaseTrackingEvent: FirebaseTrackingEvent =
              {
                eventName: "modul_visas",
                parametersMap: {
                  modultyp: "just nu",
                  skarmnamn: "hem",
                  innehallstyp: "tbd",
                  modulnamn: "just nu",
                  eventkalla: "webview",
                  pufftext: item.headline,
                  ljudnamn: item.soundName,
                  programnamn: item.programName,
                },
              };
            appService.sendFirebaseTrackingEvent(
              firebaseTrackingEvent
            );
          }
        );
      }
    },
  },
});
