import { defineStore } from "pinia";
import * as appService from "../../public/appService";

export const useExperimentStore = defineStore("experiments", {
  state: () => {
    return {
      experiments: {},
      remoteConfigLoaded: false,
    };
  },
  actions: {
    async setupExperiments() {
      this.experiments = {
        S: false,
        TO: false,
        FM: false,
        NP: false,
        PP: false,
        HPA: false,
        MNP: false,
        FND: false,
        NFE: false,
      };

      const firebaseRemoteConfigExperiments =
        await appService.getExperimentValues();
      if (firebaseRemoteConfigExperiments) {
        for (const key in firebaseRemoteConfigExperiments) {
          this.experiments[key] = convertStringToBoolean(
            firebaseRemoteConfigExperiments[key]
          );
        }
      }
      this.remoteConfigLoaded = true;
    },
  },
  getters: {
    start_show_local_news_single: (state) => {
      return state.experiments["S"];
    },
    podcast_promotion_test: (state) => {
      return state.experiments["TO"];
    },
    show_news_promotion: (state) => {
      return state.experiments["NP"];
    },
    show_push_promotion: (state) => {
      return state.experiments["PP"];
    },
    show_feedback_module: (state) => {
      return state.experiments["FM"];
    },
    hide_play_all: (state) => {
      return state.experiments["HPA"];
    },
    more_news_position: (state) => {
      return state.experiments["MNP"];
    },
    fixed_news_design: (state) => {
      return state.experiments["FND"];
    },
    news_from_ekot_card_medium_carousel: (state) => {
      return state.experiments["NFE"];
    },
  },
});

function convertStringToBoolean(value) {
  if (value === "true" || value === "false") {
    return value === "true";
  }
  return value;
}
