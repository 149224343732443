
import { defineComponent } from 'vue';
import "@sverigesradio/stil/components/stil-text.js"

export default defineComponent({
  name: "Pill",
  props: {
    selected: {
        type: Boolean,
        default: false,
    }
  }
},
)
