
import { PropType, defineComponent } from "vue";
import PuffPackageComponent from "./PuffPackage.vue";
import * as appService from "../../public/appService.js";
import { PuffItem, PuffPackage } from "../../entities/puff-package-model";
import { ON_ALMOST_VISIBLE_ROOT_MARGIN } from "@/constants";
import PuffPackageContainerSkeleton from "./cards/skeletons/PuffPackageContainerSkeleton.vue";
import { FirebaseTrackingEvent } from "entities/firebase-tracking-event";
import { SkyltaRepository } from "@/usecases/skylta-repository";
import { NativeRepository } from "@/repositories/native-repository";

export default defineComponent({
  name: "PuffPackageContainer",
  components: {
    PuffPackageContainerSkeleton,
    PuffPackageComponent,
  },
  data() {
    return {
      isLoading: true,
      puffPackage: null as PuffPackage | null,
      ON_ALMOST_VISIBLE_ROOT_MARGIN,
    };
  },
  props: {
    puffPackageId: {
      type: Number,
      required: true,
    },
    moduleName: {
      type: String as PropType<string>,
    },
  },

  computed: {
    hasData(): boolean {
      return this.puffPackage !== null;
    },
    isVisible(): boolean {
      return this.puffPackage !== null && this.puffPackage.items.length > 0;
    },
    version(): number | null {
      return NativeRepository.version();
    },
  },
  methods: {
    async fetchData() {
      this.puffPackage = await SkyltaRepository.getPuffPackageById(
        this.puffPackageId
      );
      this.isLoading = false;

      if (
        appService.getOS() === "android" &&
        this.puffPackage &&
        (!this.version || this.version < 2.1)
      ) {
        this.puffPackage = {
          ...this.puffPackage,
          items: this.puffPackage.items.filter(
            (item) => !this.isSommarPuff(item)
          ),
        };
      }
    },
    onAlmostVisible(isAlmostVisible) {
      if (isAlmostVisible) {
        this.fetchData();
      }
      window["WebViewVisibleIntervalEventPuffPackageHook"] = {
        components: this,
        update: () => {
          this.fetchData();
        },
      };
    },
    onBecomeVisible(isVisible) {
      if (isVisible) {
        this.sendDisplayedTrackingEvents();
      }
    },

    sendDisplayedTrackingEvents() {
      if (this.puffPackage) {
        let firebaseTrackingEvent: FirebaseTrackingEvent = {
          eventName: "modul_visas",
          parametersMap: {
            modultyp: "puffpaket",
            skarmnamn: "hem",
            modulnamn: this.moduleName || "puffpaket",
            eventkalla: "webview",
            rubrik_puffpaket: this.puffPackage.subject,
          },
        };
        appService.sendFirebaseTrackingEvent(firebaseTrackingEvent);
      }
    },
    isSommarPuff(item: PuffItem) {
      return item.referenceId === 6 && item.type === "Interactive";
    },
  },
});
