import { defineStore } from "pinia";
import * as appService from "../../public/appService";

export const usePlayerStateStore = defineStore("playerState", {
  state: () => {
    return {
      isPlaying: false,
      currentItemId: "",
      idsOfCurrentPlaylist: "",
    };
  },
  actions: {
    setPlaying(itemId, idsOfCurrentPlaylist) {
      this.currentItemId = itemId;
      this.idsOfCurrentPlaylist = idsOfCurrentPlaylist;
      this.isPlaying = true;
    },
    setStopped() {
      this.currentItemId = "";
      this.idsOfCurrentPlaylist = "";
      this.isPlaying = false;
    },
  },
});
