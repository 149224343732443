import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94978174"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardImage = _resolveComponent("CardImage")!
  const _component_stil_text = _resolveComponent("stil-text")!
  const _component_stil_grid = _resolveComponent("stil-grid")!
  const _component_PlayPause = _resolveComponent("PlayPause")!

  return (_openBlock(), _createBlock(_component_stil_grid, {
    onClick: _ctx.onOpen,
    variant: "flex",
    flow: "column",
    alignItems: "stretch",
    gap: 2
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CardImage, {
        isLive: _ctx.isLive,
        imageUrl: _ctx.imageUrl,
        imageAltText: _ctx.imageAltText
      }, null, 8, ["isLive", "imageUrl", "imageAltText"]),
      _createVNode(_component_stil_grid, {
        flow: "row",
        gap: 1
      }, {
        default: _withCtx(() => [
          _createVNode(_component_stil_grid, {
            flow: "column",
            columns: "1fr auto",
            gap: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_stil_grid, {
                "aria-describedby": `${_ctx.id}-summary`,
                flow: "row",
                gap: 1
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_stil_text, {
                    "aria-hidden": "true",
                    class: "headline",
                    variant: "h1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.headline), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.text)
                    ? (_openBlock(), _createBlock(_component_stil_text, {
                        key: 0,
                        "aria-hidden": "true",
                        class: "text",
                        variant: "body",
                        truncateLine: 3
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.text), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    id: `${_ctx.id}-summary`,
                    class: "screen-reader-only"
                  }, _toDisplayString(_ctx.ariaLabel), 9, _hoisted_1)
                ]),
                _: 1
              }, 8, ["aria-describedby"]),
              (_ctx.hasPlayButton)
                ? (_openBlock(), _createBlock(_component_PlayPause, {
                    key: 0,
                    isPlaying: _ctx.isPlaying,
                    onPlay: _ctx.onPlay,
                    onPause: _ctx.onPause,
                    size: 32,
                    inverted: _ctx.isDarkMode
                  }, null, 8, ["isPlaying", "onPlay", "onPause", "inverted"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.programName || _ctx.durationFormatted)
            ? (_openBlock(), _createBlock(_component_stil_text, {
                key: 0,
                "aria-hidden": "true",
                class: "metadata",
                variant: "overline2",
                transform: "uppercase"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.programName) + _toDisplayString(_ctx.programName && _ctx.durationFormatted ? " · " : "") + _toDisplayString(_ctx.durationFormatted), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}