import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "divider"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PuffPackageContainerSkeleton = _resolveComponent("PuffPackageContainerSkeleton")!
  const _component_PuffPackageComponent = _resolveComponent("PuffPackageComponent")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || (_ctx.isVisible && _ctx.hasData))
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_PuffPackageContainerSkeleton, {
              key: 0,
              variant: 5
            }))
          : (_ctx.hasData && _ctx.puffPackage)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_PuffPackageComponent, {
                  puffPackage: _ctx.puffPackage,
                  moduleName: _ctx.moduleName
                }, null, 8, ["puffPackage", "moduleName"])
              ])), [
                [_directive_observe_visibility, {
        callback: (isVisible) => _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, {
      callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible),
      intersection: {
        rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN,
      },
      once: true,
      throttle: 100,
    }]
      ])
    : _createCommentVNode("", true)
}