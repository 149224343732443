import { getBaseUrl } from "@/util/address-utils";
import {ProgramModel} from "../../entities/program-model";
import {CarouselModel} from "../../entities/carousel-model";
import { httpGetTypeOrNull } from '../api/http-get-type-or-null'

export const PopularProgramRepository = {
    async get(): Promise<CarouselModel<ProgramModel> | null> {
        const url = getBaseUrl() + "bff/programs/popular"
        return httpGetTypeOrNull<CarouselModel<ProgramModel>>(url)
    }
}